import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import React from "react";
import { Row } from "react-grid-system";
import { connect } from "react-redux";
import { createPaymentMethod, getTaxes } from "src/actions/checkouts";
import { resetSession } from "src/actions/sessions";
import { createSubscription } from "src/actions/subscriptions";
import { gettext as _ } from "../../utils";
import Button from "../buttons/Button";
import ButtonGroup from "../buttons/ButtonGroup";
import Label from "../forms/fields/Label";
import CheckoutAgreement from "./CheckoutAgreement";
import CheckoutItem from "./CheckoutItem";
import CheckoutList from "./CheckoutList";

const VATEUList = require("../../data/vat.json");

const CARD_ELEMENT_OPTIONS = {
  showIcon: true,
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#009688",
      color: "#32325d",
      fontFamily: "\"Helvetica Neue\", Helvetica, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#B90000",
      iconColor: "#B90000",
    },
  },
};

export class CheckoutPaymentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item_list: null,
      disabled: false,
      disable_pay: false,
      cardError: false,
      termsError: false,
      taxRate: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handlePaymentMethod = this.handlePaymentMethod.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.findTaxRate = this.findTaxRate.bind(this);
  }

  handleChange(key) {
    return e => {
      e.preventDefault();

      let value = e.target.value;

      this.setState(prevState => ({
        [key]: {
          ...prevState[key],
          value,
        },
      }));
    };
  }

  async findTaxRate() {
    let taxCountry = null;
    VATEUList.map(country => {
      let regexState = new RegExp(country.regex);
      if(regexState.test(this.props.organization.detail.VAT)) {
        return taxCountry = country;
      }
    });

    const taxList = (await this.props.getTaxes(this.props.account.detail)).payload.tax;
    let rate = null;

    taxList && taxList.map(taxRate => {
      if((taxRate.jurisdiction === taxCountry.id)) {
        return rate = taxRate;
      }
    });

    return rate;
  }

  render() {
    const { sessions, organization } = this.props;

    return (
      <form onSubmit={ this.handlePaymentMethod }>
        <Row className="u-mb" justify={ "between" }> { /** TODO: Row needs to be immediate child of Container */ }
          <div className={ "checkout__container" }>
            <div className={ "checkout-details" }>
              <Row className={ "card-details" }> { /** TODO: Row needs to be immediate child of Container */ }
                <div className={ "card-details__card-number" }>
                  <Label>{ "Card Number" }</Label>
                  <CardNumberElement options={ CARD_ELEMENT_OPTIONS }/>
                </div>
              </Row>
              <Row className={ "u-mb card-details" }
                justify={ "between" }> { /** TODO: Row needs to be immediate child of Container */ }
                <div className={ "card-details__expiry" }>
                  <Label> { "Expiration date " }</Label>
                  <CardExpiryElement options={ CARD_ELEMENT_OPTIONS }/>
                </div>
                <div className={ "card-details__cvc" }>
                  <Label>{ ("Card verification number") }</Label>
                  <CardCvcElement options={ CARD_ELEMENT_OPTIONS }/>
                </div>
              </Row>
              { this.state.cardError &&
              <div className={ "card-error__message" }>
                { _("Something went wrong while charging card! Please check your payment information.") }
              </div>
              }
              { this.state.termsError &&
              <div className={ "card-error__message" }>
                { _("Please confirm you agree with the terms below.") }
              </div>
              }
              <div className={ "checkout-agreement" }>
                <CheckoutAgreement termsOfAgreement={ this.handleChecked }/>
              </div>
            </div>
          </div>
          {
            this.state.taxRate.error
              ? (
                <div className={ "checkout-invoice checkout-invoice__error" }>
                  { _("Something went wrong! Please, try again later or contact service provider.") }
                </div>
              ) : (
                <div className={ "checkout-invoice" }>
                  <CheckoutList organization={ organization.detail } taxRate={ this.state.taxRate }>
                    { sessions.detail.session.subscription_data.display_items.map((item, i) =>
                      <CheckoutItem key={ i } item={ item }/>) }
                  </CheckoutList>
                  <ButtonGroup inline>
                    <Button id="pay_button" size="large" stretch primary type="submit"
                      onClick={ this.handlePaymentMethod }
                      disabled={ this.state.disable_pay }>
                      { _("Pay now") }
                    </Button>
                  </ButtonGroup>
                </div>
              ) }
        </Row>
      </form>
    );
  }

  async handlePaymentMethod(e) {
    e.preventDefault();
    if(!this.state.termsOfAgreement) {
      return this.setState({ termsError: true });
    }
    this.setState({ disable_pay: true });

    let response = await this.props.createPaymentMethod(this.props.account.detail, this.props.stripe,
      this.props.elements.getElement(CardNumberElement));

    if(response.error) {
      this.setState({ cardError: true });
      this.setState({ disable_pay: false });
    } else {
      this.handlePayment(response.payload);
    }
  }

  handlePayment(method) {
    this.props.createSubscription(this.props.account.detail, this.state.item_list, method, this.state.taxRate,
      this.props.stripe);
  }

  async componentDidMount() {
    // TODO: All of the code in `componentDidMount` needs to be refactored to a redux thunk.
    let taxRate = await this.findTaxRate();
    this.setState({ taxRate: taxRate, isLoading: false });

    this.setState(() => ({
      item_list: [...this.props.sessions.detail.session.subscription_data.display_items],
    }));
  }

  handleChecked(checked) {
    this.setState({ termsOfAgreement: checked });
  }
}

CheckoutPaymentForm.propTypes = {
  stripe: PropTypes.object,
  elements: PropTypes.object,
  account: PropTypes.object,
  items: PropTypes.object,
  sessions: PropTypes.object,
  resetSession: PropTypes.func,
  path: PropTypes.string,
  createSubscription: PropTypes.func,
  checkoutSucceeded: PropTypes.func,
  checkoutFailed: PropTypes.func,
  createPaymentMethod: PropTypes.func,
  getTaxes: PropTypes.func,
  organization: PropTypes.object,
};

export default connect(
  state => ({
    sessions: state.sessions,
    account: state.user,
    organization: state.organization,
  }),
  dispatch => ({
    checkoutFailed: () => dispatch(push("/settings/subscription/cancel/")),
    checkoutSucceeded: () => dispatch(push("/settings/subscription/success/")),
    createSubscription: (account, items, method, tax, stripe) => dispatch(
      createSubscription(account, items, method, tax, stripe)),
    createPaymentMethod: (account, stripe, cardNumber) => dispatch(createPaymentMethod(account, stripe, cardNumber)),
    getTaxes: (account) => dispatch(getTaxes(account)),
    resetSession: () => dispatch(resetSession()),
  }),
)(CheckoutPaymentForm);