import Entity from "../Entity";

export default class CheckoutSession extends Entity{
  generateProps(entity) {
    super.generateProps(entity);
    Object.entries(entity).forEach(([key, val]) => {
      if(key === "display_items") {
        this[key] = val;
      }
    });
  }
}