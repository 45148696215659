import React from "react";

import Page from "./layout/Page";
import Header from "./layout/Header";

import MainNavigation from "./layout/MainNavigation";

import "../styles/base/_utils.scss";
import Content from "./layout/Content";
import NotFoundContent from "./common/NotFoundContent";
import PrivateComponent from "./common/PrivateComponent";

class NotFound extends React.Component {
  render() {
    return (
      <Page>
        <Header>
          <PrivateComponent component={<MainNavigation/>}/>
        </Header>
        <Content>
          <NotFoundContent/>
        </Content>
      </Page>
    );
  }
}

export default NotFound;
