import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const TableHeadCell = ({className, children, width}) => (
  <th width={width || "auto"} className={cx(className, "table__head-cell")}>
    {children}
  </th>
);

TableHeadCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  width: PropTypes.string,
};

export default TableHeadCell;
