import React from "react";
import PropTypes from "prop-types";
import {Redirect, Route} from "react-router-dom";
import {connect} from "react-redux";

const ConnectPrivateRoute = (Component) => {
  return class ConnectedComponent extends React.Component {
    render() {
      return <Component {...this.props}/>;
    }
  };
};

const PrivateRoute = ({component: Component, account, routeRender, ...args}) => {
  return (
    <Route {...args} render={props => (
      account.detail && !account.error
        ? routeRender ? routeRender(props) : <Component {...props}/>
        : <Redirect to={{
          pathname: "/",
          from: props.location,
        }}/>
    )}/>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  account: PropTypes.object,
  location: PropTypes.object,
  routeRender: PropTypes.func,
};

export default connect(
  state => ({
    account: state.user,
  }),
)(ConnectPrivateRoute(PrivateRoute));
