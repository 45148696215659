import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const TableRow = ({className, children}) => (
  <tr className={cx(className, "table__row")}>
    {children}
  </tr>
);

TableRow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default TableRow;
