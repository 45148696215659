import { ACCOUNTS_ACTIONS } from "../actions/accounts";

const defaultState = {
  list: [],
  error: null,
  loading: true,
};

const accountsReducer = (state = defaultState, action) => {
  switch(action.type) {
    case ACCOUNTS_ACTIONS.FETCH_LIST_COMPLETED:
      return {
        ...state,
        error: null,
        loading: false,
        list: action.payload,
      };
    case ACCOUNTS_ACTIONS.START_CREATE_ACCOUNT:
    case ACCOUNTS_ACTIONS.START_UPDATE_ACCOUNT:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ACCOUNTS_ACTIONS.START_DELETE_ACCOUNT:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ACCOUNTS_ACTIONS.DELETE_ACCOUNT_COMPLETED:
      return {
        ...state,
        error: null,
        loading: false,
        list: state.list.filter(({ id }) => id !== action.payload),
      };
    case ACCOUNTS_ACTIONS.UPDATE_ACCOUNT_COMPLETED:
      return {
        ...state,
        error: null,
        loading: false,
        list: state.list.map(account => {
          if(account.id !== action.payload.id) {
            return account;
          }

          return action.payload;
        }),
      };
    case ACCOUNTS_ACTIONS.CREATE_ACCOUNT_COMPLETED:
      return {
        ...state,
        error: null,
        loading: false,
        list: [...state.list, action.payload],
      };
    case ACCOUNTS_ACTIONS.CREATE_ACCOUNT_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ACCOUNTS_ACTIONS.UPDATE_ACCOUNT_FAILED:
      return {
        ...state,
        error: { message: action.payload },
        loading: false,
      };
    case ACCOUNTS_ACTIONS.DELETE_ACCOUNT_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ACCOUNTS_ACTIONS.RESET_DEFAULT_ACCOUNT:
      return {
        ...state,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default accountsReducer;
