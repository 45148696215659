import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { gettext as _ } from "../../utils";

import "../../styles/components/checkout/_checkout-item.scss";

function convertAmount(amount, currency) {
  return (amount / 100).toLocaleString("en-US", {
    style: "currency",
    currency,
  });
}

// TODO: Make list components instead of separate containers
const CheckoutItem = ({ className, item }) => {
  return (
    <div className={ cx(className, "checkout-item") }>
      <div className="checkout-item__segment checkout-item__segment--large">
        <div className="checkout-item__label">
          { "{qty} {item}"
            .replace("{qty}", item.quantity)
            .replace("{item}", "agents *") }
        </div>
        <div className="checkout-item__label-small">
          { _("{amount} / per agent per month")
            .replace("{amount}", convertAmount(item.plan.amount, item.plan.currency)) }
        </div>
      </div>
    </div>
  );
};

CheckoutItem.propTypes = {
  plan: PropTypes.object,
  className: PropTypes.string,
  item: PropTypes.object,
};

export default CheckoutItem;
