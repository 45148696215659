import {RESET_ACTIONS} from "../actions/reset";

const defaultState = {
  detail: null,
  error: null,
  loading: false,
};

const resetReducer = (state = defaultState, action) => {
  switch(action.type) {
    case RESET_ACTIONS.START_RESET:
    case RESET_ACTIONS.START_REQUEST_RESET:
      return {
        ...state,
        detail: null,
        error: null,
        loading: true,
      };
    case RESET_ACTIONS.COMPLETE_RESET:
    case RESET_ACTIONS.COMPLETE_REQUEST_RESET:
      return {
        ...state,
        detail: action.payload,
        error: null,
        loading: false,
      };
    case RESET_ACTIONS.ERROR_RESET:
    case RESET_ACTIONS.ERROR_REQUEST_RESET:
      return {
        ...state,
        error: action.payload,
        detail: null,
        loading: false,
      };
    case RESET_ACTIONS.SET_DEFAULT_REQUEST_RESET:
      return {
        detail: null,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default resetReducer;
