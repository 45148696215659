import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../../styles/components/forms/_tag-input.scss";

import Input from "./Input";
import Tag from "../../tags/Tag";

const TagInput = ({
  className,
  secondary,
  isPristine,
  value,
  options,
  onChange,
  onRemove,
  onKeyDown,
  onBlur,
  disabled,
}) => {
  const _props = {
    className: cx(className, "tag-input", {
      "tag-input--secondary": secondary,
      "tag-input--error": !isPristine,
    }),
    value,
    onKeyDown,
    onChange,
  };

  return (
    <div { ..._props }>
      { options && options.length > 0 && (
        <div className="tag-input__options">
          { options.map((option, i) => (
            <Tag key={ i } className="tag-input__option" error={ !isPristine } onClick={ onRemove } value={ option }/>
          )) }
        </div>
      ) }
      <Input isPristine={ isPristine } value={ value } onChange={ onChange } onBlur={ onBlur } hidden
        disabled={ disabled }/>
    </div>
  );
};

TagInput.propTypes = {
  className: PropTypes.string,
  secondary: PropTypes.bool,
  isPristine: PropTypes.bool,
  value: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

export default TagInput;
