import React, { useEffect, useState } from "react";
import SuggestionsListComponent from "./SuggestionsListComponent";
import PropTypes from "prop-types";
import cx from "classnames";
import "Styles/components/forms/_input.scss";

const Autocomplete = ({
  suggestions,
  onSelect,
  secondary,
  pattern,
  type,
  disabled,
  onBlur,
  value,
}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState(value);

  useEffect(() => {
    setInput(value);
  }, [value]);

  const _props = {
    className: cx("trigger__button", {
      "trigger__button--disabled": disabled,
      "trigger__button--secondary": secondary,
    }), pattern, disabled, onBlur,
  };

  const onChange = (e) => {
    const userInput = e.target.value;

    // Filter our suggestions that don't contain the user's input
    const unLinked = suggestions.filter(
      (suggestion) => suggestion.label.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
    );

    setInput(e.target.value);
    setFilteredSuggestions(unLinked);
    setShowSuggestions(true);
  };

  const onSuggestionClick = (suggestion) => {
    setFilteredSuggestions([]);
    setInput(suggestion.label);
    setShowSuggestions(false);
    onSelect(suggestion);
  };

  const onInputClick = () => {
    setFilteredSuggestions(suggestions);
    setShowSuggestions(true);
    setInput("");
  };

  return (
    <div className="trigger">
      <input
        type={ type || "text" }
        onChange={ onChange }
        onClick={ onInputClick }
        value={ input }
        { ..._props }
      />
      { showSuggestions &&
      <SuggestionsListComponent
        onClick={ onSuggestionClick }
        filteredSuggestions={ filteredSuggestions }/>
      }
    </div>);
};

Autocomplete.propTypes = {
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ),
  onSelect: PropTypes.func,
  pattern: PropTypes.string,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
};

export default Autocomplete;