import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./rule-action.scss";

/**
 * Action button for Rule components
 *
 * @component
 * @name RuleAction
 *
 * @example
 * const handleClick = () => console.log("Clicked!");
 *
 * <RuleAction onClick={ handleClick } type="danger">Delete</RuleAction>
 */
export default function RuleAction({ className, children, type, onClick }) {
  const styles = cx(className, "rule-action", {
    "rule-action--danger": type === "danger",
    "rule-action--normal": type === "normal",
  });

  return (
    <a onClick={ onClick } className={ styles }>{ children }</a>
  );
}

RuleAction.defaultProps = {
  type: "normal",
};

RuleAction.propTypes = {
  // Additional classes to add to the wrapper
  className: PropTypes.string,
  // The text to display on the action
  children: PropTypes.node.isRequired,
  // The type of the action, can be one of: `normal`, `danger`. Defaults to `normal`
  type: PropTypes.oneOf(["normal", "danger"]),
  // Click handler for clicking the action
  onClick: PropTypes.func,
};
