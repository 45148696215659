import React from "react";
import PropTypes from "prop-types";
import { SearchField, SearchFieldIcon, SearchFieldInput } from "../../../components/search-field";


/**
 * Displays a search bar for searching in instances.
 *
 * @container
 * @name SearchBar
 */
export default function SearchBar({ searchValue, setSearchValue }) {
  /**
   * event handle listening for on change on search field input.
   */
  const handleChange = (e) => setSearchValue(e.target.value);

  return (
    <SearchField constrained>
      <SearchFieldIcon/>
      <SearchFieldInput value={ searchValue } onChange={ handleChange }/>
    </SearchField>
  );
}

SearchBar.propTypes = {
  searchValue: PropTypes.string, // the search value state parsed from the parent container.
  setSearchValue: PropTypes.func, // the search value setter parsed from the parent container.
};