export const START_CREATE_PAYMENT_METHOD = "CHECKOUTS::CREATE_PAYMENT_METHOD::START";
export const COMPLETE_CREATE_PAYMENT_METHOD = "CHECKOUTS::CREATE_PAYMENT_METHOD::COMPLETE";
export const REJECT_CREATE_PAYMENT_METHOD = "CHECKOUTS::CREATE_PAYMENT_METHOD::REJECT";
export const COMPLETE_GET_TAXES = "CHECKOUTS::GET_TAXES::COMPLETE";
export const REJECT_GET_TAXES = "CHECKOUTS::GET_TAXES::REJECT";

const startCreatePaymentMethod = () => {
  return {
    type: START_CREATE_PAYMENT_METHOD,
  };
};
const completeCreatePaymentMethod = (payload) => {
  return {
    payload: payload,
    type: COMPLETE_CREATE_PAYMENT_METHOD,
  };
};

const rejectCreatePaymentMethod = (error) => {
  return{
    error: error,
    type: REJECT_CREATE_PAYMENT_METHOD,
  };
};

const completeGetTaxes = (payload) => {
  return {
    type: COMPLETE_GET_TAXES,
    payload: payload,
  };
};

const rejectGetTaxes = (error) => {
  return {
    type: REJECT_GET_TAXES,
    error: error,
  };
};

export const createPaymentMethod = (account, stripe, cardNumber) => async dispatch => {
  dispatch(startCreatePaymentMethod());

  let response = await stripe.createPaymentMethod({
    type: "card",
    card: cardNumber,
    billing_details: {
      email: account.email,
    },
  });

  if(response.error) {
    dispatch(rejectCreatePaymentMethod(response.error));
  }
  try {
    let result = await account.attachPaymentMethod(response);

    return dispatch(completeCreatePaymentMethod(result.id));
  } catch (e) {
    return dispatch(rejectCreatePaymentMethod(e));
  }
};

export const getTaxes = (account) => async dispatch => {
  try {
    let response = await account.getTaxes();

    return dispatch(completeGetTaxes(response));
  } catch (e) {
    return dispatch(rejectGetTaxes(e.response.error));
  }
};