import React from "react";
import PropTypes from "prop-types";
import TableCell from "../../../components/table/TableCell";
import StatusIndicator from "../../../components/status/StatusIndicator";
import Button from "../../../components/buttons/Button";
import { gettext as _ } from "../../../utils";
import Icon from "../../../components/icons/Icon";
import { ReactComponent as WrenchIcon } from "../../../icons/wrench.svg";
import TableRow from "../../../components/table/TableRow";
import Configuration from "../../../api/configurations/Configuration";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useParams } from "react-router-dom";


/**
 * Displays a configuration row in the configuration list.
 *
 * @container
 * @name Row
 */
export default function Row({ configuration: {
  id,
  name,
  enabled,
} }) {
  const dispatch = useDispatch();
  const { instanceId } = useParams();

  /**
   * Event handler for the edit button on the configuration row.
   */
  const handleClick = (e) => {
    e.preventDefault();

    dispatch(push(`/tool/instances/${ instanceId }/configurations/${ id }`));
  };

  return (
    <TableRow key={ id }>
      <TableCell width="10%">
        <StatusIndicator type={ enabled ? "success" : "error" }/>
      </TableCell>
      <TableCell width="100%">{ name }</TableCell>
      <TableCell width="auto">
        <Button size="extra-small" primary onClick={ handleClick } className="u-va-middle">
          { _("Edit") }
          <Icon>
            <WrenchIcon/>
          </Icon>
        </Button>
      </TableCell>
    </TableRow>
  );
}

Row.propTypes = {
  configuration: PropTypes.instanceOf(Configuration), // A gert configuration
};