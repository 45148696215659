import {gettext as dj_gettext, interpolate} from "django";
import Message from "./components/forms/fields/Message";
import React from "react";

export function get_cookie(name) {
  if(document.cookie && document.cookie !== "") {
    let cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();

      if(cookie.substring(0, name.length + 1) === (name + "=")) {
        return decodeURIComponent(cookie.substring(name.length + 1));
      }
    }
  }

  return null;
}

export function isDefined(prop) {
  return typeof prop !== "undefined";
}

export function hasLength(string) {
  return string.length > 0;
}

export function hasChanged(prevValue, newValue) {
  if (!prevValue) {
    prevValue = "";
  }
  return prevValue.toString() !== newValue.toString();
}

export function gettext(msgid, obj) {
  let s = dj_gettext(msgid);

  if(typeof obj !== "undefined") {
    return interpolate(s, obj, true);
  }

  return s;
}

export function flatten(arr) {
  return [].concat.apply([], arr);
}

export function renderErrors(arrayOrString) {
  if(!arrayOrString) {
    return null;
  }

  if(typeof arrayOrString === "string") {
    return <Message>{arrayOrString}</Message>;
  }

  return arrayOrString.map((err, i) => (
    <Message key={i}>{err}</Message>
  ));
}

export function renderStatus(string) {
  return <Message>{ gettext("Status of VAT verification is: ")+string }</Message>;
}

export function validateTag(tag) {
  return !/[^-./:^_~0-9a-zA-Z]/.exec(tag);
}

export function renderTagErrors(error, tag, tags) {
  if(error) {
    return renderErrors(error);
  }

  return !validateTag(tag)
    ? (
      <Message>{gettext("The tag contains illegal characters.")}</Message>
    )
    : arrayContains(tag, tags)
      ? <Message>{gettext("The tag is already present.")}</Message>
      : null;
}

export function arrayContains(needle, haystack) {
  return haystack.indexOf(needle) > -1;
}
