import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/layout/_content.scss";

const Content = ({className, children, withGrid}) => {
  return (
    <div className={cx(className, "content", {
      "content--with-grid": withGrid,
    })}>
      {children}
    </div>
  );
};

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  withGrid: PropTypes.bool,
};

export default Content;
