import {
  COMPLETE_CREATE_PAYMENT_METHOD,
  COMPLETE_GET_TAXES,
  REJECT_CREATE_PAYMENT_METHOD,
  REJECT_GET_TAXES,
  START_CREATE_PAYMENT_METHOD,
} from "../actions/checkouts";

const defaultState = {
  data: {},
  loading: false,
  error: null,
};

const checkoutsReducer = (state = defaultState, action) => {
  switch(action.type) {
    case START_CREATE_PAYMENT_METHOD:
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      };
    case COMPLETE_CREATE_PAYMENT_METHOD:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case REJECT_CREATE_PAYMENT_METHOD:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    case COMPLETE_GET_TAXES:
      return {
        ...state,
        loading: false,
        payload: action.payload,
        error: null,
      };
    case REJECT_GET_TAXES:
      return {
        ...state,
        loading: false,
        payload: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export default checkoutsReducer;