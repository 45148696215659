import "core-js";
import "regenerator-runtime/runtime";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./stores";
import Entry from "./components";
import "./styles/base/_globals.scss";
import "./styles/base/_typography.scss";
import "./images/logo.png";
import "./images/celebrating.png";
import "./images/lock.png";

const App = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Entry/>
      </ConnectedRouter>
    </Provider>
  );
};

render(<App/>, document.querySelector("main"));