import {CONFIGURATION_TYPES} from "../actions/configuration";

const defaultState = {
  detail: null,
  loading: true,
  error: null,
};

const configurationReducer = (state = defaultState, action) => {
  switch(action.type) {
    case CONFIGURATION_TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        detail: action.payload,
      };
    case CONFIGURATION_TYPES.FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CONFIGURATION_TYPES.LOADING:
      return {
        ...state,
        error: null,
        loading: true,
      };
    default:
      return state;
  }
};

export default configurationReducer;
