import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/truncate/_truncate.scss";

const Truncate = ({className, children}) => {
  return React.Children.map(children, child => React.cloneElement(child, {
    className: cx(className, child.props.className, "truncate"),
  }));
};

Truncate.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default Truncate;
