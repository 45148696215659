import Base from "../Base";
import User from "./User";

export default class Users extends Base {
  async signup(args) {
    return new User(this._api, await this._api.post("/users/signup/", args));
  }

  async create(args) {
    return new User(this._api, await this._api.post("/users/", args));
  }

  async get(id) {
    return new User(this._api, await this._api.get(`/users/${ id }/`));
  }

  async me() {
    return new User(this._api, await this._api.get("/users/me/"));
  }

  validate(args) {
    return this._api.post("/users/validate/", args);
  }

  requestPasswordReset(email) {
    return this._api.post("/users/reset-password/", {
      email,
    });
  }

  // TODO: Implement real method
  resetPassword(uidb64, token, password) {
    return this._api.put("/users/reset-password/", {
      uidb64,
      token,
      password,
    });
  }
}
