import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./entity-card.scss";


/**
 * Container for displaying entities such as users or instances as a card.
 *
 * @component
 * @name EntityCard
 */
export default function EntityCard({ children, onClick }) {
  let optionalProps = {};

  if(onClick) {
    optionalProps.onClick = onClick;
  }

  return (
    <div className={ cx("entity-card", {
      "entity-card--clickable": !!onClick,
    }) } { ...optionalProps }>
      { children }
    </div>
  );
}

EntityCard.propTypes = {
  children: PropTypes.node.isRequired, // Sub components goes here.
  onClick: PropTypes.func, // Click handler for card.
};