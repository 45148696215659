import Modal from "Components/modal";
import React from "react";
import Button from "Components/buttons/Button";
import { gettext as _ } from "src/utils";
import PropTypes from "prop-types";

import "Styles/components/confirmation-modal/_modal.scss";

/**
 *
 * @param show {boolean}
 * @param onClose {function}
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmationModal = ({ show, onClose }) => {
  const footer = (
    <div className="confirmation-modal__footer">
      <Button size="small" onClick={ onClose } secondary>{ _("Cancel") }</Button>
      <Button size="small" type="submit" primary>{ _("Confirm") }</Button>
    </div>
  );
  return (
    <Modal show={ show } title={ _("Are you sure?") } onClose={ onClose } footer={ footer } className="confirmation-modal" footerAlign="center">
      <p>{ _("Adding this configuration will affect your tickets.") }</p>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmationModal;
