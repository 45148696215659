import Client from "../api";

const VALIDATION_ACTION = {
  START_VALIDATION: "VALIDATION::START",
  COMPLETE_VALIDATION: "VALIDATION::COMPLETE",
  ERROR_VALIDATION: "VALIDATION::ERROR",
  RESET_VALIDATION: "VALIDATION::RESET",
  START_CREATE_USER: "VALIDATION::START_CREATE_USER",
  COMPLETE_CREATE_USER: "VALIDATION::COMPLETE_CREATE_USER",
  ERROR_CREATE_USER: "VALIDATION::ERROR_CREATE_USER",
};

const validate = payload => async dispatch => {
  dispatch(startValidation());

  try {
    dispatch(completeValidation(await Client.Users.validate(payload)));
  } catch(e) {
    dispatch(errorValidation(e));
  }
};

const startValidation = () => ({
  type: VALIDATION_ACTION.START_VALIDATION,
});

const completeValidation = payload => ({
  type: VALIDATION_ACTION.COMPLETE_VALIDATION,
  payload,
});

const errorValidation = payload => ({
  type: VALIDATION_ACTION.ERROR_VALIDATION,
  payload: payload,
});

const resetValidation = () => ({
  type: VALIDATION_ACTION.RESET_VALIDATION,
});

const createAccount = (payload, callback) => async dispatch => {
  dispatch(startCreateAccount());

  try {
    dispatch(completeCreateAccount(await Client.Users.signup(payload)));
    callback();
  } catch(e) {
    dispatch(errorCreateAccount(e));
  }
};

const startCreateAccount = () => ({
  type: VALIDATION_ACTION.START_CREATE_USER,
});

const completeCreateAccount = payload => ({
  type: VALIDATION_ACTION.COMPLETE_CREATE_USER,
  payload,
});

const errorCreateAccount = payload => ({
  type: VALIDATION_ACTION.ERROR_CREATE_USER,
  payload,
});

export {
  VALIDATION_ACTION,
  validate,
  resetValidation,
  createAccount,
  startValidation,
  completeValidation,
  errorValidation,
  startCreateAccount,
  completeCreateAccount,
  errorCreateAccount,
};
