import { push } from "connected-react-router";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-grid-system";
import { connect } from "react-redux";
import { fetchCurrentAccount } from "../actions/currentAccount";
import { updateOrganization } from "../actions/organization";
import { validateVAT } from "../actions/tax";
import subRoutes from "../routes/subRoutes";

import "../styles/base/_utils.scss";
import { gettext as _, hasChanged, renderErrors } from "../utils";
import AccountItem from "./accounts/AccountItem";
import AccountList from "./accounts/AccountList";
import Button from "./buttons/Button";
import Field from "./forms/fields/Field";
import Hint from "./forms/fields/Hint";
import Input from "./forms/fields/Input";
import Label from "./forms/fields/Label";

import Content from "./layout/Content";
import Detail from "./layout/Detail";
import PageHeader from "./layout/PageHeader";
import Sidebar from "./layout/Sidebar";
import Loader from "./loaders/Loader";
import TextBlock from "./text-blocks/TextBlock";
import Autocomplete from "Components/autocomplete";

const VATList = require("../data/vat.json");
const countriesList = require("../data/countries.json");

class OrganizationOverview extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.createNewAccount = this.createNewAccount.bind(this);
    this.handleVATChange = this.handleVATChange.bind(this);
    this.handleCountrySelect = this.handleCountrySelect.bind(this);

    this.state = {
      name: {
        value: "",
      },
      VAT: {
        value: "",
        error: "",
      },
      VATValidation: null,
      address: {
        value: "",
      },
      country: {
        value: "",
        label: _("Please choose your country"),
        error: null,
      },
      city: {
        value: "",
      },
      region: {
        value: "",
      },
      zipcode: {
        value: "",
      },
    };
  }

  render() {
    const { organization, tax } = this.props;
    const { VATValidation } = this.state;

    let error = organization.error;
    error = error && error.response;

    const nameErrors = error && error.name;
    const countryErrors = error && error.country;
    const addressErrors = error && error.address;
    const cityErrors = error && error.city;
    const regionErrors = error && error.region;
    const zipcodeErrors = error && error.zipcode;
    const VATErrors = error && error.VAT;
    const VATValidationErrors = this.state.VAT.error;

    return (
      <>
        <Sidebar links={ subRoutes.settings }/>
        <Content>
          <PageHeader>
            <TextBlock>
              <h2>{ _("Organization") }</h2>
            </TextBlock>
          </PageHeader>
          { organization.loading || tax.loading
            ? <Loader/>
            : (
              <>
                <Detail className="u-mb-lg" hasGrid>
                  <form onSubmit={ this.handleSubmit("organization") }>
                    <Container fluid>
                      <Row>
                        <Col>
                          <Field className="u-mb" isPristine={ !nameErrors } secondary value={ this.getValue("name") }
                            onChange={ this.handleChange("name") }>
                            <Label>
                              { _("Name") }
                              <span className="c-fg-danger">*</span>
                            </Label>
                            <Input/>
                            { renderErrors(nameErrors) }
                          </Field>
                          <Field className="u-mb">
                            <Label>
                              { _("Country") }
                              <span className="c-fg-danger">*</span>
                            </Label>
                            <Autocomplete suggestions={ countriesList.countries }
                              onSelect={ this.handleCountrySelect } value={ this.state.country.label }/>
                            { renderErrors(countryErrors) }
                          </Field>
                          <Field className="u-mb" isPristine={ !addressErrors } secondary
                            value={ this.getValue("address") }
                            onChange={ this.handleChange("address") }>
                            <Label>
                              { _("Address") }
                              <span className="c-fg-danger">*</span>
                            </Label>
                            <Input/>
                            { renderErrors(addressErrors) }
                          </Field>
                          <Field className="u-mb" isPristine={ !cityErrors } secondary value={ this.getValue("city") }
                            onChange={ this.handleChange("city") }>
                            <Label>
                              { _("City") }
                              <span className="c-fg-danger">*</span>
                            </Label>
                            <Input/>
                            { renderErrors(cityErrors) }
                          </Field>
                          <Field className="u-mb" isPristine={ !regionErrors } secondary
                            value={ this.getValue("region") }
                            onChange={ this.handleChange("region") }>
                            <Label>
                              { _("Region") }
                              <span className="c-fg-danger">*</span>
                            </Label>
                            <Input/>
                            { renderErrors(regionErrors) }
                          </Field>
                          <Field className="u-mb" isPristine={ !zipcodeErrors } secondary
                            value={ this.getValue("zipcode") }
                            onChange={ this.handleChange("zipcode") }>
                            <Label>
                              { _("Postal") }
                              <span className="c-fg-danger">*</span>
                            </Label>
                            <Input/>
                            { renderErrors(zipcodeErrors) }
                          </Field>
                          <Field className="u-mb" isPristine={ !VATErrors } secondary value={ this.getValue("VAT") }
                            onChange={ this.handleVATChange }>
                            <Label>
                              { _("VAT") }
                              { this.state.VATValidation && (
                                <span className="c-fg-danger">*</span>
                              ) }
                            </Label>
                            { VATValidation && (
                              <Hint>
                                { _("VAT number is expected to match format: {format}").replace("{format}", VATValidation.format) }
                              </Hint>
                            ) }
                            <Input/>
                            { renderErrors(VATErrors) }
                            { renderErrors(VATValidationErrors) }
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="u-ta-r">
                          <Button type="submit" disabled={ !this.hasChanged || this.hasError } size="small"
                            primary>{ _("Save") }</Button>
                        </Col>
                      </Row>
                    </Container>
                  </form>
                </Detail>
                <PageHeader>
                  <TextBlock constrained>
                    <h2>{ _("Users") }</h2>
                  </TextBlock>
                  <Button onClick={ this.createNewAccount } size="small" primary>{ _("Add user") }</Button>
                </PageHeader>
                <AccountList>
                  { this.props.accounts.list.map(account => (
                    <AccountItem key={ account.id } account={ account }/>
                  )) }
                </AccountList>
              </>
            ) }
        </Content>
      </>
    );
  }

  componentDidMount() {
    this.setDefaultState();
  }

  handleCountrySelect(selected) {
    this.prepareVAT(selected.alpha2);
    this.setState({
      country: {
        label: selected.label,
        value: selected.alpha2,
        error: null,
      },
    });
  }

  prepareVAT(country) {
    this.setState((prevState) => {
      const VATValidationIndex = VATList.findIndex((item) => item.id === country.toUpperCase());

      let VATValidation;

      if(VATValidationIndex > -1) {
        VATValidation = VATList[VATValidationIndex];
      } else {
        VATValidation = null;
      }

      return {
        VATValidation,
        VAT: {
          ...prevState.VAT,
          error: this.detectVATValidationError(VATValidation, prevState.VAT.value),
        },
      };
    });
  }

  detectVATValidationError(VATValidation, VAT) {
    let error = null;

    if(VATValidation) {
      const regex = new RegExp(VATValidation.regex);
      const isValid = regex.test(VAT);

      if(!isValid && VAT.length > 0) {
        error = _("The filled in VAT did not match the format: {format}").replace(
          "{format}",
          VATValidation.format,
        );
      }
    }

    return error;
  }

  handleVATChange({ target: { value } }) {
    this.setState((prevState) => {
      const { VATValidation } = prevState;

      return {
        VAT: {
          ...prevState.VAT,
          error: this.detectVATValidationError(VATValidation, value),
          value,
        },
      };
    });
  }

  get fields() {
    return ["name", "country", "address", "city", "region", "zipcode", "VAT"];
  }

  get hasChanged() {
    return this.fields.some((field) => {
      return hasChanged(this.getValue(field), this.props.organization.detail[field] || "");
    });
  }

  get hasError() {
    const { organization } = this.props;

    let error = organization.error && organization.error.response;

    return this.fields.some((field) => {
      return (typeof error === "object" && error !== null) && !!error[field] || !!this.state[field].error;
    });
  }

  getValue(key) {
    return this.state[key].value;
  }

  setDefaultState() {
    const organization = this.props.organization.detail;
    let VATValidation;
    let VATIndex = "";
    if(organization.country) {
      VATIndex = VATList.findIndex((item) => item.id === organization.country.toUpperCase());
      VATValidation = VATList[VATIndex];
    }

    const _generateState = (state, key, organization) => ({
      ...state[key],
      value: organization[key] || "",
    });

    this.setState(prevState => {
      const generateState = key => _generateState(prevState, key, organization);

      return {
        name: generateState("name"),
        country: {
          ...generateState("country"),
          label: this._generateCountry(organization.country),
        },
        address: generateState("address"),
        city: generateState("city"),
        region: generateState("region"),
        zipcode: generateState("zipcode"),
        VAT: generateState("VAT"),
        VATValidation: VATValidation || null,
      };
    });
  }

  _generateCountry(country) {
    if(country != null && country.length > 0) {
      return countriesList.countries.find((item) => item.alpha2 === country).label;
    } else {
      return "";
    }
  }

  createNewAccount(e) {
    e.preventDefault();

    this.props.push("/settings/organization/accounts/new");
  }

  handleChange(key) {
    return e => {
      e.preventDefault();

      let value = e.target.value;

      this.setState(prevState => ({
        [key]: {
          ...prevState[key],
          value,
        },
      }));
    };
  }

  handleSubmit(form) {
    return async e => {
      e.preventDefault();

      if(form === "organization" && this.hasChanged && !this.hasError) {
        const payload = {
          name: this.state.name.value,
          country: this.state.country.value,
          address: this.state.address.value,
          city: this.state.city.value,
          region: this.state.region.value,
          zipcode: this.state.zipcode.value,
        };

        const tax = await this.props.validateVat({
          organization: this.props.organization.detail,
          VAT: this.state.VAT.value,
          country: this.state.country.value,
        });

        if(tax) {
          payload.VAT = this.state.VAT.value;

          this.props.updateOrganization({
            organization: this.props.organization.detail,
            args: payload,
          });
        }
      }
    };
  }
}

OrganizationOverview.propTypes = {
  user: PropTypes.object,
  organization: PropTypes.object,
  accounts: PropTypes.object,
  fetchCurrentUser: PropTypes.func,
  updateOrganization: PropTypes.func,
  push: PropTypes.func,
  validateVat: PropTypes.func,
  tax: PropTypes.object,
};

export default connect(
  state => ({
    user: state.user,
    organization: state.organization,
    accounts: state.accounts,
    tax: state.tax,
  }),
  dispatch => ({
    fetchCurrentUser: () => dispatch(fetchCurrentAccount()),
    updateOrganization: args => dispatch(updateOrganization(args)),
    push: to => dispatch(push(to)),
    validateVat: (args) => dispatch(validateVAT(args)),
  }),
)(OrganizationOverview);
