import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/layout/_footer.scss";
import WhiteLogo from "./WhiteLogo";

const Footer = ({className}) => {
  return (
    <footer className={cx(className, "footer")}>
      <div className="footer__content">
        <WhiteLogo className="footer__logo"/>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
