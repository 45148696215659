import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import {Redirect, Route, Switch} from "react-router-dom";

import Page from "./layout/Page";
import Organization from "./Organization";
import Subscription from "./Subscription";
import MainNavigation from "./layout/MainNavigation";
import Header from "./layout/Header";
import {connect} from "react-redux";
import {push} from "connected-react-router";
import PrivateRoute from "./common/PrivateRoute";
import NotFoundContent from "./common/NotFoundContent";

class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  render() {
    const {className, match} = this.props;

    return  (
      <Page className={cx(className)}>
        <Header>
          <MainNavigation/>
        </Header>
        <Switch>
          <PrivateRoute exact path={match.path} routeRender={() => <Redirect to={`${match.path}/organization`}/>}/>
          <PrivateRoute path={`${match.path}/organization`} component={Organization} />
          <PrivateRoute path={`${match.path}/subscription`} component={Subscription}/>
          <Route path="*" component={NotFoundContent}/>
        </Switch>
      </Page>
    );
  }

  handleClick(e) {
    e.preventDefault();

    this.props.push("/tool/instances/");
  }
}

Settings.propTypes = {
  className: PropTypes.string,
  match: PropTypes.object,
  push: PropTypes.func,
};

export default connect(
  null,
  dispatch => ({
    push: to => dispatch(push(to)),
  }),
)(Settings);
