import {get_cookie, isDefined} from "../utils";
import Instances from "./instances/Instances";
import Configurations from "./configurations/Configurations";
import Taxes from "./taxes/Taxes";
import Users from "./users/Users";
import Subscriptions from "./subscriptions/Subscriptions";
import Checkouts from "./checkout/Checkouts";
import Organizations from "./organizations/Organizations";

async function createErrorObject(response) {
  let error = {
    status: response.status,
    statusText: response.statusText,
  };

  if(response.json) {
    error.response = await response.json();
  }

  return error;
}

async function handleErrors(response) {
  if(response.ok) {
    return response.json && response.status !== 204
      ? await response.json()
      : {};
  }

  throw await createErrorObject(response);
}

class Client {
  constructor() {
    this.version = "v1";
  }

  get Subscriptions() {
    if(!isDefined(this._Subscription)) {
      this._Subscription = new Subscriptions(this);
    }

    return this._Subscription;
  }

  get Users() {
    if(!isDefined(this._Users)) {
      this._Users = new Users(this);
    }

    return this._Users;
  }

  get Organizations() {
    if(!isDefined(this._Organizations)) {
      this._Organizations = new Organizations(this);
    }

    return this._Organizations;
  }

  get Instances() {
    if(!isDefined(this._Instances)) {
      this._Instances = new Instances(this);
    }

    return this._Instances;
  }

  get Configurations() {
    if(!isDefined(this._Configurations)) {
      this._Configurations = new Configurations(this);
    }

    return this._Configurations;
  }

  get Checkouts() {
    if(!isDefined(this._Checkouts)) {
      this._Checkouts = new Checkouts(this);
    }

    return this._Checkouts;
  }

  get Taxes() {
    if(!isDefined(this._Taxes)) {
      this._Taxes = new Taxes(this);
    }

    return this._Taxes;
  }

  authorize(credentials) {
    return this.post("/auth/", credentials, undefined, false);
  }

  logout() {
    return this.delete("/auth/", undefined, false);
  }

  async request(path, json, method, headers, version = true) {
    if(typeof json !== "undefined" && typeof method === "undefined") {
      method = "POST";
    }

    if(typeof method === "undefined") {
      method = "GET";
    }

    if(typeof headers === "undefined") {
      headers = {};
    }

    headers = Object.assign({}, this.getHeaders(), headers);

    let options = {
      method: method,
      headers: headers,
      body: json && JSON.stringify(json),
    };

    return await handleErrors(
      await fetch(`/api${version ? `/${this.version}` : ""}${path}`, options),
    );
  }

  getHeaders() {
    let default_headers = {
      "Content-Type": "application/json",
    };

    let csrftoken = get_cookie("csrftoken");

    if(csrftoken) {
      default_headers["X-CSRFToken"] = csrftoken;
    }

    return default_headers;
  }

  get(path, json = null, headers = undefined, version) {
    return this.request(path, json, "GET", headers, version);
  }

  post(path, json = null, headers = undefined, version) {
    return this.request(path, json, "POST", headers, version);
  }

  patch(path, json = null, headers = undefined, version) {
    return this.request(path, json, "PATCH", headers, version);
  }

  put(path, json = null, headers = undefined, version) {
    return this.request(path, json, "PUT", headers, version);
  }

  delete(path, headers = undefined, version) {
    return this.request(path, null, "DELETE", headers, version);
  }
}

export default new Client();
