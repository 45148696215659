import React from "react";

import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import PrivateRoute from "../../../components/common/PrivateRoute";
import Instances from "../../../components/Instances";
import NotFoundContent from "../../../components/common/NotFoundContent";


/**
 * Displays all routes related to the tool section.
 *
 * @component
 * @name Routes
 */
export default function Routes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={ path } routeRender={ () => <Redirect to={ `${ path }/instances` }/> }/>
      <PrivateRoute path={ `${ path }/instances` } component={ Instances }/>
      <Route path="*" component={ NotFoundContent }/>
    </Switch>
  );
}