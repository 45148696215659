import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-grid-system";
import Dropdown from "../../../components/dropdown/Dropdown";
import Label from "../../../components/forms/fields/Label";
import { gettext as _ } from "../../../utils";
import Hint from "../../../components/forms/fields/Hint";
import Trigger from "../../../components/dropdown/Trigger";
import Button from "../../../components/buttons/Button";
import { Rule, RuleAction, RuleText } from "../../../components/rule";

const excludeOptions = [
  {
    value: null,
    label: _("No action"),
  },
  {
    value: 100,
    label: _("Include"),
  },
  {
    value: 200,
    label: _("Exclude"),
  },
];

/**
 * Displays form elements to select processing of custom fields within a configuration.
 *
 * @container
 * @name ConfigurationCustomFields
 */
export default function ConfigurationCustomFields({
  fields,
  fieldType,
  onHandleFields,
  selectedFields,
  exclude,
  onExclude,
  isDeleteMethod,
}) {
  const [selected, setSelected] = useState(null);

  const options = fields && fields.filter(({ id }) => !selectedFields.includes(id)).map((item) => ({
    ...item,
    label: item.title,
    value: item.id,
  }));

  const isUnspecified = !fieldType;
  const isEmpty = options && !options.length > 0;
  const isDisabled = isUnspecified || isEmpty || isDeleteMethod;

  const add = onHandleFields("add");
  const remove = onHandleFields("remove");

  const handleAdd = () => add(selected.id);

  function handleExclude(selected) {
    onExclude(selected.value);
  }

  return (
    <>
      <Row className="u-mb">
        <Col xs={ 6 }>
          <Dropdown selected={ selected } onSelect={ setSelected }
            options={ options } disabled={ isDisabled }>
            <Label>
              { _("Custom fields") }
            </Label>
            <Hint>{ _("Select custom fields to be included in the configuration. Only custom fields can be selected.") }</Hint>
            <Trigger>
              { selected && !isDisabled
                ? selected.label
                : isUnspecified || isDeleteMethod
                  ? _("No fields available for this query")
                  : isEmpty
                    ? _("No fields to select")
                    : _("Choose field")
              }
            </Trigger>
          </Dropdown>
        </Col>
        <Col style={ {
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        } }>
          <Button type="button" onClick={ handleAdd } primary size="small" disabled={ isDisabled }>{ _("Add field to configuration") }</Button>
        </Col>
      </Row>
      <Row className="u-mb">
        <Col xs={ 6 }>
          <Dropdown selected={ exclude } onSelect={ handleExclude }
            options={ excludeOptions } disabled={ isUnspecified || isDeleteMethod }>
            <Hint>{ _("Choose which action to perform on the selected fields.") }</Hint>
            <Trigger>
              { (excludeOptions.find((option) => option.value === exclude)).label }
            </Trigger>
          </Dropdown>
        </Col>
      </Row>
      <Row className="u-mb">
        <Col>
          <div>
            { fields && fields.filter(({ id }) => selectedFields.includes(id)).map(({ id, title }) => {
              const handleClick = (e) => {
                e.preventDefault();

                remove(id);
              };

              const removeButton = <RuleAction type="danger" onClick={ handleClick }>{ _("Remove") }</RuleAction>;

              return (
                <Rule key={ id } action={ removeButton }>
                  <RuleText className="u-mb-xxs" size="large" bold>{ title }</RuleText>
                  <RuleText>
                    id: { id }
                  </RuleText>
                </Rule>
              );
            }) }
          </div>
        </Col>
      </Row>
    </>
  );
}

ConfigurationCustomFields.defaultProps = {
  fields: [],
};

ConfigurationCustomFields.propTypes = {
  // The list of custom fields associated to an instance
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  })),
  // The type of fields that is provided. Currently supports `ticket_fields` and `user_fields`. The type is determined by looking at the query
  fieldType: PropTypes.oneOf(["ticket_fields", "user_fields"]),
  // Higher order function that takes the following parameters: `add` and `remove`. Used for managing selected fields
  onHandleFields: PropTypes.func,
  // The currently selected fields ids
  selectedFields: PropTypes.arrayOf(PropTypes.number),
  // The action for the fields. Can be one of following: `null`, `100` and `200`. 100 is include and 200 is exclude. Null means no action
  exclude: PropTypes.oneOf([null, 100, 200]),
  // Event handler for the exclusion dropdown
  onExclude: PropTypes.func,
  // If true, the configurations fields will be disabled
  isDeleteMethod: PropTypes.bool,
};