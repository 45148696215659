import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { connect } from "react-redux";
import TextBlock from "../text-blocks/TextBlock";
import Label from "../forms/fields/Label";
import Input from "../forms/fields/Input";
import Message from "../forms/fields/Message";
import Field from "../forms/fields/Field";

import "../../styles/base/_utils.scss";
import "../../styles/components/login-form/_reset-password-form.scss";
import Button from "../buttons/Button";
import { requestReset, setDefaultRequestReset } from "src/actions/reset";
import { push } from "connected-react-router";
import Hint from "../forms/fields/Hint";

import { gettext as _ } from "../../utils";
import Loader from "../loaders/Loader";
import Callout from "../callout/Callout";
import Icon from "../icons/Icon";
import { ReactComponent as SuccessIcon } from "../../icons/done.svg";
import ButtonGroup from "../buttons/ButtonGroup";
import BackButton from "../buttons/BackButton";

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      email: "",
    };
  }

  render() {
    const { reset } = this.props;
    const error = reset.error;

    return !reset.loading
      ? (
        <form className={ cx(this.props.className, "reset-password-form", "u-form") } onSubmit={ this.handleSubmit }>
          <TextBlock className="u-mb-lg">
            <h2>{ _("Reset password") }</h2>
            { !reset.detail && (
              <p>{ _("Enter your work email below to reset your password.") }</p>
            ) }
            { reset.detail && (
              <Callout className="u-mt-lg" type="success">
                <Icon width="100%" height="100%">
                  <SuccessIcon/>
                </Icon>
                <p className="hug">{ _("An email with instructions has been sent.") }</p>
              </Callout>
            ) }
          </TextBlock>
          { !reset.detail && (
            <Field isPristine={ !error } className="u-mb" secondary onChange={ this.handleChange }
              value={ this.state.email }>
              <Label>{ _("Email") }</Label>
              <Hint>{ _(
                "Please enter your work email here and we will send you a link to reset your password.") }</Hint>
              <Input type="email"/>
              { error && (
                <Message>
                  { _("Something went wrong. Please try again.") }
                </Message>
              ) }
            </Field>
          ) }
          { reset.detail ? (
            <ButtonGroup center>
              <BackButton onClick={ this.handleCancel }>{ _("Go to login") }</BackButton>
            </ButtonGroup>
          ) : (
            <ButtonGroup center>
              <BackButton onClick={ this.handleCancel }>{ _("Go to login") }</BackButton>
              <Button type="submit" primary className="u-mb-xs">{ _("Reset password") } </Button>
            </ButtonGroup>
          ) }
        </form>
      )
      : <Loader/>;
  }

  componentWillUnmount() {
    this.props.setDefaultRequestReset();
  }

  async handleSubmit(e) {
    e.preventDefault();

    if(this.state.email.length > 0) {
      await this.props.requestReset(this.state.email);

      this.setState({
        email: "",
      });
    }
  }

  handleChange(e) {
    e.preventDefault();

    this.setState({
      email: e.target.value,
    });
  }

  handleCancel(e) {
    e.preventDefault();

    this.props.goToLogin();
  }
}

ResetPasswordForm.propTypes = {
  className: PropTypes.string,
  reset: PropTypes.object,
  requestReset: PropTypes.func,
  goToLogin: PropTypes.func,
  setDefaultRequestReset: PropTypes.func,
};

export default connect(
  state => ({
    reset: state.reset,
  }),
  dispatch => ({
    requestReset: email => dispatch(requestReset(email)),
    setDefaultRequestReset: () => dispatch(setDefaultRequestReset()),
    goToLogin: () => dispatch(push("/")),
  }),
)(ResetPasswordForm);
