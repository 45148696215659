import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/layout/_detail.scss";

const Detail = ({className, children, hasGrid}) => {
  return (
    <div className={cx(className, "detail")}>
      <div className={cx("detail__inner", {
        "detail__inner--has-grid": hasGrid,
      })}>
        {children}
      </div>
    </div>
  );
};

Detail.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  hasGrid: PropTypes.bool,
};

export default Detail;
