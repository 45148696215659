import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./search-field.scss";


/**
 * Container for search field.
 *
 * @component
 * @name SearchField
 */
export default function SearchField({ className, children, constrained }) {
  return (
    <div className={cx(className, "search-field", {
      "search-field--constrained": constrained,
    })}>
      { children }
    </div>
  );
}

SearchField.propTypes = {
  className: PropTypes.string, // Additional classes to add to the container.
  children: PropTypes.node.isRequired, // Sub components of search field.
  constrained: PropTypes.bool, // Constrains the width of the field, if true.
};