import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../../styles/components/forms/_hint.scss";

const Hint = ({className, children}) => {
  return (
    <div className={cx(className, "hint")}>
      {children}
    </div>
  );
};

Hint.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default Hint;
