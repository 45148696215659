import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./action-button.scss";


/**
 * Container component for an action button.
 *
 * @component
 * @name ActionButton
 */
export default function ActionButton({ className, children, pinned }) {
  return (
    <div className={ cx(className, "action-button", {
      "action-button--pinned": pinned,
    }) }>
      { children }
    </div>
  );
}

ActionButton.propTypes = {
  className: PropTypes.string, // Additional classes
  children: PropTypes.node.isRequired, // Sub components for the action button
  pinned: PropTypes.bool, // Indicates if the button is pinned to lower right corner.
};