import Entity from "../Entity";
import Organization from "../organizations/Organization";

export default class User extends Entity {
  generateProps(entity) {
    Object.entries(entity).forEach(([key, val]) => {
      if(key === "organization") {
        this.organization = new Organization(this._api, val);
        this.organization_id = val.id;
      } else {
        this[key] = val;
      }
    });
  }

  delete() {
    return this._api.delete(`/users/${this.id}/`);
  }

  async partialUpdate(args) {
    return new User(this._api, await this._api.patch(`/users/${this.id}/`, args));
  }

  get fullName() {
    return `${this.first_name} ${this.last_name}`;
  }

  // start subscription
  subscribe(session, method, tax) {
    return this._api.Subscriptions.subscribe(session, method, tax);
  }

  // get session information
  getCheckoutSession(){
    return this._api.Checkouts.session();
  }

  getTaxes() {
    return this._api.Taxes.tax();
  }

  // get attach payment method to customer secret
  attachPaymentMethod(payment_method){
    return this._api.Checkouts.attachPaymentMethod(payment_method);
  }

}

