import { push } from "connected-react-router";
import Client from "../api";
import { fetchCurrentAccount } from "./currentAccount";

const LOGIN_ACTIONS = {
  START_LOGIN: "LOGIN::START",
  COMPLETE_LOGIN: "LOGIN::COMPLETE",
  ERROR_LOGIN: "LOGIN::ERROR",
};

const authorize = payload => async dispatch => {
  dispatch(startLogin());

  try {
    dispatch(completeLogin(await Client.authorize(payload)));
    dispatch(await fetchCurrentAccount());
    dispatch(push("/tool/instances/"));
  } catch (e) {
    dispatch(errorLogin(e));
  }
};

const startLogin = () => ({
  type: LOGIN_ACTIONS.START_LOGIN,
});

const completeLogin = payload => ({
  type: LOGIN_ACTIONS.COMPLETE_LOGIN,
  payload,
});

const errorLogin = payload => ({
  type: LOGIN_ACTIONS.ERROR_LOGIN,
  payload,
});

export {
  LOGIN_ACTIONS,
  authorize,
};
