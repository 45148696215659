import {ORGANIZATION_ACTIONS} from "../actions/organization";

const defaultState = {
  detail: null,
  loading: true,
  error: null,
};

const organizationReducer = (state = defaultState, action) => {
  switch(action.type) {
    case ORGANIZATION_ACTIONS.UPDATE_COMPLETED:
    case ORGANIZATION_ACTIONS.FETCH_COMPLETED:
      return {
        ...state,
        detail: action.payload,
        error: null,
        loading: false,
      };
    case ORGANIZATION_ACTIONS.START_UPDATE:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ORGANIZATION_ACTIONS.UPDATE_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default organizationReducer;
