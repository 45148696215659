import React, { useState } from "react";

import subRoutes from "../../routes/subRoutes";
import Sidebar from "../../components/layout/Sidebar";
import Content from "../../components/layout/Content";
import { AddButton, Header, List } from "./index";

export default function InstanceList() {
  /**
   * Define state for the search bar in the header.
   */
  const [ searchValue, setSearchValue ] = useState("");

  return (
    <>
      <Sidebar links={subRoutes.tool}/>
      <Content>
        <Header searchValue={ searchValue } setSearchValue={ setSearchValue }/>
        <List searchValue={ searchValue }/>
        <AddButton/>
      </Content>
    </>
  );
}