import {push} from "connected-react-router";

const SESSIONS_ACTIONS = {
  ERROR_CREATE_SESSION: "SESSIONS::ERROR_CREATE_SESSION",
  RESET_SESSION: "SESSIONS::RESET_SESSION",
  COMPLETE_CREATE_CHECKOUT_SESSION:"SESSIONS::COMPLETE_CREATE_CHECKOUT_SESSION",
  START_CREATE_CHECKOUT_SESSION:"SESSIONS::START_CREATE_CHECKOUT_SESSION",
};


// get session information about purchased items
const createCheckoutSession = payload => async dispatch => {
  dispatch(startCreateCheckoutSession());

  try {
    dispatch(completeCreateCheckoutSession(await payload.getCheckoutSession()));
    dispatch(push("/settings/subscription/checkout"));
  } catch(e) {
    dispatch(errorCreateSession(e));
  }
};

const resetSession = () => dispatch => {
  dispatch({
    type: SESSIONS_ACTIONS.RESET_SESSION,
  });

  dispatch(push("/settings/subscription"));
};

const startCreateCheckoutSession = () => ({
  type: SESSIONS_ACTIONS.START_CREATE_CHECKOUT_SESSION,
});

const completeCreateCheckoutSession = payload => ({
  type: SESSIONS_ACTIONS.COMPLETE_CREATE_CHECKOUT_SESSION,
  payload,
});

const errorCreateSession = payload => ({
  type: SESSIONS_ACTIONS.ERROR_CREATE_SESSION,
  payload,
});

export {
  SESSIONS_ACTIONS,
  createCheckoutSession,
  resetSession,
};
