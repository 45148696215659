import Base from "../Base";
import Subscription from "./Subscription";

export default class Subscriptions extends Base {
  async subscribe(session, method, tax) {
    return new Subscription(this._api, await this._api.post("/subscriptions/", {session, method, tax}));
  }

  async get(id) {
    return new Subscription(this._api, await this._api.get(`/subscriptions/${id}/`));
  }
}
