import {push} from "connected-react-router";

const SUBSCRIPTIONS_ACTIONS = {
  COMPLETE_FETCH_SUBSCRIPTION: "SUBSCRIPTIONS::COMPLETE_FETCH_SUBSCRIPTION",
  START_CANCEL_SUBSCRIPTION: "SUBSCRIPTIONS::START_CANCEL_SUBSCRIPTION",
  COMPLETE_CANCEL_SUBSCRIPTION: "SUBSCRIPTIONS::COMPLETE_CANCEL_SUBSCRIPTION",
  ERROR_CANCEL_SUBSCRIPTION: "SUBSCRIPTIONS::ERROR_CANCEL_SUBSCRIPTION",
  START_CREATE_SUBSCRIPTION: "SUBSCRIPTION::START_CREATE_SUBSCRIPTION",
  COMPLETE_CREATE_SUBSCRIPTION: "SUBSCRIPTION::COMPLETE_CREATE_SUBSCRIPTION",
  ERROR_CREATE_SUBSCRIPTION: "SUBSCRIPTION::ERROR_CREATE_SUBSCRIPTION",
};

function delay(exec) {
  return new Promise(resolve => setTimeout(() => resolve(exec), 8000));
}

const createSubscription = (account, items, method, tax, stripe) => async dispatch => {
  dispatch(startCreateSubscription());
  let response = await account.subscribe(items, method, tax);

  if(response.error && !response.payment_intent) {
    dispatch(errorCreateSubscription(response.error));
  }

  if(response.payment_intent) {
    let confirmation = await stripe.confirmCardPayment(response.payment_intent, {
      payment_method: method,
    });

    if(confirmation.error){
      dispatch(errorCreateSubscription(confirmation.error));
    } else {
      dispatch(completeCreateSubscription(response));
      dispatch(push("/settings/subscription/success/"));
    }

  } else {
    dispatch(completeCreateSubscription(response));
    dispatch(push("/settings/subscription/success/"));
  }
};

const completeFetchSubscription = payload => ({
  type: SUBSCRIPTIONS_ACTIONS.COMPLETE_FETCH_SUBSCRIPTION,
  payload,
});

const cancelSubscription = payload => async dispatch => {
  dispatch(startCancelSubscription());

  try {
    const cancel = await payload.unsubscribe();

    await delay(dispatch(completeCancelSubscription(cancel)));
  } catch(e) {
    dispatch(errorCancelSubscription(e));
  }
};

const startCreateSubscription = () => ({
  type: SUBSCRIPTIONS_ACTIONS.START_CREATE_SUBSCRIPTION,
});

const completeCreateSubscription = (payload) => {
  return {
    type: SUBSCRIPTIONS_ACTIONS.COMPLETE_CREATE_SUBSCRIPTION,
    payload,
  };
};

const startCancelSubscription = () => ({
  type: SUBSCRIPTIONS_ACTIONS.START_CANCEL_SUBSCRIPTION,
});

const completeCancelSubscription = payload => ({
  type: SUBSCRIPTIONS_ACTIONS.COMPLETE_CANCEL_SUBSCRIPTION,
  payload,
});

const errorCancelSubscription = payload => ({
  type: SUBSCRIPTIONS_ACTIONS.ERROR_CANCEL_SUBSCRIPTION,
  payload,
});

const errorCreateSubscription = (payload) => ({
  type: SUBSCRIPTIONS_ACTIONS.ERROR_CREATE_SUBSCRIPTION,
  payload,
});

export {
  SUBSCRIPTIONS_ACTIONS,
  completeFetchSubscription,
  cancelSubscription,
  createSubscription,
};
