import React from "react";
import PropTypes from "prop-types";

import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./common/PrivateRoute";
import NotFoundContent from "./common/NotFoundContent";
import { InstanceList } from "../containers/instance-list";
import { ConfigurationList } from "../containers/configuration-list";
import { connect } from "react-redux";
import { Configuration } from "../containers/configuration";

class Instances extends React.Component {
  render() {
    const { match, instance } = this.props;

    return (
      <Switch>
        <PrivateRoute exact path={ match.path } component={ InstanceList }/>
        <PrivateRoute exact path={ `${ match.path }/:instanceId` } component={ ConfigurationList }/>
        <PrivateRoute exact path={ `${ match.path }/:instanceId/configurations/create` } routeRender={ () => (
          <Configuration instance={ instance }/>
        ) }/>
        <PrivateRoute path={ `${ match.path }/:instanceId/configurations/:configurationId` }
          routeRender={ ({ match }) => (
            <Configuration instance={ instance } configurationId={ match.params.configurationId } update/>
          ) }/>
        <Route path="*" component={ NotFoundContent }/>
      </Switch>
    );
  }
}

Instances.propTypes = {
  match: PropTypes.object,
  instance: PropTypes.object,
};

export default connect(
  (state) => ({
    instance: state.instance,
  }),
)(Instances);
