import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./search-field-input.scss";


/**
 * The input of the search field.
 *
 * @component
 * @name SearchFieldInput
 */
export default function SearchFieldInput({ className, value, onChange }) {
  return <input className={ cx(className, "search-field-input") } value={ value } onChange={ onChange } />;
}

SearchFieldInput.propTypes = {
  className: PropTypes.string, // Additional classes that should be applied.
  value: PropTypes.string.isRequired, // The value of the input
  onChange: PropTypes.func.isRequired, // The on change handler for the input
};