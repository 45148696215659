import React from "react";

import MainNavigation from "../../../components/layout/MainNavigation";
import HeaderComponent from "../../../components/layout/Header";


/**
 * Displays the main header for the tool section.
 *
 * @component
 * @name Header
 */
export default function Header() {
  return (
    <HeaderComponent>
      <MainNavigation/>
    </HeaderComponent>
  );
}