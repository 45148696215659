import React from "react";
import PropTypes from "prop-types";
import { gettext as _ } from "../../../utils";

import {
  ActionButton,
  ActionButtonMenu,
  ActionButtonMenuItem,
  ActionButtonTrigger,
} from "../../../components/action-button";
import { withActionButtonAnimation } from "../../../enhancers/with-action-button-animation";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useRouteMatch } from "react-router-dom";


/**
 * Displays the add button in context of the instance list.
 *
 * @container
 * @name AddButton
 */
function AddButton({ actionButtonItems, handleActionButtonHover, isActionButtonOpen }) {
  const dispatch = useDispatch();
  const { params: {
    instanceId,
  } } = useRouteMatch();

  /**
   * Click handler for the button.
   *
   * @param {string} key - the identifier that decides what is going to happen when the button is clicked.
   * @returns {function(...[*]=)}
   */
  const handleClick = (key) => (e) => {
    e.preventDefault();

    switch(key) {
      case "add-configuration":
        dispatch(push(`${ instanceId }/configurations/create`));
        break;
      default:
        return false;
    }
  };

  const sharedProps = {
    isOpen: isActionButtonOpen,
    onHover: handleActionButtonHover,
  };

  return (
    <ActionButton pinned>
      <ActionButtonTrigger { ...sharedProps } />
      <ActionButtonMenu { ...sharedProps }>
        { actionButtonItems.map(({ label, key, isVisible }) => (
          <ActionButtonMenuItem key={ key } onClick={ handleClick(key) } isVisible={ isVisible }>
            { label }
          </ActionButtonMenuItem>
        )) }
      </ActionButtonMenu>
    </ActionButton>
  );
}


AddButton.propTypes = {
  actionButtonItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
      isVisible: PropTypes.bool,
    }),
  ),
  handleActionButtonHover: PropTypes.func,
  isActionButtonOpen: PropTypes.bool,
};


export default withActionButtonAnimation(AddButton, [
  {
    label: _("Add configuration"),
    key: "add-configuration",
    isVisible: false,
  },
]);