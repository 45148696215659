import React, {Children} from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/icons/_icon.scss";

const TYPES = {
  ERROR: "error",
  WARNING: "warning",
  SUCCESS: "success",
};

// TODO: Use this component for all icons
const Icon = ({children, className, type, width, height}) => {
  let props = {
    className: cx("icon__svg", {
      "icon__svg--error": type === TYPES.ERROR,
      "icon__svg--warning": type === TYPES.WARNING,
      "icon__svg--success": type === TYPES.SUCCESS,
      "icon__svg--default": !width || !height,
    }),
  };
  
  if(width) {
    props.width = width;
  }
  
  if(height) {
    props.height = height;
  }
  
  return (
    <div className={cx(className, "icon")}>
      {React.cloneElement(Children.only(children), props)}
    </div>
  );
};

Icon.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default Icon;
export {
  TYPES,
};
