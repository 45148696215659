import React from "react";
import PropTypes from "prop-types";

import Content from "./layout/Content";
import FooterPage from "./layout/FooterPage";
import Start from "./signup-form/Start";
import End from "./signup-form/End";
import { connect } from "react-redux";
import Header from "./layout/Header";
import Loader from "./loaders/Loader";
import { createAccount, resetValidation, validate } from "../actions/validation";

import { Col, Container, Row } from "react-grid-system";

export class Signup extends React.Component {
  constructor(props) {
    super(props);

    this.doSignup = this.doSignup.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      is_authenticated: false,
      successful_sign_up: false,
      fields: {
        email: {
          value: "",
          error: null,
        },
        password: {
          value: "",
          error: null,
        },
        conditions: {
          value: false,
          error: null,
        },
        first_name: {
          value: "",
          error: null,
        },
        last_name: {
          value: "",
          error: null,
        },
        organization_name: {
          value: "",
          error: null,
        },
      },
    };
  }

  handleChange(key) {
    return e => {
      let target = e.target;
      let value;

      if(target.type !== "checkbox") {
        e.preventDefault();

        value = target.value;
      } else {
        value = target.checked;
      }

      this.setState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          [key]: {
            ...prevState.fields[key],
            value,
          },
        },
      }));
    };
  }

  async doSignup(e) {
    e.preventDefault();

    const { fields } = this.state;

    this.props.createUser({
      "email": fields.email.value,
      "password": fields.password.value,
      "first_name": fields.first_name.value,
      "last_name": fields.last_name.value,
      "organization": {
        "name": fields.organization_name.value,
      },
    }, () => {
      const { validation } = this.props;

      this.setState({
        successful_sign_up: validation.created.detail && !validation.created.error,
      });
    });
  }

  render() {
    const { fields } = this.state;

    const _props = {
      onChange: this.handleChange,
      fields,
    };

    if(this.props.validation.loading) {
      return <Loader page/>;
    }

    return (
      <FooterPage size="large">
        <Header/>
        <Content>
          { this.state.successful_sign_up ? <End/> : <Container fluid>
            <Row justify="center">
              <Col sm="content">
                <form onSubmit={ this.doSignup } className="u-form">
                  <Start { ..._props }/>
                </form>
              </Col>
            </Row>
          </Container> }
        </Content>
      </FooterPage>
    );
  }
}

Signup.propTypes = {
  validation: PropTypes.object,
  createUser: PropTypes.func,
  validateAccount: PropTypes.func,
  resetValidation: PropTypes.func,
  validate: PropTypes.func,
};

export default connect(
  state => ({
    validation: state.validation,
  }),
  dispatch => ({
    createUser: (args, callback) => dispatch(createAccount(args, callback)),
    resetValidation: () => dispatch(resetValidation()),
    validate: args => dispatch(validate(args)),
  }),
)(Signup);
