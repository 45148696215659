import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import logoPath from "../../images/logo-white.svg";
import "../../styles/components/layout/_logo.scss";

const LogoWhite = ({className}) => <img className={cx(className, "logo", "logo--horizontal")} src={logoPath}/>;

LogoWhite.propTypes = {
  className: PropTypes.string,
};

export default LogoWhite;
