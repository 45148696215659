import Base from "./Base";

export default class Entity extends Base {
  constructor(api, entity) {
    super(api);

    this.generateProps(entity);
  }

  generateProps(entity) {
    Object.entries(entity).forEach(([key, val]) => {
      this[key] = val;
    });
  }
}

