import {SUBSCRIPTIONS_ACTIONS} from "../actions/subscriptions";

const defaultState = {
  detail: null,
  loading: true,
  error: null,
};

const subscriptionsReducer = (state = defaultState, action) => {
  switch(action.type) {
    case SUBSCRIPTIONS_ACTIONS.COMPLETE_CANCEL_SUBSCRIPTION:
      return {
        ...state,
        error: null,
        detail: null,
        loading: false,
      };
    case SUBSCRIPTIONS_ACTIONS.COMPLETE_FETCH_SUBSCRIPTION:
      return {
        ...state,
        error: null,
        loading: false,
        detail: action.payload,
      };
    case SUBSCRIPTIONS_ACTIONS.ERROR_CANCEL_SUBSCRIPTION:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detail: null,
      };
    case SUBSCRIPTIONS_ACTIONS.START_CANCEL_SUBSCRIPTION:
      return {
        ...state,
        error: null,
        loading: true,
        detail: null,
      };
    case SUBSCRIPTIONS_ACTIONS.START_CREATE_SUBSCRIPTION:
      return {
        ...state,
        error:null,
        loading: true,
        detail: null,
      };
    case SUBSCRIPTIONS_ACTIONS.COMPLETE_CREATE_SUBSCRIPTION:
      return {
        ...state,
        error: null,
        loading: false,
        detail: action.payload,
      };
    case SUBSCRIPTIONS_ACTIONS.ERROR_CREATE_SUBSCRIPTION:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detail: null,
      };
    default:
      return state;
  }
};

export default subscriptionsReducer;
