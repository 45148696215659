import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/buttons/_button.scss";

const SIZES = {
  XSMALL: "extra-small",
  SMALL: "small",
};

const Button = ({primary, secondary, danger, size, className, children, onClick, plain, disabled, stretch, type, title}) => {
  const iter = (child, i) => {
    if(child.type && child.type.name === "Icon") {
      return React.cloneElement(child, {
        className: cx("button__icon", {
          "button__icon--space-left": i > 0,
          "button__icon--white": primary || danger,
          "button__icon--plain": plain,
        }),
      });
    }

    return child;
  };

  const props = {
    className: cx(
      "button",
      className,
      {
        "button--primary": primary && !disabled,
        "button--secondary": secondary && !disabled,
        "button--plain": plain && !disabled,
        "button--danger": danger && !disabled,
        "button--xsmall": size === SIZES.XSMALL,
        "button--small": size === SIZES.SMALL,
        "button--disabled": disabled,
        "button--stretch": stretch,
      },
    ),
    title,
  };

  if(onClick) {
    props.onClick = onClick;
  }

  if(type) {
    props.type = type;
  }

  return (
    <button disabled={disabled} {...props}>
      {React.Children.map(children, iter)}
    </button>
  );
};

Button.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  danger: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  plain: PropTypes.bool,
  disabled: PropTypes.bool,
  stretch: PropTypes.bool,
  type: PropTypes.string,
  title: PropTypes.string,
};

export default Button;
