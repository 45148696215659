import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/table/_table.scss";

const Table = ({className, children}) => (
  <table className={cx(className, "table")}>
    {children}
  </table>
);

Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default Table;
