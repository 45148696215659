import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "../../../styles/components/forms/_field.scss";
import "../../../styles/base/_utils.scss";

const SIZES = {
  SMALL: "small",
};

class Field extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || "",
      isPristine: props.isPristine || true,
    };
  }

  render() {
    const {className, children, secondary, isPristine, pattern, value, onChange} = this.props;

    const _children = React.Children.map(children, child => {
      if(!child) {
        return null;
      }

      const name = child.type && child.type.name;

      let props = {};

      if(name && name === "Input") {
        props = {
          isPristine,
          secondary,
          pattern,
          value,
          onChange,
        };
      }

      if(name && name === "Message") {
        props = {
          isPristine,
        };
      }

      return React.cloneElement(child, props);
    });

    return (
      <div className={cx(className, "field")}>
        {_children}
      </div>
    );
  }
}

Field.propTypes = {
  value: PropTypes.string,
  isPristine: PropTypes.bool,
  isFocused: PropTypes.bool,
  className: PropTypes.string,
  secondary: PropTypes.bool,
  size: PropTypes.string,
  children: PropTypes.any,
  onChange: PropTypes.func,
  pattern: PropTypes.string,
};

export default Field;
export {
  SIZES,
};
