import Base from "../Base";
import Instance from "./Instance";

export default class Instances extends Base {
  async list(query) {
    return (
      await this._api.get(`/instances/?query=${query}`)
    ).map(instance => new Instance(this._api, instance));
  }

  async get(id) {
    return new Instance(this._api, await this._api.get(`/instances/${id}/`));
  }
}
