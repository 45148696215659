import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { gettext as _ } from "../../../utils";

import { Col, Container, Row } from "react-grid-system";
import { fetchInstances } from "../../../actions/instances";
import Loader from "../../../components/loaders/Loader";
import { Card } from "../index";


/**
 * Displays a list of instances.
 *
 * @container
 * @name List
 */
export default function List({ searchValue }) {
  const dispatch = useDispatch();
  const { loading, list } = useSelector((state) => state.instances);

  /**
   * Hook for fetching instances.
   * Is only fired on load.
   */
  useEffect(() => {
    dispatch(fetchInstances());
  }, []);

  if(loading) {
    return <Loader/>;
  }

  return (
    <Container fluid>
      <Row>
        { (list.length > 0)
          ? list
            .filter((item) => item.subdomain.startsWith(searchValue)) // Filter on searchValue, to only show instances matching.
            .map((item) => (
              <Col key={ item.id } xs={ 4 }>
                <Card instance={ item }/>
              </Col>
            ))
          : (
            <Col>
              <p>{ _("No instances found") }</p>
            </Col>
          ) }
      </Row>
    </Container>
  );
}

List.propTypes = {
  searchValue: PropTypes.string, // The search value inserted into the search bar. Value is provided from parent container.
};