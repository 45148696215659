import React from "react";
import FooterPage from "./layout/FooterPage";
import Content from "./layout/Content";
import {Col, Container, Row} from "react-grid-system";
import LoginForm from "./login-form/LoginForm";


class Login extends React.Component {
  render() {
    return (
      <FooterPage>
        <Content>
          <Container fluid>
            <Row>
              <Col className="u-display-flex u-jc-center">
                <LoginForm/>
              </Col>
            </Row>
          </Container>
        </Content>
      </FooterPage>
    );
  }
}

export default Login;
