import React from "react";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";

import "../../styles/components/layout/_navigation.scss";

const NavigationItem = ({to, children, exact}) => (
  <li>
    <NavLink className="navigation__item" activeClassName="navigation__item--active" exact={exact} to={to}>
      {children}
    </NavLink>
  </li>
);

NavigationItem.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any,
  exact: PropTypes.bool,
};

const Navigation = ({links}) => {
  return (
    <ul className="navigation">
      {links.map((link, i) => (
        <NavigationItem key={i} to={link.to} exact={link.exact}>
          {link.name}
        </NavigationItem>
      ))}
    </ul>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
  links: PropTypes.array,
};

export default Navigation;
