import Base from "../Base";
import Configuration from "./Configuration";

export default class Configurations extends Base {
  async get(id) {
    return new Configuration(this._api, await this._api.get(`/configurations/${ id }/`));
  }

  async add(instanceId, configuration) {
    return new Configuration(this._api, await this._api.post("/configurations/", {
      ...configuration,
      instance_id: instanceId,
    }));
  }
}
