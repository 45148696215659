import {SESSIONS_ACTIONS} from "../actions/sessions";

const defaultState = {
  detail: null,
  loading: true,
  error: null,
};

const sessionsReducer = (state = defaultState, action) => {
  switch(action.type) {
    case SESSIONS_ACTIONS.RESET_SESSION:
    case SESSIONS_ACTIONS.ERROR_CREATE_SESSION:
      return {
        ...state,
        loading: false,
        detail: null,
        error: action.payload,
      };
    case SESSIONS_ACTIONS.START_CREATE_CHECKOUT_SESSION:
      return {
        ...state,
        loading: true,
        error: null,
        detail: null,
      };
    case SESSIONS_ACTIONS.COMPLETE_CREATE_CHECKOUT_SESSION:
      return {
        ...state,
        loading: false,
        error: null,
        detail: action.payload,
      };
    default:
      return state;
  }
};

export default sessionsReducer;
