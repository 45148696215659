import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/layout/_page-header.scss";

const PageHeader = ({className, children}) => {
  return (
    <div className={cx(className, "page-header")}>
      {children}
    </div>
  );
};

PageHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default PageHeader;
