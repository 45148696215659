import React from "react";
import PropTypes from "prop-types";
import {Route, Switch} from "react-router-dom";
import Login from "./Login";
import ConfirmationSuccess from "./ConfirmationSuccess";
import NotFound from "./NotFound";
import Reset from "./Reset";

class Access extends React.Component {
  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route exact path={match.path} component={Login}/>
        <Route path={`${match.path}/confirmation/success`} component={ConfirmationSuccess}/>
        <Route path={`${match.path}/reset`} component={Reset}/>
        <Route path="*" component={NotFound}/>
      </Switch>
    );
  }
}

Access.propTypes = {
  match: PropTypes.object,
};

export default Access;