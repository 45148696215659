import React from "react";
import PropTypes from "prop-types";
import Footer from "./Footer";
import Page from "./Page";

const FooterPage = ({children, size}) => {
  return (
    <>
      <Page size={size}>
        {children}
      </Page>
      <Footer/>
    </>
  );
};

FooterPage.propTypes = {
  children: PropTypes.any,
  size: PropTypes.string,
};

export default FooterPage;
