import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./action-button-menu-item.scss";


export default function ActionButtonMenuItem({ className, children, onClick, isVisible }) {
  return (
    <li className={ cx(className, "action-button-menu-item", {
      "action-button-menu-item--animated": isVisible,
    }) }>
      <a className="action-button-menu-item__anchor" onClick={ onClick }>{ children }</a>
    </li>
  );
}

ActionButtonMenuItem.propTypes = {
  className: PropTypes.string, // Additional classes.
  children: PropTypes.node.isRequired, // The label for the menu item.
  onClick: PropTypes.func.isRequired, // The click handler for the menu item.
  isVisible: PropTypes.bool, // Indicates if the item menu item is visible (for animation purpose)
};