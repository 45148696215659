import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import TableHead from "../table/TableHead";
import TableHeadCell from "../table/TableHeadCell";
import TableBody from "../table/TableBody";
import Table from "../table/Table";
import TableRow from "../table/TableRow";

import {gettext as _} from "../../utils";

const AccountList = ({className, children}) => {
  return (
    <Table className={cx(className)}>
      <TableHead>
        <TableRow>
          <TableHeadCell width="50%">
            {_("Name")}
          </TableHeadCell>
          <TableHeadCell width="50%">
            {_("E-mail")}
          </TableHeadCell>
          <TableHeadCell>
            {_("Actions")}
          </TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {children}
      </TableBody>
    </Table>
  );
};

AccountList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default AccountList;
