import Client from "../api";
import { fetchAccountListCompleted } from "./accounts";
import { fetchOrganizationCompleted } from "./organization";
import { completeFetchSubscription } from "./subscriptions";

const USER_ACTIONS = {
  START_CREATE_USER: "USER::START_CREATE_USER",
  CREATE_USER_COMPLETED: "USER::CREATE_USER_SUCCESS",
  CREATE_USER_FAILED: "USER::CREATE_USER_FAILED",
  START_FETCH_CURRENT_USER: "USER::START_FETCH_CURRENT",
  FETCH_CURRENT_USER_FAILED: "USER::FETCH_CURRENT_FAILED",
  FETCH_CURRENT_USER_COMPLETED: "USER::FETCH_CURRENT_COMPLETED",
  START_LOGOUT: "USER::START_LOGOUT",
  COMPLETE_LOGOUT: "USER::COMPLETE_LOGOUT",
  ERROR_LOGOUT: "USER::ERROR_LOGOUT",
};

const logout = () => async dispatch => {
  dispatch(startLogout());

  try {
    await Client.logout();

    dispatch(completeLogout(null));
  } catch(e) {
    dispatch(errorLogout(e));
  }
};

const startLogout = () => ({
  type: USER_ACTIONS.START_LOGOUT,
});

const completeLogout = payload => ({
  type: USER_ACTIONS.COMPLETE_LOGOUT,
  payload,
});

const errorLogout = payload => ({
  type: USER_ACTIONS.ERROR_LOGOUT,
  payload,
});

const fetchCurrentAccount = () => {
  return async dispatch => {
    dispatch(startFetchCurrentAccount());

    try {
      const account = await Client.Users.me();
      const organization = await Client.Organizations.get(account.organization_id);

      dispatch(fetchOrganizationCompleted(organization));
      dispatch(fetchAccountListCompleted(organization.users));
      dispatch(completeFetchSubscription(organization.subscription));
      dispatch(fetchCurrentAccountCompleted(account));
    } catch(e) {
      dispatch(fetchCurrentAccountFailed(e));
    }
  };
};

const startFetchCurrentAccount = () => {
  return {
    type: USER_ACTIONS.START_FETCH_CURRENT_USER,
  };
};

const fetchCurrentAccountFailed = payload => {
  return {
    type: USER_ACTIONS.FETCH_CURRENT_USER_FAILED,
    payload,
  };
};

const fetchCurrentAccountCompleted = payload => {
  return {
    type: USER_ACTIONS.FETCH_CURRENT_USER_COMPLETED,
    payload,
  };
};

export {
  USER_ACTIONS,
  logout,
  fetchCurrentAccount,
};
