import Entity from "../Entity";

export default class Subscription extends Entity {
  unsubscribe() {
    return this._api.delete(`/subscriptions/${this.id}/`);
  }

  async partialUpdate(args) {
    return new Subscription(this._api, await this._api.patch(`/subscriptions/${this.id}/`, args));
  }

  async update(args) {
    return new Subscription(this._api, await this._api.put(`/subscriptions/${this.id}/`, args));
  }
}
