import React from "react";
import PropTypes from "prop-types";

import { Col, Container, Row } from "react-grid-system";
import SuccessImageUrl from "../../images/success.svg";
import TextBlock from "../text-blocks/TextBlock";
import Button from "../buttons/Button";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { gettext as _ } from "../../utils";

import "../../styles/base/_utils.scss";

class ConfirmationSuccessContent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className="u-full-width" fluid>
        <Row justify="center" className="u-mb-lg">
          <Col sm={ 7 }>
            <img alt={ "success" } src={ SuccessImageUrl }
              className="u-full-width u-mw-480px u-margin-align-center u-display-block"/>
          </Col>
        </Row>
        <Row justify="center">
          <Col sm={ 8 }>
            <TextBlock align="center">
              <h2>{ _("Congratulations!") }</h2>
              <p
                className="u-mb">{ _("You have successfully confirmed your email. You account is good to go!") }</p>
              <Button onClick={ () => this.props.gotoLogin() } primary>{ _("Go to login") }</Button>
            </TextBlock>
          </Col>
        </Row>
      </Container>
    );
  }
}

ConfirmationSuccessContent.propTypes = {
  gotoLogin: PropTypes.func,
};

export default connect(
  null,
  dispatch => ({
    gotoLogin: () => dispatch(push("/")),
  }),
)(ConfirmationSuccessContent);
