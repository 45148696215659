import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Navigation from "./Navigation";

import "../../styles/components/layout/_sidebar.scss";

class Sidebar extends React.Component {
  render() {
    const {className, links} = this.props;

    return (
      <div className={cx(className, "sidebar")}>
        <Navigation links={links}/>
      </div>
    );
  }
}

Sidebar.propTypes = {
  className: PropTypes.string,
  links: PropTypes.array,
};

export default Sidebar;
