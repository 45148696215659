import Entity from "../Entity";

export default class Configuration extends Entity {
  delete() {
    return this._api.delete(`/configurations/${this.id}/`);
  }

  async patch(args) {
    return new Configuration(this._api, await this._api.patch(`/configurations/${this.id}/`, args));
  }
}
