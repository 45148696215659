import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../../styles/components/forms/_message.scss";


const Message = ({ className, children, isPristine, isWarning }) => {
  return (
    <div className={ cx(className, "message", {
      "message--error": !isPristine,
      "message--warning": isWarning,
    }) }>
      { children }
    </div>
  );
};

Message.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  isPristine: PropTypes.bool,
  isWarning: PropTypes.bool,
};

export default Message;
