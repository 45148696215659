import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";

import accountReducer from "./accountReducer";
import instancesReducer from "./instancesReducer";
import instanceReducer from "./instanceReducer";
import configurationReducer from "./configurationReducer";
import currentAccountReducer from "./currentAccountReducer";
import organizationReducer from "./organizationReducer";
import accountsReducer from "./accountsReducer";
import subscriptionsReducer from "./subscriptionsReducer";
import sessionsReducer from "./sessionsReducer";
import resetReducer from "./resetReducer";
import { USER_ACTIONS } from "../actions/currentAccount";
import authorizationReducer from "./authorizationReducer";
import validationReducer from "./validationReducer";
import loginReducer from "./loginReducer";
import checkoutsReducer from "./checkoutReducer";
import taxReducer from "./taxReducer";

// Define reducers here
const reducers = (history) => ({
  router: connectRouter(history),
  instances: instancesReducer,
  instance: instanceReducer,
  configuration: configurationReducer,
  user: currentAccountReducer, // TODO: Rename to account, including usages.
  accounts: accountsReducer,
  account: accountReducer,
  organization: organizationReducer,
  subscriptions: subscriptionsReducer,
  sessions: sessionsReducer,
  reset: resetReducer,
  authorization: authorizationReducer,
  validation: validationReducer,
  login: loginReducer,
  checkouts: checkoutsReducer,
  tax: taxReducer,
});

const appReducer = (history) => combineReducers(
  reducers(history),
);

// See: https://gist.github.com/frankchang0125/f5d5f538ebd42c97c4eae2780555ad03
const rootReducer = history => (state, action) => {
  const _appReducer = appReducer(history);

  if(action.type === USER_ACTIONS.COMPLETE_LOGOUT) {
    state = undefined;
  }

  return _appReducer(state, action);
};

export default rootReducer;
