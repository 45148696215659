import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { fetchCurrentAccount } from "../actions/currentAccount";
import { fetchInstances } from "../actions/instances";
import { cancelSubscription } from "../actions/subscriptions";
import subRoutes from "../routes/subRoutes";

import "../styles/base/_utils.scss";

import { gettext as _ } from "../utils";
import Button from "./buttons/Button";
import Content from "./layout/Content";
import PageHeader from "./layout/PageHeader";
import Sidebar from "./layout/Sidebar";
import Loader from "./loaders/Loader";
import TextBlock from "./text-blocks/TextBlock";

export class SubscriptionOverview extends React.Component {
  constructor(props) {
    super(props);

    this.doSubscribe = this.doSubscribe.bind(this);
    this.doCancel = this.doCancel.bind(this);
  }

  render() {
    const { subscriptions, account, instances } = this.props;
    const isActive = subscriptions.detail && subscriptions.detail.is_active;

    if(subscriptions.loading || account.loading || instances.loading) {
      return <Loader page/>;
    }

    const handleClick = !isActive
      ? this.doSubscribe
      : this.doCancel;

    const isDisabled = !isActive && !(instances.list.length > 0) || !account.detail.organization.country;

    const buttonProps = {
      danger: isActive,
      primary: !isActive,
      size: "small",
      onClick: handleClick,
      disabled: isDisabled,
    };

    if(isDisabled) {
      buttonProps.title = _("Please add an instance before subscribing.");
    }

    return (
      <>
        <Sidebar links={ subRoutes.settings }/>
        <Content>
          <PageHeader>
            <TextBlock>
              <h2>{ _("Subscription") }</h2>
              { isActive
                ? <p>{ _("Hooray! You are currently subscribing to gert.io.") }</p>
                :
                <p>{ _("Oh no. It looks like you are not currently subscribing to gert.io. Start to comply today!") }</p> }
            </TextBlock>
            <Button { ...buttonProps }>
              { !isActive
                ? _("Subscribe")
                : _("Unsubscribe") }
            </Button>
          </PageHeader>
        </Content>
      </>
    );
  }

  async componentDidMount() {
    await this.props.fetchInstances();
  }

  async doSubscribe(e) {
    e.preventDefault();

    if(this.props.account.detail) {
      this.props.history.push("/settings/subscription/checkout");
    }
  }

  doCancel(e) {
    e.preventDefault();

    if(confirm(_("Are you sure that you want to cancel your subscription?"))) {
      this.props.cancelSubscription(this.props.subscriptions.detail);
    }
  }
}

SubscriptionOverview.propTypes = {
  account: PropTypes.object,
  organization: PropTypes.object,
  subscriptions: PropTypes.object,
  instances: PropTypes.object,
  fetchInstances: PropTypes.func,
  fetchCurrentAccount: PropTypes.func,
  createSubscription: PropTypes.func,
  cancelSubscription: PropTypes.func,
  history: PropTypes.object,
};

export default connect(
  state => ({
    account: state.user,
    subscriptions: state.subscriptions,
    instances: state.instances,
  }),
  dispatch => ({
    fetchCurrentAccount: () => dispatch(fetchCurrentAccount()),
    cancelSubscription: subscription => dispatch(cancelSubscription(subscription)),
    fetchInstances: () => dispatch(fetchInstances()),
  }),
)(SubscriptionOverview);
