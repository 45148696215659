import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../../styles/components/forms/_checkbox.scss";

const Checkbox = ({className, children, onChange, checked, isPristine}) => {
  return (
    <label className={cx(className, "checkbox")}>
      <div className="checkbox__field">
        <input onChange={onChange} className={cx("checkbox__input")} type="checkbox" checked={checked}/>
        <span className={cx("checkbox__indicator", {
          "checkbox__indicator--error": !isPristine,
        })}></span>
      </div>
      <div className="checkbox__meta">
        {children}
      </div>
    </label>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  isPristine: PropTypes.bool,
};

export default Checkbox;
