import React from "react";
import { gettext as _ } from "../../../utils";

import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { deleteInstance } from "../../../actions/instance";

import PageHeader from "../../../components/layout/PageHeader";
import TextBlock from "../../../components/text-blocks/TextBlock";
import BackButton from "../../../components/buttons/BackButton";
import Icon from "../../../components/icons/Icon";
import Button from "../../../components/buttons/Button";

import { ReactComponent as DeleteIcon } from "../../../icons/delete.svg";


/**
 * Displays the header on the configuration list
 *
 * @container
 * @name Header
 */
export default function Header() {
  const dispatch = useDispatch();
  const { detail } = useSelector(({ instance }) => instance);

  /**
   * Event handler for back button.
   * Goes back to the instance list if clicked.
   */
  const handleClickBackButton = (e) => {
    e.preventDefault();

    dispatch(push("/tool/instances/"));
  };

  /**
   * Event handler for delete instance button.
   * Will initialize deletion of instance.
   */
  const handleClickDeleteInstance = (e) => {
    e.preventDefault();

    if(confirm(_("You are about to delete instance \"{{subdomain}}\"").replace("{{subdomain}}", detail.subdomain))) {
      dispatch(deleteInstance(detail));
    }
  };

  return (
    <PageHeader>
      <TextBlock constrained>
        <BackButton large plain onClick={ handleClickBackButton }>
          { detail.subdomain }
        </BackButton>
      </TextBlock>
      <Button onClick={ handleClickDeleteInstance } danger size="small">
        { _("Delete instance") }
        <Icon>
          <DeleteIcon/>
        </Icon>
      </Button>
    </PageHeader>
  );
}