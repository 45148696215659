import React from "react";
import PropTypes from "prop-types";

import "./entity-card-meta.scss";


/**
 * Displays a top banner on the card.
 *
 * @component
 * @name EntityCardMeta
 */
export default function EntityCardMeta({ children }) {
  return (
    <div className="entity-card-meta">
      <div className="entity-card-meta__text">
        { children }
      </div>
    </div>
  );
}

EntityCardMeta.propTypes = {
  children: PropTypes.node.isRequired, // The text to display in the banner.
};