import React from "react";
import PropTypes from "prop-types";

import { EntityCardButton } from "../../index";

import "./entity-card-add.scss";


/**
 * Applies styling for the add card.
 *
 * @component
 * @name EntityCardAdd
 */
export default function EntityCardAdd({ children }) {
  return (
    <>
      <div className="entity-card-add">
        <div className="entity-card-add__ratio"></div>
        <div className="entity-card-add__content">
          <EntityCardButton/>
          <span className="entity-card-add__label">{ children }</span>
        </div>
      </div>
    </>
  );
}

EntityCardAdd.propTypes = {
  children: PropTypes.node.isRequired,
};