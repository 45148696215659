import { TAX_ACTIONS } from "../actions/tax";

const defaultState = {
  detail: null,
  loading: false,
  error: null,
};

const taxReducer = (state = defaultState, action) => {
  switch(action.type) {
    case TAX_ACTIONS.START_VALIDATE_VAT:
      return {
        ...state,
        detail: null,
        error: null,
        loading: true,
      };
    case TAX_ACTIONS.VALIDATE_VAT_COMPLETED:
      return {
        ...state,
        detail: action.payload,
        error: null,
        loading: false,
      };
    case TAX_ACTIONS.VALIDATE_VAT_FAILED:
      return {
        ...state,
        detail: null,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default taxReducer;