import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

const ConnectedPrivateComponent = (Component) => {
  return class ConnectedComponent extends React.Component {
    render() {
      return <Component {...this.props}/>;
    }
  };
};

const PrivateComponent = ({component: Component, account, ...args}) => {
  return account.detail && !account.error
    ? <Component {...args}/>
    : null;
};

PrivateComponent.propTypes = {
  component: PropTypes.any,
  account: PropTypes.object,
};

export default connect(
  state => ({
    account: state.user,
  }),
)(ConnectedPrivateComponent(PrivateComponent));
