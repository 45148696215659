import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/callout/_callout.scss";

const TYPES = {
  ERROR: "error",
  SUCCESS: "success",
};

const Callout = ({className, children, type}) => {
  let hasIcon = false;
  
  const _children = React.Children.map(children, (child) => {
    const _type = child.type;
    const name = _type && child.type.name;
    
    if(name && name === "Icon") {
      hasIcon = true;
      
      return React.cloneElement(child, {
        className: "callout__icon",
        type,
      });
    }
    
    if(typeof _type !== "string") {
      return React.cloneElement(child, {
        type: _type,
      });
    }
    
    return React.cloneElement(child);
  });

  return (
    <div className={cx(className, "callout", {
      "callout--error": type === TYPES.ERROR,
      "callout--success": type === TYPES.SUCCESS,
      "callout--has-icon": hasIcon,
    })}>
      {_children}
    </div>
  );
};

Callout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  type: PropTypes.string,
};

export default Callout;
export {
  TYPES,
};
