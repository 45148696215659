import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Content from "./layout/Content";
import PageHeader from "./layout/PageHeader";
import TextBlock from "./text-blocks/TextBlock";
import {Container} from "react-grid-system";
import subRoutes from "../routes/subRoutes";
import Sidebar from "./layout/Sidebar";
import {gettext as _} from "../utils";
import {Elements, ElementsConsumer} from "@stripe/react-stripe-js";
import CheckoutPaymentForm from "./checkout/CheckoutPaymentForm";
import "../styles/components/checkout/_checkout-stripe.scss";
import "../styles/components/checkout/_checkout-details.scss";
import { createCheckoutSession, resetSession } from "../actions/sessions";
import {loadStripe} from "@stripe/stripe-js/pure";
import Loader from "./loaders/Loader";

const stripePromise = loadStripe(gert.stripePublicKey);

export class SubscriptionCheckout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this.options,
      value: null,
    };

    this.doCancel = this.doCancel.bind(this);
  }

  render() {
    const { sessions } = this.props;

    if(sessions.loading) {
      return <Loader page/>;
    }

    return (
      <>
        <Sidebar links={subRoutes.settings}/>
        <Content>
          <PageHeader>
            <TextBlock>
              <h2>{_("Checkout")}</h2>
              <p>{_("Review your order below.")}</p>
            </TextBlock>
          </PageHeader>
          <Container fluid>
            <Elements stripe={stripePromise}>
              <ElementsConsumer>
                {({elements, stripe}) => {
                  return (
                    <CheckoutPaymentForm elements={elements} stripe={stripe}/>
                  );}}
              </ElementsConsumer>
            </Elements>
          </Container>
        </Content>
      </>
    );
  }

  async componentDidMount() {
    await this.props.createCheckoutSession(this.props.account.detail);
  }

  doCancel(e) {
    e.preventDefault();

    this.props.resetSession();
  }
}

SubscriptionCheckout.propTypes = {
  account: PropTypes.object,
  sessions: PropTypes.object,
  resetSession: PropTypes.func,
  path: PropTypes.string,
  createCheckoutSession: PropTypes.func,
};

export default connect(
  state => ({
    sessions: state.sessions,
    account: state.user,
  }),
  dispatch => ({
    resetSession: () => dispatch(resetSession()),
    createCheckoutSession: account => dispatch(createCheckoutSession(account)),
  }),
)(SubscriptionCheckout);
