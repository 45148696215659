import { push } from "connected-react-router";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-grid-system";
import { connect } from "react-redux";
import { fetchAccount } from "../actions/account";
import { deleteAccountFromDetail, updateAccount } from "../actions/accounts";
import { fetchCurrentAccount } from "../actions/currentAccount";
import { ReactComponent as DeleteIcon } from "../icons/delete.svg";
import subRoutes from "../routes/subRoutes";

import "../styles/base/_utils.scss";
import { gettext as _, hasChanged, isDefined, renderErrors } from "../utils";
import BackButton from "./buttons/BackButton";
import Button from "./buttons/Button";
import NotFoundContent from "./common/NotFoundContent";
import Truncate from "./common/Truncate";
import Field from "./forms/fields/Field";
import Input from "./forms/fields/Input";
import Label from "./forms/fields/Label";
import Icon from "./icons/Icon";

import Content from "./layout/Content";
import Detail from "./layout/Detail";
import PageHeader from "./layout/PageHeader";
import Sidebar from "./layout/Sidebar";
import Loader from "./loaders/Loader";

class EditAccount extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.back = this.back.bind(this);

    this.state = {
      first_name: {
        value: "",
      },
      last_name: {
        value: "",
      },
      email: {
        value: "",
      },
    };
  }

  render() {
    const { accounts, account } = this.props;

    if(accounts.loading) {
      return <Loader page/>;
    }

    if(account.loading) {
      return <Loader page/>;
    }

    if(!isDefined(account)) {
      return <NotFoundContent/>;
    }

    let error = account.error;
    error = error && error.response;

    const firstNameErrors = error && error.first_name && error.first_name;
    const lastNameErrors = error && error.last_name && error.last_name;
    const emailErrors = error && error.email && error.email;

    return (
      <>
        <Sidebar links={ subRoutes.settings }/>
        <Content>
          <PageHeader>
            <BackButton large plain onClick={ this.back }>
              { _("Go back") }
            </BackButton>
            <Button onClick={ this.handleClick } danger size="extra-small">
              <Icon>
                <DeleteIcon/>
              </Icon>
            </Button>
          </PageHeader>
          <form onSubmit={ this.handleSubmit }>
            <Detail hasGrid>
              <Container fluid>
                <Row>
                  <Col>
                    <Truncate>
                      <h2 className="u-mb-md">{ account.fullName }</h2>
                    </Truncate>
                    <Field isPristine={ !firstNameErrors } className="u-mb" secondary
                      value={ this.state.first_name.value }
                      onChange={ this.handleChange("first_name") }>
                      <Label>{ _("First name") }</Label>
                      <Input/>
                      { renderErrors(firstNameErrors) }
                    </Field>
                    <Field isPristine={ !lastNameErrors } className="u-mb" secondary
                      value={ this.state.last_name.value }
                      onChange={ this.handleChange("last_name") }>
                      <Label>{ _("Last name") }</Label>
                      <Input/>
                      { renderErrors(lastNameErrors) }
                    </Field>
                    <Field isPristine={ !emailErrors } className="u-mb" secondary value={ this.state.email.value }
                      onChange={ this.handleChange("email") }>
                      <Label>{ _("E-mail") }</Label>
                      <Input/>
                      { renderErrors(emailErrors) }
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col className="u-ta-r">
                    <Button type="submit" primary disabled={ !this.isPristine } size="small">{ _("Save user") }</Button>
                  </Col>
                </Row>
              </Container>
            </Detail>
          </form>
        </Content>
      </>
    );
  }

  async componentDidMount() {
    const id = parseInt(this.props.match.params.account);

    await this.props.fetchAccount(id);

    const account = this.props.account;

    this.setState(prevState => ({
      first_name: {
        ...prevState.first_name,
        value: account.detail.first_name || "",
      },
      last_name: {
        ...prevState.last_name,
        value: account.detail.last_name,
      },
      email: {
        ...prevState.email,
        value: account.detail.email || "",
      },
    }));
  }

  back(e) {
    e.preventDefault();

    this.props.push("/settings/organization/");
  }

  handleClick(e) {
    e.preventDefault();

    if(confirm(_("You are about to delete the account of {{name}} Are you sure?").replace("{{name}}", this.account.fullName))) {
      this.props.deleteAccountFromDetail(this.account);
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    if(this.isPristine) {
      const { first_name, last_name, email } = this.state;

      this.props.updateAccount({
        account: this.props.account.detail,
        args: {
          first_name: first_name.value,
          last_name: last_name.value,
          email: email.value,
        },
      });
    }
  }

  handleChange(key) {
    return e => {
      e.preventDefault();

      let value = e.target.value;

      this.setState(prevState => ({
        [key]: {
          ...prevState[key],
          value,
        },
      }));
    };
  }

  get isPristine() {
    const { first_name, last_name, email } = this.state;

    return (
      hasChanged(first_name.value, this.props.account.detail.first_name) ||
      hasChanged(last_name.value, this.props.account.detail.last_name) ||
      hasChanged(email.value, this.props.account.detail.email)
    );
  }
}

EditAccount.propTypes = {
  accounts: PropTypes.object,
  fetchCurrentUser: PropTypes.func,
  deleteAccountFromDetail: PropTypes.func,
  updateAccount: PropTypes.func,
  match: PropTypes.object,
  push: PropTypes.func,
  fetchAccount: PropTypes.func,
  account: PropTypes.object,
};

export default connect(
  state => ({
    accounts: state.accounts,
    organization: state.organization,
    account: state.account,
  }),
  dispatch => ({
    fetchCurrentUser: () => dispatch(fetchCurrentAccount()),
    updateAccount: args => dispatch(updateAccount(args)),
    deleteAccountFromDetail: account => dispatch(deleteAccountFromDetail(account)),
    push: to => dispatch(push(to)),
    fetchAccount: args => dispatch(fetchAccount(args)),
  }),
)(EditAccount);
