const subRoutes = {
  tool: [
    {
      to: "/tool/instances/",
      name: "Instances",
      exact: false,
    },
  ],
  settings: [
    {
      to: "/settings/organization/",
      name: "Organization",
      exact: false,
    },
    {
      to: "/settings/subscription/",
      name: "Subscription",
      exact: false,
    },
  ],
};

export default subRoutes;
