import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Options from "./Options";
import Option from "./Option";

import "../../styles/components/dropdown/_trigger.scss";


const Trigger = ({ className, children, onClick, options, isOpen, onSelect, selected, disabled, secondary }) => {
  return (
    <div className={ cx(className, "trigger") }>
      <button onClick={ onClick } className={ cx("trigger__button", {
        "trigger__button--disabled": disabled,
        "trigger__button--secondary": secondary,
      }) } disabled={ disabled }>
        { children }
      </button>
      { options && isOpen && (
        <Options onSelect={ onSelect } selected={ selected } isOpen={ isOpen }>
          { options.map((option, index) => (
            <Option key={ `${ index }-${ option.value }` } value={ option }>
              { option.label }
            </Option>
          )) }
        </Options>
      ) }
    </div>
  );
};

Trigger.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      label: PropTypes.string,
    }),
  ),
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  // If true, the dropdown becomes disabled
  disabled: PropTypes.bool,
  // If true, the dropdown will be rendered with a white background
  secondary: PropTypes.bool,
};

export default Trigger;
