import Base from "../Base";
import PaymentMethod from "./PaymentMethod";
import CheckoutSession from "./CheckoutSession";

export default class Checkouts extends Base{
  async session() {
    return new CheckoutSession(this._api, await this._api.request("/checkout/session/"));
  }
  async attachPaymentMethod (payment_method){
    return new PaymentMethod(this._api, await this._api.post("/checkout/", payment_method));
  }
}