import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import "./rule-text.scss";

/**
 * Text for Rule components
 *
 * @component
 * @name RuleText
 *
 * @example
 * <RuleText size="large" bold>Hello world!</RuleText>
 */
export default function RuleText({ className, children, size, bold }) {
  return (
    <div className={ cx(className, "rule-text", {
      "rule-text--small": size === "small",
      "rule-text--large": size === "large",
      "rule-text--bold": bold,
    }) }>
      { children }
    </div>
  );
}

RuleText.defaultProps = {
  size: "small",
};

RuleText.propTypes = {
  // Additional classes
  className: PropTypes.string,
  // The text to present in the component
  children: PropTypes.node.isRequired,
  // Sizes. Must be one of: `small` and `large`. Defaults to `small`
  size: PropTypes.oneOf(["small", "large"]),
  // If this flag is set to true, the text will be bold
  bold: PropTypes.bool,
};