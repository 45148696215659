import React from "react";
import Page from "../../components/layout/Page";

import { Header, Routes } from "./index";

/**
 * Displays the tool related pages.
 *
 * @container
 * @name Tool
 */
export default function Tool() {
  return (
    <Page>
      <Header/>
      <Routes/>
    </Page>
  );
}