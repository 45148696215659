import Client from "../api";

const RESET_ACTIONS = {
  START_REQUEST_RESET: "RESET::START_REQUEST_RESET",
  COMPLETE_REQUEST_RESET: "RESET::COMPLETE_REQUEST_RESET",
  ERROR_REQUEST_RESET: "RESET::ERROR_REQUEST_RESET",
  START_RESET: "RESET::START_RESET",
  COMPLETE_RESET: "RESET::COMPLETE_RESET",
  ERROR_RESET: "RESET::ERROR_RESET",
  SET_DEFAULT_REQUEST_RESET: "RESET::SET_DEFAULT_REQUEST_RESET",
};

const requestReset = email => async dispatch => {
  dispatch(startRequestReset());
  try {
    dispatch(completeRequestReset(await Client.Users.requestPasswordReset(email)));
  } catch(e) {
    dispatch(errorRequestReset(e));
  }
};

const setDefaultRequestReset = () => async dispatch => {
  dispatch(setDefaultReset());
};

const setDefaultReset = () => ({
  type: RESET_ACTIONS.SET_DEFAULT_REQUEST_RESET,
});

const startRequestReset = () => ({
  type: RESET_ACTIONS.START_REQUEST_RESET,
});

const completeRequestReset = payload => ({
  type: RESET_ACTIONS.COMPLETE_REQUEST_RESET,
  payload,
});

const errorRequestReset = payload => ({
  type: RESET_ACTIONS.ERROR_REQUEST_RESET,
  payload,
});

// TODO: Implement real action
const resetPassword = (uidb64, token, password) => async dispatch => {
  dispatch(startReset());

  try {
    dispatch(completeReset(await Client.Users.resetPassword(uidb64, token, password)));
  } catch(e) {
    dispatch(errorReset(e));
  }
};

const startReset = () => ({
  type: RESET_ACTIONS.START_RESET,
});

const completeReset = payload => ({
  type: RESET_ACTIONS.COMPLETE_RESET,
  payload,
});

const errorReset = payload => ({
  type: RESET_ACTIONS.ERROR_RESET,
  payload,
});

export {
  RESET_ACTIONS,
  requestReset,
  resetPassword,
  setDefaultRequestReset,
};
