import React from "react";
import PropTypes from "prop-types";

import "./rule.scss";

/**
 * A component used to present a rule in e.g. a configuration
 *
 * @component
 * @name Rule
 *
 * @example
 * <Rule closeButtonComponent={ removeButton }>
 *   <RuleText size="large" bold>{ label }</RuleText>
 *   <RuleText>
 *     id: { id } &#8901; type: { type }
 *   </RuleText>
 * </Rule>
 */
export default function Rule({ children, action }) {
  return (
    <div className="rule">
      <div>
        { children }
      </div>
      { action && action }
    </div>
  );
}

Rule.propTypes = {
  // Stuff that should be rendered inside the rule, e.g. RuleText.
  children: PropTypes.node,
  // Used to inject RuleAction. Injecting this component creates a link in the rule, which can be used for e.g. deleting it.
  action: PropTypes.node,
};