import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/buttons/_button.scss";

const IconButton = ({ className, children, onClick, disabled }) => (
  <button disabled={ disabled } onClick={ onClick } className={ cx(className, "button", "button--icon", {
    "button--disabled": disabled,
  }) }>
    { React.Children.map(children, child => (
      React.cloneElement(child, {
        className: cx("button__icon", {
          "button__icon--disabled": disabled,
        }),
      })
    )) }
  </button>
);

IconButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default IconButton;
