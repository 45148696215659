import { ACCOUNT_ACTIONS } from "../actions/account";

const defaultState = {
  detail: null,
  loading: true,
  error: null,
};

const accountReducer = (state = defaultState, action) => {
  switch(action.type) {
    case ACCOUNT_ACTIONS.START_FETCH_ACCOUNT:
      return {
        detail: null,
        loading: true,
        error: null,
      };
    case ACCOUNT_ACTIONS.ACCOUNT_FETCH_COMPLETED:
      return {
        detail: action.payload,
        loading: false,
        error: null,
      };
    case ACCOUNT_ACTIONS.ACCOUNT_FETCH_FAILED:
      return {
        detail: null,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default accountReducer;