import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const TableBody = ({className, children}) => (
  <tbody className={cx(className, "table__body")}>
    {children}
  </tbody>
);

TableBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default TableBody;

