import React from "react";
import PropTypes from "prop-types";

import {Redirect, Route, Switch} from "react-router-dom";
import PrivateRoute from "./common/PrivateRoute";
import NewAccount from "./NewAccount";
import EditAccount from "./EditAccount";
import NotFoundContent from "./common/NotFoundContent";

class Accounts extends React.Component {
  render() {
    const {match} = this.props;

    return (
      <Switch>
        <PrivateRoute exact path={match.path} routeRender={() => <Redirect to="/settings/organization"/>}/>
        <PrivateRoute path={`${match.path}/new`} component={NewAccount}/>
        <PrivateRoute path={`${match.path}/:account/edit`} component={EditAccount}/>
        <Route path="*" component={NotFoundContent}/>
      </Switch>
    );
  }
}

Accounts.propTypes = {
  match: PropTypes.object,
};

export default Accounts;
