import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/dropdown/_options.scss";

const Options = ({className, children, isOpen, onSelect, selected}) => {
  const _children = React.Children.map(children, child => {
    const type = child.type;
    const name = type && type.name;

    if(!type && !name) {
      return child;
    }

    if(name === "Option") {
      return React.cloneElement(child, {
        onSelect,
        selected,
      });
    }

    return child;
  });

  return (
    <div className={cx(className, "options", {
      "options--open": isOpen,
    })}>
      {_children}
    </div>
  );
};

Options.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
};

export default Options;
