import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/base/_utils.scss";

import IconButton from "../buttons/IconButton";
import Icon from "../icons/Icon";
import { ReactComponent as DeleteIcon } from "../../icons/delete.svg";
import Button from "../buttons/Button";
import { ReactComponent as WrenchIcon } from "../../icons/wrench.svg";
import { connect } from "react-redux";
import { deleteAccount } from "src/actions/accounts";
import TableRow from "../table/TableRow";
import TableCell from "../table/TableCell";
import { push } from "connected-react-router";

import { gettext as _ } from "../../utils";

class AccountItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
    this.edit = this.edit.bind(this);
  }

  render() {
    const { className, account } = this.props;

    return (
      <TableRow className={ cx(className) }>
        <TableCell width="50%" bold>
          { account.fullName }
        </TableCell>
        <TableCell width="50%" bold>
          { account.email }
        </TableCell>
        <TableCell>
          <IconButton onClick={ this.handleDelete } className="u-mr-sm u-va-middle"
            disabled={ account.is_organization_owner }>
            <Icon>
              <DeleteIcon/>
            </Icon>
          </IconButton>
          <Button size="extra-small" primary onClick={ this.edit } className="u-va-middle">
            { _("Edit") }
            <Icon>
              <WrenchIcon/>
            </Icon>
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  handleDelete(e) {
    e.preventDefault();

    if(this.props.account.is_organization_owner) {
      return false;
    }

    if(confirm(_("You are about to delete the account of {{name}}. Are you sure?").
      replace("{{name}}", this.props.account.fullName))) {
      this.props.deleteAccount(this.props.account);
    }
  }

  edit(e) {
    e.preventDefault();

    this.props.editAccount(this.props.account.id);
  }
}

AccountItem.propTypes = {
  className: PropTypes.string,
  account: PropTypes.object,
  deleteAccount: PropTypes.func,
  editAccount: PropTypes.func,
};

export default connect(
  null,
  dispatch => ({
    deleteAccount: account => dispatch(deleteAccount(account)),
    editAccount: id => dispatch(push(`/settings/organization/accounts/${ id }/edit`)),
  }),
)(AccountItem);
