import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../../styles/components/forms/_tag-field.scss";

class TagField extends React.Component {
  render() {
    const {className, children, secondary, isPristine, value, options, onRemove, onKeyDown, onChange, onBlur} = this.props;

    const _children = React.Children.map(children, child => {
      if(!child) {
        return null;
      }

      const name = child.type && child.type.name;

      let props = {};

      if(name && name === "TagInput") {
        props = {
          secondary,
          isPristine,
          value,
          options,
          onRemove,
          onKeyDown,
          onChange,
          onBlur,
        };
      }

      return React.cloneElement(child, props);
    });

    return (
      <div className={cx(className, "tag-field", {
        "tag-field--error": !isPristine,
      })}>
        {_children}
      </div>
    );
  }
}

TagField.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  secondary: PropTypes.bool,
  isPristine: PropTypes.bool,
  value: PropTypes.string,
  options: PropTypes.array,
  onRemove: PropTypes.func,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default TagField;
