import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { ReactComponent as SearchIcon } from "../../assets/search-icon.svg";
import "./search-field-icon.scss";


/**
 * Displays a search icon in the search field.
 *
 * @component
 * @name SearchFieldIcon
 */
export default function SearchFieldIcon({ className }) {
  return (
    <div className={ cx(className, "search-field-icon") }>
      <SearchIcon/>
    </div>
  );
}

SearchFieldIcon.propTypes = {
  className: PropTypes.string, // Additional classes to the component.
};