import React from "react";
import FooterPage from "./layout/FooterPage";
import Header from "./layout/Header";
import Content from "./layout/Content";
import {Col, Container, Row} from "react-grid-system";
import ResetPasswordForm from "./login-form/ResetPasswordForm";

class ResetPassword extends React.Component {
  render() {
    return (
      <FooterPage size="large">
        <Header/>
        <Content>
          <Container fluid>
            <Row>
              <Col className="u-display-flex u-jc-center">
                <ResetPasswordForm/>
              </Col>
            </Row>
          </Container>
        </Content>
      </FooterPage>
    );
  }
}

export default ResetPassword;
