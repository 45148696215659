import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/loaders/_loading.scss";

const Loader = ({className, page}) => {
  return (
    <div className={cx(className, "loader", {
      "loader--page": page,
    })}>
      <div className="loader__animation"></div>
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  page: PropTypes.bool,
};

export default Loader;
