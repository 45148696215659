import React from "react";
import Checkbox from "../forms/checkboxes/Checkbox";
import {Row} from "react-grid-system";
import {gettext as _} from "../../utils";
import PropTypes from "prop-types";


export default class CheckoutAgreement extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
    };

    this.handleCheck = this.handleCheck.bind(this);
  }

  render() {
    return(
      <Row className={"checkout-agreement__label"}>
        <Checkbox  isPristine onChange={this.handleCheck}>
          {_("I understand my subscription starts today. I authorise helphouse.io to send instructions " +
              "to the financial institution that issued my card to take " +
              "payments from my card account in accordance with the ")}
          <a href={"https://app.gert.io/terms-of-agreement/"}>{_("terms of my agreement")}</a> {_("and ")}
          <a href={"https://app.gert.io/privacy-policy/"}>{_("privacy policy")}</a>
          {_(", and terms and conditions for any applicable add-ons.")}
        </Checkbox>
      </Row>);
  }

  handleCheck(){
    if(!this.state.checked){
      this.setState({checked: true});
    } else {
      this.setState({checked: false});
    }

    this.props.termsOfAgreement(!this.state.checked);
  }
}

CheckoutAgreement.propTypes = {
  termsOfAgreement: PropTypes.func,
};
