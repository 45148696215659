const AUTHORIZATION_ACTIONS = {
  AUTHORIZED: "AUTHORIZATION::AUTHORIZED",
  ANONYMOUS: "AUTHORIZATION::ANONYMOUS",
};

const isAuthorized = () => ({
  type: AUTHORIZATION_ACTIONS.AUTHORIZED,
});

const isAnonymous = () => ({
  type: AUTHORIZATION_ACTIONS.ANONYMOUS,
});

export {
  AUTHORIZATION_ACTIONS,
  isAuthorized,
  isAnonymous,
};
