import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/tags/_tag.scss";

import {ReactComponent as Close} from "../../icons/close.svg";

const Tag = ({className, error, value, onClick}) => {
  const _handleClick = (e) => {
    e.preventDefault();

    onClick(value);
  };

  return (
    <div className={cx(className, "tag", {
      "tag--error": error,
    })}>
      {value}
      {onClick && <Close onClick={_handleClick} className="tag__close"/>}
    </div>
  );
};

Tag.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
  error:  PropTypes.bool,
};

export default Tag;
