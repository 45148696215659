import {INSTANCES_TYPES} from "../actions/instances";

const defaultState = {
  list: [],
  loading: false,
  error: null,
};

const instancesReducer = (state = defaultState, action) => {
  switch(action.type) {
    case INSTANCES_TYPES.FETCH:
      return {
        ...state,
        loading: true,
        list: [],
        error: null,
      };
    case INSTANCES_TYPES.FETCH_COMPLETED:
      return {
        ...state,
        list: action.payload,
        loading: false,
        error: null,
      };
    case INSTANCES_TYPES.FETCH_FAILED:
      return {
        ...state,
        error: action.payload,
        list: [],
        loading: false,
      };
    case INSTANCES_TYPES.REMOVE_FROM_LIST:
      return {
        ...state,
        error: null,
        loading: false,
        list: state.list.filter(item => item.id !== action.payload),
      };
    default:
      return state;
  }
};

export default instancesReducer;
