import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/status/_status-indicator.scss";

const TYPES = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
};

const StatusIndicator = ({className, type}) => <div className={cx(className, "status-indicator", {
  "status-indicator--success": type === TYPES.SUCCESS,
  "status-indicator--warning": type === TYPES.WARNING,
  "status-indicator--error": type === TYPES.ERROR,
})}/>;

StatusIndicator.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
};

export default StatusIndicator;
