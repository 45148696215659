import React from "react";
import PropTypes from "prop-types";

import {Route, Switch} from "react-router-dom";
import PrivateRoute from "./common/PrivateRoute";
import OrganizationOverview from "./OrganizationOverview";
import Accounts from "./Accounts";
import NotFoundContent from "./common/NotFoundContent";

class Organization extends React.Component {
  render() {
    const {match} = this.props;

    return (
      <Switch>
        <PrivateRoute exact path={match.path} component={OrganizationOverview}/>
        <PrivateRoute path={`${match.path}/accounts`} component={Accounts}/>
        <Route path="*" component={NotFoundContent}/>
      </Switch>
    );
  }
}

Organization.propTypes = {
  match: PropTypes.object,
};

export default Organization;
