import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./action-button-menu.scss";


/**
 * Contains the menu for the action button.
 *
 * @component
 * @name ActionButtonMenu
 */
export default function ActionButtonMenu({ className, children, onHover, isOpen }) {
  return (
    <ul className={ cx(className, "action-button-menu", {
      "action-button-menu--open": isOpen,
    }) } onMouseOver={ onHover } onMouseLeave={ onHover }>
      { children }
    </ul>
  );
}

ActionButtonMenu.propTypes = {
  className: PropTypes.string, // Additional classes.
  children: PropTypes.node.isRequired, // Sub components for the menu.
  onHover: PropTypes.func.isRequired, // Handler for mouse enter and mouse leave.
  isOpen: PropTypes.bool.isRequired, // Indicates if the menu is open.
};