import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const ALIGNMENTS = {
  RIGHT: "right",
};

const TableCell = ({className, children, align, width, bold}) => (
  <td width={width || "auto"} className={cx(className, "table__cell", {
    "table__cell--ar": align === ALIGNMENTS.RIGHT,
    "table__cell--bold": bold,
  })}>
    {children}
  </td>
);

TableCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  align: PropTypes.string,
  bold: PropTypes.bool,
  width: PropTypes.string,
};

export default TableCell;
