import Client from "../api";
import {push} from "connected-react-router";
import {removeFromList} from "./instances";

const INSTANCE_TYPES = {
  LOADING: "INSTANCE::LOADING",
  FAILED: "INSTANCE::FAILED",
  ADD_INSTANCE: "INSTANCE::FETCH_COMPLETED",
  COMPLETE_ADD_CONFIGURATION: "INSTANCE",
  START_DELETE_INSTANCE: "INSTANCE::START_DELETE",
  COMPLETE_DELETE_INSTANCE: "INSTANCE::COMPLETE_DELETE",
  ERROR_DELETE_INSTANCE: "INSTANCE::ERROR_DELETE",
};

const fetchInstance = payload => {
  return async dispatch => {
    dispatch(loading());

    try {
      dispatch(addInstance(await Client.Instances.get(payload)));
    } catch(e) {
      dispatch(failed(e));
    }
  };
};

const addConfiguration = payload => {
  return async dispatch => {
    dispatch(loading());

    try {
      await payload.instance.addConfiguration(payload.configuration);
      dispatch(push(`/tool/instances/${ payload.instance.id }`));
    } catch(e) {
      dispatch(failed(e));
    }
  };
};


const addInstance = payload => {
  return {
    type: INSTANCE_TYPES.ADD_INSTANCE,
    payload,
  };
};

const loading = () => {
  return {
    type: INSTANCE_TYPES.LOADING,
  };
};

const failed = payload => {
  return {
    type: INSTANCE_TYPES.FAILED,
    payload,
  };
};

const deleteInstance = payload => async dispatch => {
  dispatch(startDeleteInstance());

  try {
    await payload.delete();
    dispatch(completeDeleteInstance(null));
    dispatch(removeFromList(payload.id));
    dispatch(push("/tool/instances/"));
  } catch(e) {
    dispatch(errorDeleteInstance(e));
  }
};

const startDeleteInstance = () => ({
  type: INSTANCE_TYPES.START_DELETE_INSTANCE,
});

const completeDeleteInstance = payload => ({
  type: INSTANCE_TYPES.COMPLETE_DELETE_INSTANCE,
  payload,
});

const errorDeleteInstance = payload => ({
  type: INSTANCE_TYPES.ERROR_DELETE_INSTANCE,
  payload,
});

export {
  INSTANCE_TYPES,
  fetchInstance,
  addConfiguration,
  deleteInstance,
};
