
const TAX_ACTIONS = {
  START_VALIDATE_VAT: "VAT::START_VALIDATE_VAT",
  VALIDATE_VAT_COMPLETED: "VAT::VALIDATE_VAT_COMPLETED",
  VALIDATE_VAT_FAILED: "VAT::VALIDATE_VAT_FAILED",
};

const startValidateTax = () => {
  return {
    type: TAX_ACTIONS.START_VALIDATE_VAT,
  };
};

const completeValidateTax = payload => {
  return {
    type: TAX_ACTIONS.VALIDATE_VAT_COMPLETED,
    payload,
  };
};

const rejectValidateTax = payload => {
  return {
    type: TAX_ACTIONS.VALIDATE_VAT_FAILED,
    payload,
  };
};

const validateVAT = ({ organization, VAT, country }) => {
  return async dispatch => {
    dispatch(startValidateTax());

    try {
      const response = await organization.validateTaxId({ VAT, country });

      dispatch(completeValidateTax(response));

      return response;
    } catch(e) {
      dispatch(rejectValidateTax(e));

      return false;
    }
  };
};

export {
  TAX_ACTIONS,
  validateVAT,
};