import React from "react";
import PropTypes from "prop-types";

import {Switch, Route} from "react-router-dom";
import ResetPassword from "./ResetPassword";
import ResetPasswordConfirm from "./ResetPasswordConfirm";
import NotFound from "./NotFound";

class Reset extends React.Component {
  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route exact path={match.path} component={ResetPassword}/>
        <Route path={`${match.path}/:uidb64/:token`} component={ResetPasswordConfirm}/>
        <Route path="*" component={NotFound}/>
      </Switch>
    );
  }
}

Reset.propTypes = {
  match: PropTypes.object,
};

export default Reset;


