import Entity from "../Entity";
import Subscription from "../subscriptions/Subscription";
import User from "../users/User";

export default class Organization extends Entity {
  generateProps(entity) {
    Object.entries(entity).forEach(([key, val]) => {
      if(key === "subscription") {
        this[key] = val && new Subscription(this._api, val);
      } else if(key === "users") {
        this[key] = val.map(account => new User(this._api, account));
      } else {
        this[key] = val;
      }
    });
  }

  async update(args) {
    return new Organization(this._api, await this._api.put(`/organizations/${this.id}/`, args));
  }

  async validateTaxId(args){
    return await this._api.post("/organizations/validate-update/", args);
  }
}
