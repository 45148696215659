import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/buttons/_button-group.scss";
import {isDefined} from "../../utils";

const ButtonGroup = ({className, children, vertical, center, inline}) => {
  const iter = child => {
    const el = child.type.name;

    let _child;

    if(el === "Button" || el === "IconButton") {
      _child = React.cloneElement(child, {
        className: "button--in-group",
      });
    }

    if(el === "BackButton") {
      _child = React.cloneElement(child, {
        className: "back-button--in-group",
      });
    }

    if(el === "Anchor") {
      _child = React.cloneElement(child, {
        className: "anchor--in-group",
      });
    }

    return isDefined(_child) ? _child : child;
  };

  return (
    <div className={cx(className, "button-group", {
      "button-group--vertical": vertical,
      "button-group--center": center,
      "button-group--inline": inline,
    })}>
      {React.Children.map(children, iter)}
    </div>
  );
};

ButtonGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  vertical: PropTypes.bool,
  center: PropTypes.bool,
  inline: PropTypes.bool,
};

export default ButtonGroup;
