import React, { useEffect, useState } from "react";


/**
 * Higher order component for adding animation logic to action button menu.
 *
 * @param Component
 * @param menuItems
 * @returns {function(*): Component}
 */
export default function withActionButtonAnimation(Component, menuItems) {
  return function ActionButtonAnimation(props) {
    // State handling open state
    const [ isOpen, setIsOpen ] = useState(false);
    // State handling menu items state
    const [ items, setItems ] = useState(menuItems);

    // Declare variable for storing interval
    let animationInterval;

    // Listen for state change on isOpen
    useEffect(() => {
      if(isOpen) { // check if isOpen is true
        // Count all menu items
        const numOfElements = items.length;

        // The current index of the processed menu item.
        // Is set to the last menu item, as that one will be
        // rendered first (Last in, first out).
        let index = numOfElements - 1;

        // Initiate interval.
        animationInterval = setInterval(() => {
          // Modify menu items state
          setItems((prevAnimatedElements) => {
            // Copy current state to be able to mutate it.
            const newAnimatedElements = [ ...prevAnimatedElements ];

            // Get the current processed menu item.
            newAnimatedElements[index] = {
              ...newAnimatedElements[index],
              isVisible: true, // We set the visibility state to true, to indicate that this item should be rendered.
            };

            // Return the mutated menu item array to update the state.
            return newAnimatedElements;
          });

          // Decrement the index to get the next menu item in the next interval.
          index = index - 1;

          if(index < 0) { // Check if the current index is less than zero.
            // Clear the interval as the current index is less then zero,
            // and therefor at the beginning of the array.
            clearInterval(animationInterval);
          }
        }, 50);
      } else {
        // We update the menu items so all isVisible props are set to false,
        // as the button is not hovered anymore.
        setItems((prevAnimatedElements) => prevAnimatedElements.map((item) => ({
          ...item,
          isVisible: false,
        })));
      }

      return () => clearTimeout(hoverTimeout); // clear the timeout on unmount to prevent memory leaks.
    }, [ isOpen ]);

    // Declare variable to store timeout.
    // This timeout is used to give an buffer, for when the user is leaving the hover area,
    // to avoid that the user has to follow a strict path with the cursor, to stay in hovered mode.
    let hoverTimeout;

    // Event handler for hovering the Action button trigger or menu items.
    const handleHover = (e) => {
      e.preventDefault();

      const { type } = e;

      if(type === "mouseover") { // Check if the cursor is over one of the elements.
        // Clear timeout as we are currently hovering/continued hovering.
        clearTimeout(hoverTimeout);

        // Set isOpen to true, as the button is being hovered.
        // Will start rendering menu items (see useEffect)
        setIsOpen(true);
      }

      if(type === "mouseleave") { // Check if the cursor is leaving one of the elements.
        // We assign a timeout to the hoverTimeout variable.
        // The timeout is set to 500ms, to postpone state changes.
        // This is done to give a buffer, where the user can accidentally remove the cursor
        // from the area activating the event handler. If the they presume to hover the area,
        // the timeout will be cleared,
        hoverTimeout = setTimeout(() => {
          // We waited the 500 ms, and assume that the users intention was to close the action button menu,
          // and therefor we set the isOpen state to false.
          setIsOpen(false);

          // We clear the animation interval, to stop animating the menu items.
          clearInterval(animationInterval);
        }, 500);
      }
    };

    return <Component
      actionButtonItems={ items }
      handleActionButtonHover={ handleHover }
      isActionButtonOpen={ isOpen }
      { ...props } />;
  };
}