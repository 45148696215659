import Entity from "../Entity";

export default class PaymentMethod extends Entity{
  generateProps(entity) {
    Object.entries(entity).forEach(([key, val]) => {
      if(key === "id") {
        this[key] = val;
      }
    });
  }
}