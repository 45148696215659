import Client from "../api";

const ACCOUNT_ACTIONS = {
  START_FETCH_ACCOUNT: "ACCOUNT::START_FETCH",
  ACCOUNT_FETCH_COMPLETED: "ACCOUNT::FETCH_COMPLETED",
  ACCOUNT_FETCH_FAILED: "ACCOUNT::FETCH_FAILED",
};

const fetchAccount = id => {
  return async dispatch => {
    dispatch(startFetchAccount());

    try {
      const user = await Client.Users.get(id);

      dispatch(accountFetchCompleted(user));
    } catch (error) {
      dispatch(accountFetchFailed(error));
    }
  };
};

const startFetchAccount = () => {
  return {
    type: ACCOUNT_ACTIONS.START_FETCH_ACCOUNT,
  };
};

const accountFetchCompleted = payload => {
  return {
    type: ACCOUNT_ACTIONS.ACCOUNT_FETCH_COMPLETED,
    payload,
  };
};

const accountFetchFailed = error => {
  return {
    type: ACCOUNT_ACTIONS.ACCOUNT_FETCH_FAILED,
    error,
  };
};

export {
  ACCOUNT_ACTIONS,
  fetchAccount,
};