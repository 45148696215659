import React from "react";
import PropTypes from "prop-types";

import {Route, Switch} from "react-router-dom";
import PrivateRoute from "./common/PrivateRoute";
import SubscriptionOverview from "./SubscriptionOverview";
import NotFoundContent from "./common/NotFoundContent";
import SubscriptionSuccess from "./SubscriptionSuccess";
import SubscriptionCancel from "./SubscriptionCancel";
import SubscriptionCheckout from "./SubscriptionCheckout";

class Subscription extends React.Component {
  render() {
    const {match} = this.props;

    return (
      <Switch>
        <PrivateRoute exact path={match.path} component={SubscriptionOverview}/>
        <PrivateRoute path={`${match.path}/checkout`} component={SubscriptionCheckout}/>
        <PrivateRoute path={`${match.path}/success`} component={SubscriptionSuccess}/>
        <PrivateRoute path={`${match.path}/cancel`} component={SubscriptionCancel}/>
        <Route path="*" component={NotFoundContent}/>
      </Switch>
    );
  }
}

Subscription.propTypes = {
  match: PropTypes.object,
};

export default Subscription;
