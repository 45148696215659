import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import {connect} from "react-redux";
import {push} from "connected-react-router";

import "../../styles/base/_utils.scss";
import "../../styles/components/login-form/_reset-password-confirmation-form.scss";
import TextBlock from "../text-blocks/TextBlock";
import Label from "../forms/fields/Label";
import Input from "../forms/fields/Input";
import Field from "../forms/fields/Field";
import Button from "../buttons/Button";
import {resetPassword} from "../../actions/reset";

import {gettext as _, hasLength} from "../../utils";
import Hint from "../forms/fields/Hint";
import Message from "../forms/fields/Message";
import Loader from "../loaders/Loader";
import Icon from "../icons/Icon";
import {ReactComponent as SuccessIcon} from "../../icons/done.svg";
import Callout from "../callout/Callout";
import ButtonGroup from "../buttons/ButtonGroup";
import BackButton from "../buttons/BackButton";

class ResetPasswordConfirmationForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      password: "",
    };
  }

  render() {
    const { className, reset } = this.props;

    const error = reset.error && reset.error.password && reset.error.password[0];

    return !reset.loading
      ? (
        <form onSubmit={this.handleSubmit} className={ cx(className, "reset-password-confirmation-form", "u-form") }>
          <TextBlock className="u-mb-lg">
            <h2>{ _("Confirm reset password") }</h2>
            { !reset.detail && (
              <p>{ _("Enter your new password.") }</p>
            ) }
            { reset.detail && reset.detail.password && reset.detail.password === "password set" && (
              <Callout className="u-mt-lg" type="success">
                <Icon width="100%" height="100%">
                  <SuccessIcon/>
                </Icon>
                <p className="hug">{ _("Your password has been updated.") }</p>
              </Callout>
            ) }
          </TextBlock>
          { !reset.detail && (
            <Field isPristine={ !error } className="u-mb" secondary onChange={ this.handleChange("password") }
              value={ this.state.password }>
              <Label>{ _("Enter new password") }</Label>
              <Hint>{ _("Use a strong password to stay safe.") }</Hint>
              <Input type="password"/>
              { error && (
                <Message>{ error }</Message>
              ) }
            </Field>
          ) }
          { reset.detail && reset.detail.password && reset.detail.password === "password set" ? (
            <ButtonGroup center>
              <BackButton onClick={ this.handleCancel }>{ _("Go to login") }</BackButton>
            </ButtonGroup>
          ) : (
            <ButtonGroup center>
              <BackButton onClick={ this.handleCancel }>{_("Go to login")}</BackButton>
              <Button disabled={ !this.isPristine } type="submit" primary className="u-mb-xs">{ _("Reset password") }</Button>
            </ButtonGroup>
          ) }
        </form>
      )
      : <Loader/>;
  }

  handleChange(key) {
    return e => {
      e.preventDefault();

      this.setState({
        [key]: e.target.value,
      });
    };
  }

  handleCancel(e) {
    e.preventDefault();

    this.props.goToLogin();
  }

  handleSubmit(e) {
    e.preventDefault();

    this.props.resetPassword(
      this.props.uidb64,
      this.props.token,
      this.state.password,
    );
  }

  get isPristine() {
    return hasLength(this.state.password);
  }
}

ResetPasswordConfirmationForm.propTypes = {
  uidb64: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  className: PropTypes.string,
  reset: PropTypes.object,
  goToLogin: PropTypes.func,
  resetPassword: PropTypes.func,
};

export default connect(
  state => ({
    reset: state.reset,
  }),
  dispatch => ({
    goToLogin: () => dispatch(push("/")),
    resetPassword: (uidb64, token, password) => dispatch(resetPassword(uidb64, token, password)),
  }),
)(ResetPasswordConfirmationForm);
