import React from "react";
import PropTypes from "prop-types";

import "./entity-card-logo.scss";


/**
 * Renders a block for the logo, that will keep it's aspect ratio.
 *
 * @component
 * @name EntityCardLogo
 */
export default function EntityCardLogo({ src, alt }) {
  return (
    <div className="entity-card-logo">
      <div className="entity-card-logo__ratio"></div>
      <div className="entity-card-logo__content">
        <img className="entity-card-logo__image" src={ src } alt={ alt }/>
      </div>
    </div>
  );
}

EntityCardLogo.propTypes = {
  src: PropTypes.string.isRequired, // The src for the logo
  alt: PropTypes.string.isRequired, // The alt text for the logo
};
