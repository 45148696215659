import Entity from "../Entity";
import Configuration from "../configurations/Configuration";

export default class Instance extends Entity {
  constructor(...args) {
    super(...args);

    if(this.configurations.length > 0) {
      this.configurations = this.configurations.map(configuration => new Configuration(this._api, configuration));
    }
  }

  delete() {
    return this._api.delete(`/instances/${ this.id }/`);
  }

  async addConfiguration(configuration) {
    return this._api.Configurations.add(this.id, configuration);
  }
}
