import { push } from "connected-react-router";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-grid-system";
import { connect } from "react-redux";
import { createAccount, resetDefaultAccount } from "../actions/accounts";
import { fetchCurrentAccount } from "../actions/currentAccount";
import subRoutes from "../routes/subRoutes";

import "../styles/base/_utils.scss";
import { gettext as _, hasLength, renderErrors } from "../utils";
import BackButton from "./buttons/BackButton";
import Button from "./buttons/Button";
import Truncate from "./common/Truncate";
import Field from "./forms/fields/Field";
import Input from "./forms/fields/Input";
import Label from "./forms/fields/Label";

import Content from "./layout/Content";
import Detail from "./layout/Detail";
import PageHeader from "./layout/PageHeader";
import Sidebar from "./layout/Sidebar";

class NewAccount extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.back = this.back.bind(this);

    this.state = {
      first_name: {
        value: "",
      },
      last_name: {
        value: "",
      },
      email: {
        value: "",
      },
    };
  }

  render() {
    const { accounts } = this.props;

    let error = accounts.error;
    error = error && error.response;

    const firstNameErrors = error && error.first_name && error.first_name;
    const lastNameErrors = error && error.last_name && error.last_name;
    const emailErrors = error && error.email && error.email;

    return (
      <>
        <Sidebar links={ subRoutes.settings }/>
        <Content>
          <form onSubmit={ this.handleSubmit }>
            <PageHeader>
              <BackButton large plain onClick={ this.back }>
                { _("Go back") }
              </BackButton>
            </PageHeader>
            <Detail hasGrid>
              <Container fluid>
                <Row>
                  <Col>
                    <Truncate>
                      <h2 className="u-mb-md">{ _("Create user") }</h2>
                    </Truncate>
                    <Field isPristine={ !firstNameErrors } className="u-mb" secondary
                      value={ this.getValue("first_name") }
                      onChange={ this.handleChange("first_name") }>
                      <Label>{ _("First name") }</Label>
                      <Input/>
                      { renderErrors(firstNameErrors) }
                    </Field>
                    <Field isPristine={ !lastNameErrors } className="u-mb" secondary
                      value={ this.getValue("last_name") }
                      onChange={ this.handleChange("last_name") }>
                      <Label>{ _("Last name") }</Label>
                      <Input/>
                      { renderErrors(lastNameErrors) }
                    </Field>
                    <Field isPristine={ !emailErrors } className="u-mb" secondary value={ this.getValue("email") }
                      onChange={ this.handleChange("email") }>
                      <Label>{ _("E-mail") }</Label>
                      <Input/>
                      { renderErrors(emailErrors) }
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col className="u-ta-r">
                    <Button type="submit" disabled={ !this.isPristine } primary size="small">{ _("Add user") }</Button>
                  </Col>
                </Row>
              </Container>
            </Detail>
          </form>
        </Content>
      </>
    );
  }

  componentWillUnmount() {
    this.props.resetDefault();
  }

  back(e) {
    e.preventDefault();

    this.props.push("/settings/organization/");
  }

  handleChange(key) {
    return e => {
      e.preventDefault();

      let value = e.target.value;

      this.setState(prevState => ({
        [key]: {
          ...prevState[key],
          value,
        },
      }));
    };
  }

  handleSubmit(e) {
    e.preventDefault();

    if(this.isPristine) {
      this.props.createAccount({
        first_name: this.getValue("first_name"),
        last_name: this.getValue("last_name"),
        email: this.getValue("email"),
        organization_id: this.props.organization.detail.id,
      });
    }
  }

  getValue(key) {
    return this.state[key].value;
  }

  get isPristine() {
    return (
      hasLength(this.getValue("first_name")) &&
      hasLength(this.getValue("last_name")) &&
      hasLength(this.getValue("email"))
    );
  }
}

NewAccount.propTypes = {
  organization: PropTypes.object,
  accounts: PropTypes.object,
  account: PropTypes.object,
  createAccount: PropTypes.func,
  fetchCurrentAccount: PropTypes.func,
  push: PropTypes.func,
  resetDefault: PropTypes.func,
};

export default connect(
  state => ({
    organization: state.organization,
    accounts: state.accounts,
    account: state.user,
  }),
  dispatch => ({
    createAccount: args => dispatch(createAccount(args)),
    fetchCurrentAccount: () => dispatch(fetchCurrentAccount()),
    push: to => dispatch(push(to)),
    resetDefault: () => dispatch(resetDefaultAccount()),
  }),
)(NewAccount);
