import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/login-form/_login-form.scss";
import "../../styles/base/_utils.scss";

import TextBlock from "../text-blocks/TextBlock";
import Field from "../forms/fields/Field";
import Label from "../forms/fields/Label";
import Input from "../forms/fields/Input";
import Button from "../buttons/Button";
import Anchor from "../buttons/Anchor";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { hasLength } from "src/utils";
import Message from "../forms/fields/Message";
import { authorize } from "src/actions/login";

import { gettext as _ } from "../../utils";
import HorizontalLogo from "../layout/HorizontalLogo";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.redirect = this.redirect.bind(this);

    this.state = {
      email: {
        value: "",
      },
      password: {
        value: "",
      },
    };
  }

  render() {
    const { className, login } = this.props;

    const error = login.error;
    const errorResponse = error && error.response;
    const nonFieldError = errorResponse && errorResponse.non_field_errors && errorResponse.non_field_errors[0];
    const emailError = errorResponse && errorResponse.username && errorResponse.username[0];
    const passwordError = errorResponse && errorResponse.password && errorResponse.password[0];

    return (
      <form onSubmit={ this.handleSubmit } className={ cx(className, "login-form", "u-form") }>
        <TextBlock className="u-mb-lg">
          <HorizontalLogo className="u-4rem-height"/>
          { nonFieldError && (
            <p className="c-fg-danger">{ nonFieldError }</p>
          ) }
        </TextBlock>
        <Field isPristine={ !emailError } className="u-mb" secondary onChange={ this.handleChange("email") }
          value={ this.state.email.value }>
          <Label>{ _("E-mail") }</Label>
          <Input/>
          { emailError && (
            <Message>
              { emailError }
            </Message>
          ) }
        </Field>
        <Field isPristine={ !passwordError } className="u-mb" secondary onChange={ this.handleChange("password") }
          value={ this.state.password.value }>
          <Label>{ _("Password") }</Label>
          <Input type="password"/>
          { passwordError && (
            <Message>
              { passwordError }
            </Message>
          ) }
        </Field>
        <Button type="submit" className="u-mb" primary disabled={ !this.isPristine }>
          { _("Login") }
        </Button>
        <TextBlock>
          <p>
            { _("Still not a user?") } <Anchor onClick={ this.redirect("/signup/") }>{ _(
              "Create a new one and get started.") }</Anchor>
            <br/>
            { _("Did you forget your password?") } <Anchor onClick={ this.redirect("/access/reset/") }>{ _(
              "Click here to get a new one.") }</Anchor>
          </p>
        </TextBlock>
      </form>
    );
  }

  redirect(to) {
    return e => {
      e.preventDefault();

      this.props.push(to);
    };
  }

  handleChange(key) {
    return e => {
      e.preventDefault();

      const value = e.target.value;

      this.setState(prevState => ({
        [key]: {
          ...prevState[key],
          value,
        },
      }));
    };
  }

  get isPristine() {
    const { email, password } = this.state;

    return !!(hasLength(email.value) && hasLength(password.value));
  }

  handleSubmit(e) {
    e.preventDefault();

    if(this.isPristine) {
      this.props.authorize({
        email: this.state.email.value,
        password: this.state.password.value,
      });
    }
  }
}

LoginForm.propTypes = {
  className: PropTypes.string,
  login: PropTypes.object,
  authorize: PropTypes.func,
  push: PropTypes.func,
};

export default connect(
  state => ({
    login: state.login,
  }),
  dispatch => ({
    authorize: credentials => dispatch(authorize(credentials)),
    push: to => dispatch(push(to)),
  }),
)(LoginForm);
