import Client from "../api";
import { fetchCurrentAccount } from "./currentAccount";
import { push } from "connected-react-router";

const ACCOUNTS_ACTIONS = {
  FETCH_LIST_COMPLETED: "ACCOUNTS::FETCH_LIST_COMPLETED",
  START_DELETE_ACCOUNT: "ACCOUNTS::START_DELETE",
  DELETE_ACCOUNT_FAILED: "ACCOUNTS::DELETE_FAILED",
  DELETE_ACCOUNT_COMPLETED: "ACCOUNTS::DELETE_COMPLETED",
  START_UPDATE_ACCOUNT: "ACCOUNTS::START_UPDATE",
  UPDATE_ACCOUNT_FAILED: "ACCOUNTS::UPDATE_FAILED",
  UPDATE_ACCOUNT_COMPLETED: "ACCOUNTS::UPDATE_COMPLETED",
  START_CREATE_ACCOUNT: "ACCOUNTS::START_CREATE",
  CREATE_ACCOUNT_FAILED: "ACCOUNTS::CREATE_FAILED",
  CREATE_ACCOUNT_COMPLETED: "ACCOUNTS::CREATE_COMPLETED",
  RESET_DEFAULT_ACCOUNT: "ACCOUNT::RESET_DEFAULT",
};

const resetDefaultAccount = () => {
  return {
    type: ACCOUNTS_ACTIONS.RESET_DEFAULT_ACCOUNT,
  };
};

const fetchAccountListCompleted = payload => {
  return {
    type: ACCOUNTS_ACTIONS.FETCH_LIST_COMPLETED,
    payload,
  };
};

const deleteAccount = payload => {
  return async dispatch => {
    dispatch(startDeleteAccount());

    try {
      await payload.delete();
      dispatch(deleteAccountCompleted(payload.id));
      dispatch(fetchCurrentAccount());
    } catch(e) {
      dispatch(deleteAccountFailed(e));
    }
  };
};

const deleteAccountFromDetail = payload => {
  return async dispatch => {
    dispatch(startDeleteAccount());

    try {
      await payload.delete();
      dispatch(deleteAccountCompleted(payload.id));
      dispatch(push("/settings/organization/"));
    } catch(e) {
      dispatch(deleteAccountFailed(e));
    }
  };
};

const startDeleteAccount = () => {
  return {
    type: ACCOUNTS_ACTIONS.START_DELETE_ACCOUNT,
  };
};

const deleteAccountFailed = payload => {
  return {
    type: ACCOUNTS_ACTIONS.DELETE_ACCOUNT_FAILED,
    payload,
  };
};

const deleteAccountCompleted = payload => {
  return {
    type: ACCOUNTS_ACTIONS.DELETE_ACCOUNT_COMPLETED,
    payload,
  };
};

const updateAccount = payload => {
  return async dispatch => {
    dispatch(startUpdateAccount());

    try {
      dispatch(updateAccountCompleted(await payload.account.partialUpdate(payload.args)));
      dispatch(fetchCurrentAccount());
    } catch(e) {
      dispatch(updateAccountFailed(e));
    }
  };
};

const startUpdateAccount = () => {
  return {
    type: ACCOUNTS_ACTIONS.START_UPDATE_ACCOUNT,
  };
};

const updateAccountCompleted = payload => {
  return {
    type: ACCOUNTS_ACTIONS.UPDATE_ACCOUNT_COMPLETED,
    payload,
  };
};

const updateAccountFailed = payload => {
  return {
    type: ACCOUNTS_ACTIONS.UPDATE_ACCOUNT_FAILED,
    payload,
  };
};

const createAccount = payload => {
  return async dispatch => {
    dispatch(startCreateAccount());

    try {
      dispatch(createAccountCompleted(await Client.Users.create(payload)));
      dispatch(push("/settings/organization"));
    } catch(e) {
      dispatch(createAccountFailed(e));
    }
  };
};

const startCreateAccount = () => {
  return {
    type: ACCOUNTS_ACTIONS.START_CREATE_ACCOUNT,
  };
};

const createAccountCompleted = payload => {
  return {
    type: ACCOUNTS_ACTIONS.CREATE_ACCOUNT_COMPLETED,
    payload,
  };
};

const createAccountFailed = payload => {
  return {
    type: ACCOUNTS_ACTIONS.CREATE_ACCOUNT_FAILED,
    payload,
  };
};

export {
  ACCOUNTS_ACTIONS,
  fetchAccountListCompleted,
  deleteAccount,
  deleteAccountFromDetail,
  updateAccount,
  createAccount,
  resetDefaultAccount,
};
