import React from "react";
import PropTypes from "prop-types";

import { Col, Container, Row } from "react-grid-system";
import NotFoundImageUrl from "../../images/404.svg";
import TextBlock from "../text-blocks/TextBlock";
import Button from "../buttons/Button";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { gettext as _ } from "../../utils";

import "../../styles/base/_utils.scss";

class NotFoundContent extends React.Component {
  constructor(props) {
    super(props);

    this.home = this.home.bind(this);
  }

  render() {
    return (
      <Container className="u-full-width" fluid>
        <Row justify="center" className="u-mb-lg">
          <Col sm={ 7 }>
            <img alt={ "notFound" } src={ NotFoundImageUrl } className="u-full-width"/>
          </Col>
        </Row>
        <Row justify="center">
          <Col sm={ 8 }>
            <TextBlock align="center">
              <h2>{ _("We can't locate the page you're looking for") }</h2>
              <p
                className="u-mb">{ _(
                  "We're sorry, but we can't find the page you're looking for. You may have mistyped the address or maybe we've moved the page. However, now we know and we'll fix the problem as fast as we can.") }</p>
              <Button onClick={ this.home } primary>{ _("Go to homepage") }</Button>
            </TextBlock>
          </Col>
        </Row>
      </Container>
    );
  }

  home(e) {
    e.preventDefault();

    this.props.goToHomePage();
  }
}

NotFoundContent.propTypes = {
  goToHomePage: PropTypes.func,
};

export default connect(
  null,
  dispatch => ({
    goToHomePage: () => dispatch(push("/tool/instances/")),
  }),
)(NotFoundContent);
