import React from "react";
import PropTypes from "prop-types";

import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import Instance from "../../../api/instances/Instance";

import { EntityCard, EntityCardLogo, /** EntityCardMeta, */ EntityCardTitle } from "../../../components/entity-card";


/**
 * Displays a card representing an instance.
 *
 * @container
 * @name Card
 */
export default function Card({
  instance: {
    id,
    subdomain,
  },
}) {
  const dispatch = useDispatch();

  /**
   * Event handler for the card.
   * Goes to the configuration list of the instance if clicked.
   */
  const handleClick = (e) => {
    e.preventDefault();

    dispatch(push(`/tool/instances/${ id }`));
  };

  return (
    <EntityCard onClick={ handleClick }>
      {/** <EntityCardMeta>_("Sandbox")</EntityCardMeta> todo: Show that it is a sandbox */}
      <EntityCardLogo
        src={ "https://d3v-helphouse.zendesk.com/system/brands/3600/0006/5112/helphouse_logo_white_on_green_RGB_no_border_1024.png" /** todo: add dynamic src */ }
        alt={ subdomain }/>
      <EntityCardTitle>{ subdomain }</EntityCardTitle>
    </EntityCard>
  );
}

Card.propTypes = {
  instance: PropTypes.instanceOf(Instance), // A GERT instance.
};