import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { NavLink } from "react-router-dom";

import "../../styles/components/layout/_main-navigation.scss";
import { connect } from "react-redux";
import { logout } from "src/actions/currentAccount";

const MainNavigationItem = ({ to, children, exact }) => (
  <li className="main-navigation__item">
    <NavLink className="main-navigation__anchor" activeClassName="main-navigation__anchor--active" exact={ exact }
      to={ to }>
      { children }
    </NavLink>
  </li>
);

MainNavigationItem.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any,
  exact: PropTypes.bool,
};

class MainNavigation extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  render() {
    const { className } = this.props;

    return (
      <ul className={ cx(className, "main-navigation") }>
        <MainNavigationItem to="/tool/instances">gert.io</MainNavigationItem>
        <MainNavigationItem to="/settings/">Settings</MainNavigationItem>
        <li className="main-navigation__item">
          <a className="main-navigation__anchor" onClick={ this.handleClick }>Logout</a>
        </li>
      </ul>
    );
  }

  handleClick(e) {
    e.preventDefault();

    if(confirm("Are you sure that you want to log out?")) {
      this.props.logout();
    }
  }
}

MainNavigation.propTypes = {
  className: PropTypes.string,
  logout: PropTypes.func,
};

export default connect(
  null,
  dispatch => ({
    logout: () => dispatch(logout()),
  }),
)(MainNavigation);
