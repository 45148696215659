import React from "react";
import PropTypes from "prop-types";
import "Styles/components/dropdown/_options.scss";

const SuggestionsListComponent = ({ filteredSuggestions, onClick }) => {
  return filteredSuggestions.length ? (
    <ul className="options options--open">
      { filteredSuggestions.map((suggestion) => {
        return (
          <li className={ "option" } key={ suggestion.id } onClick={ () => onClick(suggestion) }>
            { suggestion.label }
          </li>
        );
      }) }
    </ul>
  ) : (
    <div className="no-suggestions">
      <em>No results</em>
    </div>
  );
};

SuggestionsListComponent.propTypes = {
  filteredSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ),
  onClick: PropTypes.func,
};

export default SuggestionsListComponent;