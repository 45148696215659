import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/text-blocks/_text-block.scss";

const ALIGNMENTS = {
  CENTER: "center",
  RIGHT: "right",
};

const TextBlock = ({className, children, align, constrained}) => {
  return (
    <div className={cx(className, "text-block", {
      "text-block--centered": align === ALIGNMENTS.CENTER,
      "text-block--right": align === ALIGNMENTS.RIGHT,
      "text-block--constrained": constrained,
    })}>
      {children}
    </div>
  );
};

TextBlock.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  align: PropTypes.string,
  constrained: PropTypes.bool,
};

export default TextBlock;
