import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { gettext as _ } from "../../../utils";

import {
  ActionButton,
  ActionButtonMenu,
  ActionButtonMenuItem,
  ActionButtonTrigger,
} from "../../../components/action-button";
import { withActionButtonAnimation } from "../../../enhancers/with-action-button-animation";
import { useDispatch } from "react-redux";
import { fetchInstances } from "../../../actions/instances";


/**
 * Displays the add button in context of the instance list.
 *
 * @container
 * @name AddButton
 */
function AddButton({ actionButtonItems, handleActionButtonHover, isActionButtonOpen }) {
  const dispatch = useDispatch();
  /**
   * Event handler for message event.
   *
   * @param {object} e - the event send to the receiver.
   */
  const messageReceiver = e => {
    // We check that the origin of the event is the same as the location origin.
    // We do also check that an instance_id is sent to us.
    if(e.origin === location.origin && e.data.instance_id) {
      // If the above is truthy, we want to update the list of instances.
      dispatch(fetchInstances());
    }

    // Finally, we close the source window to complete the authorization.
    e.source.close();
  };

  // We define a useEffect hook which only runs on mount.
  // It will add messageReceiver as a handler for the message event.
  useEffect(() => {
    window.addEventListener("message", messageReceiver, false);

    // We do a cleanup when the component un-mounts, where we remove the event handler to prevent memory leaks.
    return () => window.removeEventListener("message", messageReceiver);
  }, []);

  /**
   * Function for initializing the instance addition.
   */
  const doInstanceAdd = () => {
    let width = 800;
    let height = 600;

    let options = {
      width: width,
      height: height,
      top: window.screen.height / 2 - height / 2,
      left: window.screen.width / 2 - width / 2,
    };

    let window_options = Object.entries(options).map(([ k, v ]) => `${ k }=${ v }`).join(",");

    window.open(
      "/zendesk/auth/",
      "zendesk auth",
      window_options,
    );
  };

  /**
   * Click handler for the button.
   *
   * @param {string} key - the identifier that decides what is going to happen when the button is clicked.
   * @returns {function(...[*]=)}
   */
  const handleClick = (key) => (e) => {
    e.preventDefault();

    switch(key) {
      case "add-instance":
        doInstanceAdd();
        break;
      default:
        return false;
    }
  };

  const sharedProps = {
    isOpen: isActionButtonOpen,
    onHover: handleActionButtonHover,
  };

  return (
    <ActionButton pinned>
      <ActionButtonTrigger { ...sharedProps } />
      <ActionButtonMenu { ...sharedProps }>
        { actionButtonItems.map(({ label, key, isVisible }) => (
          <ActionButtonMenuItem key={ key } onClick={ handleClick(key) } isVisible={ isVisible }>
            { label }
          </ActionButtonMenuItem>
        )) }
      </ActionButtonMenu>
    </ActionButton>
  );
}


AddButton.propTypes = {
  actionButtonItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
      isVisible: PropTypes.bool,
    }),
  ),
  handleActionButtonHover: PropTypes.func,
  isActionButtonOpen: PropTypes.bool,
};


export default withActionButtonAnimation(AddButton, [
  {
    label: _("Add instance"),
    key: "add-instance",
    isVisible: false,
  },
]);