import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/layout/_header.scss";
import HorizontalLogo from "./HorizontalLogo";
import {connect} from "react-redux";
import {push} from "connected-react-router";

const SIZES = {
  LARGE: "large",
};

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  render() {
    const {className, children, size} = this.props;
    const isLarge = size === SIZES.LARGE;

    return (
      <header className={cx(className, "header", {
        "header--large": isLarge,
        "header--no-bg": isLarge,
      })}>
        <a className="header__logo" onClick={this.handleClick}>
          <HorizontalLogo/>
        </a>
        {children}
      </header>
    );
  }

  handleClick(e) {
    e.preventDefault();

    this.props.goToInstances();
  }
}

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  size: PropTypes.string,
  goToInstances: PropTypes.func,
};

export default connect(
  null,
  dispatch => ({
    goToInstances: () => dispatch(push("/tool/instances/")),
  }),
)(Header);
