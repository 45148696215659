import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import "../../styles/components/checkout/_checkout-list.scss";
import HorizontalSeparator from "../separators/HorizontalSeparator";
import { gettext as _ } from "../../utils";

const eu_vat = new RegExp("AT|BE|BG|CY|CZ|DE|EE|EL|ES|FI|FR|GB|HR|HU|IE|IT|LT|LU|LV|MT|NL|PL|PT|SEV|SI|SK");
const dk_vat = new RegExp("DK+[0-9]{8}");

function calculatePrice(amount, quantity) {
  return ((amount * quantity) / 100);
}

function calculateVAT(amount, quantity, tax) {
  return calculatePrice(amount, quantity) * (tax / 100);
}

function calculateTotal(amount, quantity, organization) {
  return calculatePrice(amount, quantity) + calculateVAT(amount, quantity, organization);
}

function currencyMap(currency) {
  const currency_symbols = {
    "USD": "$", // US Dollar
    "EUR": "€", // Euro
    "GBP": "£", // British Pound Sterling
    "DKK": "dkk",
  };

  return currency_symbols[currency];
}

const CheckoutList = ({ className, children, organization, taxRate }) => {
  return (
    <>
      <div className={ cx(className, "checkout-list") }>
        <div className={ "checkout-list___title" }>{ _("Bill summary") }</div>

        <ul className={ "checkout-list__segment" }>
          <li>
            <div className={ "checkout-list__label" }>
              { children }
            </div>
            <div className={ "checkout-list__numeric" }>
              { "{currency} {price}".replace("{price}", calculatePrice(children[0].props.item.plan.amount, children[0].props.item.quantity))
                .replace("{currency}", currencyMap(children[0].props.item.plan.currency.toUpperCase())) }
            </div>
          </li>
          { dk_vat.test(organization.VAT)
            ? (
              <li>
                <div className={ "checkout-list__label" }>
                  { _("Tax ({VAT}% VAT)".replace("{VAT}", taxRate.percentage)) }
                </div>
                <div className={ "checkout-list__numeric" }>
                  { "{currency} {vat}".replace("{vat}", calculateVAT(children[0].props.item.plan.amount, children[0].props.item.quantity, taxRate.percentage))
                    .replace("{currency}", currencyMap(children[0].props.item.plan.currency.toUpperCase())) }
                </div>
              </li>
            )
            : eu_vat.test(organization.VAT) && (
              <li>
                <div className={ "checkout-list__label" }>
                  { _("Tax to be paid on reverse charge basis") }
                </div>
              </li>
            )
          }
          <li>
            <div className="checkout-list__label--small">
              { _("* We are billing you based on the number of agents registered on your Zendesk instances.") }
            </div>
          </li>
        </ul>
        <HorizontalSeparator/>
        <div className="checkout-list__total">
          <div className={ "checkout-list__label" }>
            { "Total :" }
          </div>
          <div className={ "checkout-list__total-currency" }>
            { "{currency}".replace("{currency}",
              currencyMap(children[0].props.item.plan.currency.toUpperCase())) }
          </div>
          <div className={ "checkout-list__total-amount" }>
            { dk_vat.test(organization.VAT)
              ? "{amount}".replace("{amount}", calculateTotal(children[0].props.item.plan.amount, children[0].props.item.quantity, taxRate.percentage))
              : "{amount}".replace("{amount}", calculatePrice(children[0].props.item.plan.amount, children[0].props.item.quantity))
            }
          </div>
        </div>
      </div>
    </>
  );
};

CheckoutList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  organization: PropTypes.object,
  VAT_percentage: PropTypes.number,
  taxRate: PropTypes.object,
};

export default CheckoutList;
