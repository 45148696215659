import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { arrayContains, gettext as _, validateTag } from "src/utils";
import Modal from "Components/modal";
import Button from "Components/buttons/Button";
import TextBlock from "Components/text-blocks/TextBlock";
import Label from "Components/forms/fields/Label";
import Input from "Components/forms/fields/Input";
import TagInput from "Components/forms/fields/TagInput";
import Hint from "Components/forms/fields/Hint";
import { ACTION_LIST, CONDITION_LIST, STATUS_LIST, OPERATOR_LIST, RELATIVE_TIME_LIST, TYPE_LIST } from "./queryOptions";

import "Styles/components/query-builder/_modal.scss";

const queryStringBuilder = (queryObject) => {
  let queryString = "";

  if(queryObject) {
    for(const field in queryObject) {
      switch(field) {
        case "type":
          if(queryObject[field] === "user") {
            queryString = queryString.concat(`${ field }:${ queryObject[field] } role:end-user `);
          } else {
            queryString = queryString.concat(`${ field }:${ queryObject[field] } `);
          }
          break;
        case "tags":
          for(const tag in queryObject["tags"]) {
            queryString = queryString.concat(`tags:${ queryObject["tags"][tag] } `);
          }
          break;
        case "condition":
        case "action":
        case "time":
        case "time-format":
          queryString = queryString.concat(`${ queryObject[field] }`);
          break;
        case "operator":
        case "operator-equals":
          break;
        case "status":
          if(queryObject["operator"]) {
            queryString = queryString.concat(`${ field }${ queryObject["operator"] }${ queryObject["operator-equals"]
              ? "="
              : "" }${ queryObject[field] } `);
          } else {
            queryString = queryString.concat(`${ field }:${ queryObject[field] } `);
          }
          break;
        default:
          queryString = queryString.concat(`${ field }:${ queryObject[field] } `);
      }
    }
  }

  return queryString;
};

const QueryBuilderModal = ({ show, onClose }) => {
  const [query, setQuery] = useState();
  const [queryText, setQueryText] = useState("");
  const [tag, setTag] = useState("");

  const closeModal = (e) => onClose(e);
  const closeAndSave = (e) => onClose(e, queryText);

  const optionalFieldsEnabled = (additionalCheck, additionalCheckCondition) => {
    if(query) {
      const ticketIsSelected = query.type === "ticket";
      const userIsSelected = query.type === "user";

      if(additionalCheckCondition) {
        if(query[additionalCheckCondition]) {
          return (ticketIsSelected || userIsSelected) && query[additionalCheck];
        } else { return (ticketIsSelected || userIsSelected);}
      }

      if(additionalCheck) {
        return (ticketIsSelected || userIsSelected) && query[additionalCheck];
      }

      return ticketIsSelected || userIsSelected;
    }

    return false;
  };

  const footer = (
    <div className="query-builder-modal__footer">
      <Button onClick={ closeModal } secondary>{ _("Cancel") }</Button>
      <Button onClick={ closeAndSave } primary disabled={ !optionalFieldsEnabled() }>{ _("Save") }</Button>
    </div>
  );

  const handleSelect = (fieldType, value) => e => {
    e.preventDefault();

    //if customer changes the type, we need to reset the query
    if(fieldType === "type") {
      setQuery({
        [fieldType]: value,
      });
    }
    if(query) {
      setQuery((prevState) => ({
        ...prevState,
        [fieldType]: value,
      }));
    } else {
      setQuery({
        [fieldType]: value,
      });
    }
  };

  useEffect(() => {
    setQueryText(queryStringBuilder(query));
  }, [query]);

  const isChosen = (fieldType, value) => {
    if(query) {
      return Boolean(query[fieldType] === value);
    }

    return false;
  };

  const handleAddTag = (e) => {
    const isTagEntered = e.target.value.length > 0;

    if(isTagEntered && (e.keyCode === 13 || e.keyCode === 32 || e.type === "blur")) {
      e.preventDefault();

      const value = e.target.value;

      setQuery((prevState) => {
        const options = prevState.tags;
        const isValid = validateTag(value);
        const isPresent = options && arrayContains(value, options);
        const tags = options ? [...options, value] : [value];

        if(!isValid || isPresent) {
          return prevState;
        }

        return {
          ...prevState,
          tags,
        };
      });
      setTag("");
    }
  };

  const handleRemoveTag = (tag) => {
    setQuery((prevState) => ({
      ...prevState,
      tags: prevState.tags.filter(_tag => _tag !== tag),
    }));
  };

  const handleChangeTag = (e) => {
    e.preventDefault();

    const value = e.target.value;
    setTag(value);
  };

  const handleChangeTime = (e) => {
    e.preventDefault();

    const value = e.target.value;

    setQuery((prevState) => ({
      ...prevState,
      "time": value,
    }));
  };

  return (
    <Modal show={ show } title={ _("Build query") } footer={ footer } footerAlign="end">
      <div className="modal-body-wrapper">
        { /*TODO: add proper explanation */ }
        <TextBlock>
          { _("Explanation") }
        </TextBlock>

        { /* TYPE */ }
        <div className="field-wrapper">
          <Label>{ _("Choose type: ") }</Label>
          { TYPE_LIST.map(
            type => <Button key={ type } size="extra-small" onClick={ handleSelect("type", type.toLowerCase()) }
              primary={ isChosen("type", type.toLowerCase()) }>
              { _(type) }
            </Button>) }

        </div>

        { /* ACTION */ }
        <div className="field-wrapper">
          <Label>{ _("Choose action: ") }</Label>
          { ACTION_LIST.map(
            action => <Button key={ `${ action } action` } size="extra-small"
              onClick={ handleSelect("action", action.toLowerCase()) }
              primary={ isChosen("action", action.toLowerCase()) }
              disabled={ !optionalFieldsEnabled() }>
              { _(action) }
            </Button>) }
        </div>

        { /* CONDITION */ }
        { query && query.action && (<div className="field-wrapper">
          <Label>{ _("Choose action condition: ") }</Label>
          { CONDITION_LIST.map(
            condition => <Button key={ condition.name } size="extra-small"
              onClick={ handleSelect("condition", condition.value) }
              primary={ isChosen("condition", condition.value) }
              disabled={ !optionalFieldsEnabled("action") }>
              { _(condition.name) }
            </Button>) }

        </div>) }

        { /* DATE&TIME */ }
        { query && query.action && (<div className="field-wrapper">
          <Label>{ _("Choose time: ") }</Label>
          <input disabled={ !optionalFieldsEnabled("condition") } onChange={ handleChangeTime }
            className="time-input" type="number" min="0"/>
          { RELATIVE_TIME_LIST.map(
            time => <Button key={ time } size="extra-small"
              onClick={ handleSelect("time-format", `${ time.toLowerCase() } `) }
              primary={ isChosen("time-format", `${ time.toLowerCase() } `) }
              disabled={ !optionalFieldsEnabled("time") }>
              { _(time) }
            </Button>) }
        </div>) }

        { /* STATUS */ }
        <div className="field-wrapper">
          <Label>{ _("Ticket status: ") }</Label>
          { STATUS_LIST.map(
            status => <Button key={ status } size="extra-small" onClick={ handleSelect("status", status.toLowerCase()) }
              primary={ isChosen("status", status.toLowerCase()) }
              disabled={ !optionalFieldsEnabled("time-format", "action") }>
              { _(status) }
            </Button>,
          ) }

        </div>

        { /* OPERATOR */ }
        { query && query.status && (<div className="field-wrapper">
          <Label>{ _("Choose status condition: ") }</Label>
          { OPERATOR_LIST.map(operator => <Button key={ operator.name } size="extra-small"
            onClick={ handleSelect("operator", operator.value) }
            primary={ isChosen("operator", operator.value) }
            disabled={ !optionalFieldsEnabled("status") }>
            { _(operator.name) }
          </Button>) }

          <Button size="extra-small" onClick={ handleSelect("operator-equals", !(query && query["operator-equals"])) }
            primary={ isChosen("operator-equals", true) } disabled={ !optionalFieldsEnabled("operator") }>
            { _("Equals") }
          </Button>
        </div>) }

        { /* TAGS */ }
        <Label>{ _("Insert tags here") }</Label>
        <Hint>{ _("Find tickets that include either of entered tags.") }</Hint>
        <TagInput secondary={ !optionalFieldsEnabled() } onChange={ handleChangeTag } onRemove={ handleRemoveTag }
          onKeyDown={ handleAddTag } onBlur={ handleAddTag }
          disabled={ !optionalFieldsEnabled("time-format", "action") }
          options={ query && query.tags } isPristine={ true } value={ tag }/>

        <Label>{ _("Query:") }</Label>
        <Input isPristine={ true } className="u-mb" secondary value={ queryText } disabled={ true }/>
      </div>
    </Modal>
  );
};

QueryBuilderModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default QueryBuilderModal;