import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { push } from "connected-react-router";

import Content from "./layout/Content";
import { Col, Container, Row } from "react-grid-system";
import TextBlock from "./text-blocks/TextBlock";
import Button from "./buttons/Button";

import SuccessImageUrl from "../images/success.svg";

import "../styles/base/_utils.scss";

class SubscriptionSuccess extends React.Component {
  constructor(props) {
    super(props);

    this.dashboard = this.dashboard.bind(this);
  }

  render() {
    return (
      <Content withGrid>
        <Container className="u-full-height" fluid>
          <Row className="u-full-height" align="center">
            <Col sm={ 6 }>
              <img alt={ "success" } src={ SuccessImageUrl } className="u-of-contain"/>
            </Col>
            <Col sm={ 6 }>
              <TextBlock>
                <h1>Thank you for subscribing</h1>
                <Button onClick={ this.dashboard } primary>Back to dashboard</Button>
              </TextBlock>
            </Col>
          </Row>
        </Container>
      </Content>
    );
  }

  dashboard(e) {
    e.preventDefault();

    this.props.goToDashboard();
  }
}

SubscriptionSuccess.propTypes = {
  goToDashboard: PropTypes.func,
};

export default connect(
  null,
  dispatch => ({
    goToDashboard: () => dispatch(push("/tool/instances/")),
  }),
)(SubscriptionSuccess);
