import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

import "../../../styles/components/forms/_input.scss";

const Input = ({className, secondary, hidden, isPristine, value, onChange, pattern, type, disabled, onBlur}) => {
  const _props = {
    className: cx(className, "input", {
      "input--secondary": secondary && !hidden,
      "input--error": !isPristine,
      "input--hidden": hidden,
      "input--button__inline":"input--button__inline",
    }), value, onChange, pattern, type, disabled, onBlur,
  };

  return <input {..._props}/>;
};

Input.propTypes = {
  value: PropTypes.string,
  pattern: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  isPristine: PropTypes.bool,
  secondary: PropTypes.bool,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Input;
