import {USER_ACTIONS} from "../actions/currentAccount";

const defaultState = {
  detail: null,
  loading: true,
  error: null,
};

const currentAccountReducer = (state = defaultState, action) => {
  switch(action.type) {
    case USER_ACTIONS.START_FETCH_CURRENT_USER:
    case USER_ACTIONS.START_CREATE_USER:
    case USER_ACTIONS.START_LOGIN:
    case USER_ACTIONS.START_LOGOUT:
      return {
        ...state,
        loading: true,
        error: null,
        detail: null,
      };
    case USER_ACTIONS.FETCH_CURRENT_USER_FAILED:
    case USER_ACTIONS.CREATE_USER_FAILED:
    case USER_ACTIONS.LOGIN_FAILED:
    case USER_ACTIONS.ERROR_LOGOUT:
      return {
        ...state,
        loading: false,
        error: action.payload,
        detail: null,
      };
    case USER_ACTIONS.FETCH_CURRENT_USER_COMPLETED:
    case USER_ACTIONS.CREATE_USER_COMPLETED:
    case USER_ACTIONS.LOGIN_COMPLETED:
      return {
        ...state,
        loading: false,
        detail: action.payload,
        error: null,
      };
    case USER_ACTIONS.COMPLETE_LOGOUT:
      return {
        ...state,
        loading: false,
        detail: null,
        error: null,
      };
    default:
      return state;
  }
};

export default currentAccountReducer;
