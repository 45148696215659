import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/dropdown/_dropdown.scss";

class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggle = this.handleToggle.bind(this);
    this.setRef = this.setRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      isOpen: false,
      cursor: 0,
      selected: props.selected
        ? props.selected
        : props.options && props.options.length > 0
          ? props.options[0].value
          : "",
    };
  }

  render() {
    const { className, children, disabled, secondary } = this.props;

    const _children = React.Children.map(children, child => {
      const type = child && child.type;
      const name = type && type.name;

      if(!type && !name) {
        return child;
      }

      if(name === "Trigger") {
        return React.cloneElement(child, {
          onClick: this.handleToggle,
          isOpen: this.state.isOpen,
          selected: this.state.selected,
          onSelect: this.handleSelect,
          options: this.props.options,
          disabled,
          secondary,
        });
      }

      return child;
    });

    return (
      <div ref={ this.setRef } className={ cx(className, "dropdown", {
        "dropdown--disabled": disabled,
      }) }>
        { _children }
      </div>
    );
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleToggle(e) {
    e.preventDefault();

    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  handleSelect(selected) {
    return e => {
      e.preventDefault();

      if(this.props.onSelect) {
        this.props.onSelect(selected);
      } else {
        this.setState({
          selected,
        });
      }

      this.setState({
        isOpen: false,
      });
    };
  }

  handleClickOutside(e) {
    if(this.ref && !this.ref.contains(e.target)) {
      this.setState({
        isOpen: false,
      });
    }
  }

  setRef(node) {
    this.ref = node;
  }
}

Dropdown.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ),
  // If true, the dropdown becomes disabled
  disabled: PropTypes.bool,
  // If true, the dropdown will be rendered with a white background
  secondary: PropTypes.bool,
};

export default Dropdown;
