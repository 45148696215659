import Client from "../api";

const INSTANCES_TYPES = {
  FETCH: "INSTANCES::FETCH",
  FETCH_FAILED: "INSTANCES::FAILED",
  FETCH_COMPLETED: "INSTANCES::FETCH_COMPLETED",
  REMOVE_FROM_LIST: "INSTANCES::REMOVE_FROM_LIST",
};

const fetchInstances = () => {
  return async dispatch => {
    dispatch(startFetchInstances());

    try {
      dispatch(setInstances(await Client.Instances.list("{id,subdomain,configurations,agent_number,metadata}")));
    } catch(e) {
      dispatch(failedFetchInstances(e));
    }
  };
};

const startFetchInstances = () => {
  return {
    type: INSTANCES_TYPES.FETCH,
  };
};

const failedFetchInstances = payload => {
  return {
    type: INSTANCES_TYPES.FETCH_FAILED,
    payload,
  };
};

const setInstances = payload => {
  return {
    type: INSTANCES_TYPES.FETCH_COMPLETED,
    payload,
  };
};

const removeFromList = payload => ({
  type: INSTANCES_TYPES.REMOVE_FROM_LIST,
  payload,
});

export {
  INSTANCES_TYPES,
  fetchInstances,
  removeFromList,
};
