import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-grid-system";
import TextBlock from "../text-blocks/TextBlock";

import "../../styles/base/_utils.scss";
import Field from "../forms/fields/Field";
import Label from "../forms/fields/Label";
import Input from "../forms/fields/Input";
import Button from "../buttons/Button";
import ButtonGroup from "../buttons/ButtonGroup";
import Checkbox from "../forms/checkboxes/Checkbox";
import Anchor from "../buttons/Anchor";
import { hasLength, renderErrors } from "../../utils";
import { connect } from "react-redux";
import Hint from "../forms/fields/Hint";

import { gettext as _ } from "../../utils";

class Start extends React.Component {
  render() {
    const { onChange, fields, validation } = this.props;

    let error = validation.error;

    error = error && error.response;

    const firstNameError = error && error.first_name && error.first_name[0];
    const lastNameError = error && error.last_name && error.last_name[0];
    const emailErrors = error && error.email && error.email;
    const organizationError = error && error.organization;
    const organizationNameErrors = organizationError && organizationError.name && organizationError.name;
    const passwordErrors = error && error.password && error.password;

    return (
      <>
        <Row className="u-mb-xl" justify="center">
          <Col>
            <TextBlock align={ "center" }>
              <h2>{ _("Start your journey towards compliancy today") }</h2>
            </TextBlock>
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <Field isPristine={ !firstNameError } onChange={ onChange("first_name") }
              value={ fields.first_name.value } className="u-mb">
              <Label>
                { _("First name") }
                <span className="c-fg-danger">*</span>
              </Label>
              <Input/>
              { renderErrors(firstNameError) }
            </Field>
            <Field isPristine={ !lastNameError } onChange={ onChange("last_name") } value={ fields.last_name.value }
              className="u-mb">
              <Label>
                { _("Last name") }
                <span className="c-fg-danger">*</span>
              </Label>
              <Input/>
              { renderErrors(lastNameError) }
            </Field>
            <Field isPristine={ !emailErrors } onChange={ onChange("email") } value={ fields.email.value }
              className="u-mb">
              <Label>
                { _("Email") }
                <span className="c-fg-danger">*</span>
              </Label>
              <Input type="email"/>
              { renderErrors(emailErrors) }
            </Field>
            <Field isPristine={ !organizationNameErrors } onChange={ onChange("organization_name") }
              value={ fields.organization_name.value } className="u-mb">
              <Label>
                { _("Company name") }
                <span className="c-fg-danger">*</span>
              </Label>
              <Input/>
              { renderErrors(organizationNameErrors) }
            </Field>
            <Field isPristine={ !passwordErrors } onChange={ onChange("password") } value={ fields.password.value }
              className="u-mb">
              <Label>
                { _("Password") }
                <span className="c-fg-danger">*</span>
              </Label>
              <Input type="password"/>
              { renderErrors(passwordErrors) }
            </Field>
            <Checkbox className="u-mb" onChange={ onChange("conditions") } checked={ fields.conditions.value }
              isPristine>
              <Label>
                { _("Agree to our policies") }
                <span className="c-fg-danger">*</span>
              </Label>
              <Hint>
                { _("Agree to ") }
                <Anchor target="_blank" href="https://helphouse.io/en/legal/gertio-master-subscription-agreement/">
                  { _("terms & conditions") }
                </Anchor>
                { _(" and ") }
                <Anchor target="_blank" href="https://helphouse.io/en/legal/gertio-privacy-policy/">
                  { _("privacy policy") }
                </Anchor>
              </Hint>
            </Checkbox>
            <ButtonGroup center>
              <Button type="submit" disabled={ !this.isPristine } primary>{ _("Signup") }</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </>
    );
  }

  get isPristine() {
    const { fields } = this.props;

    return Boolean(hasLength(fields.email.value) && hasLength(fields.password.value) && fields.conditions.value
      && hasLength(fields.first_name.value) && hasLength(fields.last_name.value));
  }

}

Start.propTypes = {
  onChange: PropTypes.func,
  fields: PropTypes.object,
  user: PropTypes.object,
  validation: PropTypes.object,
};

export default connect(
  state => ({
    validation: state.validation,
  }),
)(Start);
