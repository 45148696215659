import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../../styles/components/forms/_label.scss";

const Label = ({className, children}) => {
  return (
    <label className={cx(className, "label")}>{children}</label>
  );
};

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default Label;
