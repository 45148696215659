import {VALIDATION_ACTION} from "../actions/validation";

const defaultState = {
  detail: null,
  created: {
    detail: null,
    error: null,
    loading: false,
  },
  error: null,
  loading: false,
};

const validationReducer = (state = defaultState, action) => {
  switch(action.type) {
    case VALIDATION_ACTION.START_VALIDATION:
      return {
        ...state,
        loading: true,
        detail: null,
        error: null,
        created: {
          ...state.created,
          detail: null,
          error: null,
          loading: false,
        },
      };
    case VALIDATION_ACTION.COMPLETE_VALIDATION:
      return {
        ...state,
        detail: action.payload,
        error: null,
        loading: false,
        created: {
          ...state.created,
          detail: null,
          error: null,
          loading: false,
        },
      };
    case VALIDATION_ACTION.ERROR_VALIDATION:
      return {
        ...state,
        error: {
          ...action.payload,
          response: action.payload.response.errors,
        },
        detail: null,
        loading: false,
        created: {
          ...state.created,
          detail: null,
          error: null,
          loading: false,
        },
      };
    case VALIDATION_ACTION.RESET_VALIDATION:
      return {
        ...state,
        error: null,
        detail: null,
        loading: false,
        created: {
          ...state.created,
          detail: null,
          error: null,
          loading: false,
        },
      };
    case VALIDATION_ACTION.START_CREATE_USER:
      return {
        ...state,
        error: null,
        detail: null,
        loading: false,
        created: {
          ...state.created,
          detail: null,
          error: null,
          loading: true,
        },
      };
    case VALIDATION_ACTION.COMPLETE_CREATE_USER:
      return {
        ...state,
        error: null,
        detail: null,
        loading: false,
        created: {
          ...state.created,
          detail: action.payload,
          error: null,
          loading: true,
        },
      };
    case VALIDATION_ACTION.ERROR_CREATE_USER:
      return {
        ...state,
        error: null,
        detail: null,
        loading: false,
        created: {
          ...state.created,
          detail: null,
          error: action.payload,
          loading: true,
        },
      };
    default:
      return state;
  }
};

export default validationReducer;
export {defaultState as validationDefaultState};
