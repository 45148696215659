import {LOGIN_ACTIONS} from "../actions/login";

const defaultState = {
  detail: null,
  error: null,
  loading: false,
};

const loginReducer = (state = defaultState, action) => {
  switch(action.type) {
    case LOGIN_ACTIONS.START_LOGIN:
      return {
        ...state,
        detail: null,
        error: null,
        loading: true,
      };
    case LOGIN_ACTIONS.COMPLETE_LOGIN:
      return {
        ...state,
        detail: action.payload,
        loading: false,
        error: null,
      };
    case LOGIN_ACTIONS.ERROR_LOGIN:
      return {
        ...state,
        error: action.payload,
        detail: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default loginReducer;
