import Client from "../api";
import {push} from "connected-react-router";
import {fetchInstances} from "./instances";

const CONFIGURATION_TYPES = {
  LOADING: "CONFIGURATION::LOADING",
  FAILED: "CONFIGURATION::FAILED",
  SUCCESS: "CONFIGURATION::SUCCESS",
  DELETE_FROM_LIST: "CONFIGURATION_DELETE_FROM_LIST",
};

const loading = () => {
  return {
    type: CONFIGURATION_TYPES.LOADING,
  };
};

const failed = payload => {
  return {
    type: CONFIGURATION_TYPES.FAILED,
    payload,
  };
};

const success = payload => {
  return {
    type: CONFIGURATION_TYPES.SUCCESS,
    payload,
  };
};

const fetchConfiguration = (payload, callback) => {
  return async dispatch => {
    dispatch(loading());

    let configuration = null;

    try {
      configuration = await Client.Configurations.get(payload);

      dispatch(success(configuration));
    } catch(e) {
      dispatch(failed(e));
    } finally {
      callback(configuration);
    }
  };
};

const deleteConfiguration = payload => {
  return async dispatch => {
    dispatch(loading());

    try {
      await payload.delete();
      dispatch(push("/tool/instances/"));
    } catch(e) {
      dispatch(failed(e));
    }
  };
};

const deleteConfigurationFromList = payload => {
  return async dispatch => {
    dispatch(loading());

    try {
      await payload.delete();
      dispatch(await fetchInstances());
    } catch(e) {
      dispatch(failed(e));
    }
  };
};

const updateConfiguration = payload => {
  return async dispatch => {
    dispatch(loading());

    try {
      dispatch(success(await payload.configuration.patch(payload.args)));
    } catch(e) {
      dispatch(failed(e));
    }
  };
};

export {
  CONFIGURATION_TYPES,
  fetchConfiguration,
  deleteConfiguration,
  updateConfiguration,
  deleteConfigurationFromList,
};
