import React from "react";
import PropTypes from "prop-types";
import Content from "./layout/Content";
import {Col, Container, Row} from "react-grid-system";
import Header from "./layout/Header";
import FooterPage from "./layout/FooterPage";
import ResetPasswordConfirmationForm from "./login-form/ResetPasswordConfirmationForm";

class ResetPasswordConfirm extends React.Component {
  render() {
    return (
      <FooterPage size="large">
        <Header/>
        <Content>
          <Container fluid>
            <Row>
              <Col className="u-display-flex u-jc-center">
                <ResetPasswordConfirmationForm
                  uidb64={this.props.match.params.uidb64}
                  token={this.props.match.params.token}/>
              </Col>
            </Row>
          </Container>
        </Content>
      </FooterPage>
    );
  }
}

ResetPasswordConfirm.propTypes = {
  match: PropTypes.object,
};

export default ResetPasswordConfirm;
