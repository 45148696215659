import { push } from "connected-react-router";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { fetchCurrentAccount } from "../actions/currentAccount";
import { Tool } from "../containers/tool";
import { gettext as _ } from "../utils";
import Access from "./Access";
import PrivateRoute from "./common/PrivateRoute";
import Loader from "./loaders/Loader";
import NotFound from "./NotFound";
import Settings from "./Settings";
import Signup from "./Signup";

class Entry extends React.Component {
  render() {
    return this.props.account.loading || this.props.login.loading
      ? <Loader page/>
      : (
        <Switch>
          <Route exact path="/" render={ () => <Redirect to="/access"/> }/>
          <Route path="/access" component={ Access }/>
          <Route path="/signup" component={ Signup }/>
          <PrivateRoute path="/tool" component={ Tool }/>
          <PrivateRoute path="/settings" component={ Settings }/>
          <Route path="*" component={ NotFound }/>
        </Switch>
      );
  }

  async componentDidMount() {
    document.title = _("gert.io - A GDPR tool for Zendesk");

    await this.props.fetchCurrentAccount();

    const { account } = this.props;

    if(account.detail && !account.error && !account.loading) {
      const currentPath = this.props.location.pathname;

      if(currentPath === "/" || currentPath === /\/(signup|access)\/?/.test(currentPath)) {
        this.props.push("/tool/instances/");
      }
    }
  }
}

Entry.propTypes = {
  account: PropTypes.object,
  login: PropTypes.object,
  fetchCurrentAccount: PropTypes.func,
  goToInstances: PropTypes.func,
  location: PropTypes.object,
  push: PropTypes.func,
};

export default withRouter(connect(
  state => ({
    account: state.user,
    login: state.login,
  }),
  dispatch => ({
    fetchCurrentAccount: () => dispatch(fetchCurrentAccount()),
    push: to => dispatch(push(to)),
  }),
)(Entry));
