import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const TableHead = ({className, children}) => (
  <thead className={cx(className, "table__head")}>
    {children}
  </thead>
);

TableHead.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default TableHead;
