import React from "react";
import PropTypes from "prop-types";
import { gettext as _ } from "../../../utils";

import TextBlock from "../../../components/text-blocks/TextBlock";
import PageHeader from "../../../components/layout/PageHeader";
import { SearchBar } from "../index";


/**
 * Displays the header for the instance list.
 *
 * @component
 * @name Header
 */
export default function Header({ searchValue, setSearchValue }) {
  return (
    <PageHeader>
      <TextBlock constrained>
        <h2>{_("Instances")}</h2>
      </TextBlock>
      <SearchBar searchValue={ searchValue } setSearchValue={ setSearchValue }/>
    </PageHeader>
  );
}

Header.propTypes = {
  searchValue: PropTypes.string, // Search value provided from parent container.
  setSearchValue: PropTypes.func, // Setter for search value provided from parent container.
};