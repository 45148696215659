const ORGANIZATION_ACTIONS = {
  START_UPDATE: "ORGANIZATION::START_UPDATE",
  UPDATE_COMPLETED: "ORGANIZATION::UPDATE_COMPLETED",
  UPDATE_FAILED: "ORGANIZATION::UPDATE_FAILED",
  FETCH_COMPLETED: "ORGANIZATION::FETCH_COMPLETED",
};

const fetchOrganizationCompleted = payload => {
  return {
    type: ORGANIZATION_ACTIONS.FETCH_COMPLETED,
    payload,
  };
};

const updateOrganization = payload => {
  return async dispatch => {
    dispatch(startUpdateOrganization());

    try {
      const organization = await payload.organization.update(payload.args);

      dispatch(updateOrganizationCompleted(organization));
    } catch(e) {
      dispatch(updateOrganizationFailed(e));
    }
  };
};

const startUpdateOrganization = () => {
  return {
    type: ORGANIZATION_ACTIONS.START_UPDATE,
  };
};

const updateOrganizationCompleted = payload => {
  return {
    type: ORGANIZATION_ACTIONS.UPDATE_COMPLETED,
    payload,
  };
};

const updateOrganizationFailed = payload => {
  return {
    type: ORGANIZATION_ACTIONS.UPDATE_FAILED,
    payload,
  };
};

export {
  ORGANIZATION_ACTIONS,
  fetchOrganizationCompleted,
  updateOrganization,
};
