import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./action-button-trigger.scss";
import { ReactComponent as AddIcon } from "../../assets/add.svg";


/**
 * The trigger for the action button component.
 *
 * @component
 * @name ActionButtonTrigger
 */
export default function ActionButtonTrigger({ className, onHover, isOpen }) {
  return (
    <div className={ cx(className, "action-button-trigger", {
      "action-button-trigger--open": isOpen,
    }) } onMouseOver={ onHover } onMouseLeave={ onHover }>
      <AddIcon className={ cx("action-button-trigger__icon", {
        "action-button-trigger__icon--open": isOpen,
      }) }/>
    </div>
  );
}

ActionButtonTrigger.propTypes = {
  className: PropTypes.string, // Additional classes.
  onHover: PropTypes.func.isRequired, // Click handler for the trigger.
  isOpen: PropTypes.bool.isRequired, // Indicates if action buttons drawer is open.
};