import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/buttons/_anchor.scss";

const Anchor = ({className, children, href, onClick, target, secondary}) => {
  return (
    <a target={target} className={cx(className, "anchor", {
      "anchor--secondary": secondary,
    })} onClick={onClick} href={href}>
      {children}
    </a>
  );
};

Anchor.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  href: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  secondary: PropTypes.bool,
};

export default Anchor;
