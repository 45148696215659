import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import {routerMiddleware} from "connected-react-router";
import {createBrowserHistory} from "history";
import reducers from "../reducers";

export const history = createBrowserHistory();

// Define middleware chain here
const middleWares = [
  routerMiddleware(history),
  thunk,
];

export default createStore(
  reducers(history),
  applyMiddleware(...middleWares),
);

export {
  middleWares,
};
