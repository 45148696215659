import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/dropdown/_option.scss";

const Option = ({className, children, value, selected, onSelect}) => (
  <div onClick={onSelect(value)} className={cx(className, "option", {
    "option--active": value === selected,
  })}>
    {children}
  </div>
);

Option.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  onSelect: PropTypes.func,
};

export default Option;
