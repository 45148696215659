import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Sidebar from "../../components/layout/Sidebar";
import Content from "../../components/layout/Content";

import { Header } from "./index";
import subRoutes from "../../routes/subRoutes";
import { fetchInstance } from "../../actions/instance";
import { useParams } from "react-router-dom";
import Loader from "../../components/loaders/Loader";
import List from "./containers/list";
import AddButton from "./containers/add-button";
import NotFoundContent from "../../components/common/NotFoundContent";
import { gettext as _ } from "../../utils";


/**
 * Displays a list of configurations.
 *
 * @container
 * @name ConfigurationList
 */
export default function ConfigurationList() {
  const dispatch = useDispatch();
  const { instanceId } = useParams();
  const { detail, loading, error } = useSelector((state) => state.instance);

  useEffect(() => {
    dispatch(fetchInstance(instanceId));
  }, []);

  return (
    <>
      <Sidebar links={ subRoutes.tool }/>
      <Content>
        { loading
          ? <Loader/>
          : detail
            ? (
              <>
                <Header/>
                <List/>
                <AddButton/>
              </>
            )
            : error && error.status === 404
              ? <NotFoundContent/>
              : <p>{ _("Something unexpected occurred. Please contact support.") }</p> }
      </Content>
    </>
  );
}