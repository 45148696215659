import {AUTHORIZATION_ACTIONS} from "../actions/authorization";

const defaultState = {
  detail: false,
  loading: true,
};

const authorizationReducer = (state = defaultState, action) => {
  const _state = {
    ...state,
    loading: false,
  };

  if(action.type === AUTHORIZATION_ACTIONS.AUTHORIZED) {
    _state.detail = true;
  }

  if(action.type === AUTHORIZATION_ACTIONS.ANONYMOUS) {
    _state.detail = false;
  }

  return _state;
};

export default authorizationReducer;
