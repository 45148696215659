import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "../../styles/components/separators/_separator.scss";

const HorizontalSeparator = ({className}) => <div className={cx(className, "separator", "separator--horizontal")}/>;

HorizontalSeparator.propTypes = {
  className: PropTypes.string,
};

export default HorizontalSeparator;