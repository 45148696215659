import {INSTANCE_TYPES} from "../actions/instance";

const defaultState = {
  detail: null,
  loading: true,
  error: null,
};

const instanceReducer = (state = defaultState, action) => {
  switch(action.type) {
    case INSTANCE_TYPES.START_DELETE_INSTANCE:
      return {
        ...state,
        loading: true,
        error: null,
        detail: null,
      };
    case INSTANCE_TYPES.LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case INSTANCE_TYPES.ERROR_DELETE_INSTANCE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        detail: null,
      };
    case INSTANCE_TYPES.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case INSTANCE_TYPES.COMPLETE_DELETE_INSTANCE:
    case INSTANCE_TYPES.ADD_INSTANCE:
      return {
        ...state,
        loading: false,
        error: null,
        detail: action.payload,
      };
    default:
      return state;
  }
};

export default instanceReducer;
