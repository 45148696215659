import React from "react";
import PropTypes from "prop-types";
import Button from "Components/buttons/Button";
import cx from "classnames";
import "Styles/components/modal/_modal.scss";

const Modal = ({ show, children, onClose, title, footer, className, footerAlign }) => {
  const closeModal = (e) => onClose(e);

  if(show) {
    return (
      <div className={ cx("modal", className) }>
        <div className="modal__content">
          <div className="modal__header">
            <h2>{ title }</h2>
          </div>
          <div className="modal__body">
            <div>{ children } </div>
          </div>
          <div className={ cx("modal__footer", {
            "modal__footer--start": footerAlign === "start",
            "modal__footer--center": footerAlign === "center",
            "modal__footer--end": footerAlign === "end",
          }) }>
            { footer ? footer : <Button onClick={ closeModal }>Close</Button> }
          </div>
        </div>
      </div>
    );
  }

  return null;
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node,
  onClose: PropTypes.func,
  title: PropTypes.string,
  footer: PropTypes.node,
  className: PropTypes.string,
  footerAlign: PropTypes.oneOf(["start", "center", "end"]),
};

export default Modal;