import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import {ReactComponent as BackIcon} from "../../icons/arrow-back.svg";

import "../../styles/components/buttons/_back-button.scss";

const BackButton = ({className, children, onClick, large, plain, type}) => {
  return (
    <button type={type} onClick={onClick} className={cx(className, "back-button", {
      "back-button--large": large,
      "back-button--plain": plain,
    })}>
      <BackIcon className="back-button__icon"/>
      {children}
    </button>
  );
};

BackButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
  onClick: PropTypes.func,
  large: PropTypes.bool,
  plain: PropTypes.bool,
  type: PropTypes.string,
};

BackButton.defaultProps = {
  type: "button",
};

export default BackButton;
