import React from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-grid-system";
import TextBlock from "../text-blocks/TextBlock";

import imageUrl from "../../images/success.svg";
import "../../styles/base/_utils.scss";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { gettext as _ } from "../../utils";

class End extends React.Component {
  constructor(props) {
    super(props);

    this.redirect = this.redirect.bind(this);
  }

  render() {
    return (
      <Container fluid>
        <Row justify="center" align="center">
          <Col>
            <img alt={ "party" } src={ imageUrl }/>
          </Col>
          <Col>
            <TextBlock align="left">
              <h1>{ _("Almost there...") }</h1>
              <p>
                { _("Check your email and verify your account and you’re all set." +
                  " The email might have landed in your spam folder.") }
              </p>
            </TextBlock>
          </Col>
        </Row>
      </Container>
    );
  }

  redirect(e) {
    e.preventDefault();

    this.props.push("/");
  }
}

End.propTypes = {
  push: PropTypes.func,
  validation: PropTypes.object,
};

export default connect(
  state => ({
    validation: state.validation,
  }),
  dispatch => ({
    push: to => dispatch(push(to)),
  }),
)(End);
