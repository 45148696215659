import React from "react";
import PropTypes from "prop-types";

import { Col, Container, Row } from "react-grid-system";
import CancelImageUrl from "../images/cancel.svg";
import TextBlock from "./text-blocks/TextBlock";
import Button from "./buttons/Button";
import Content from "./layout/Content";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { gettext as _ } from "../utils";

class SubscriptionCancel extends React.Component {
  constructor(props) {
    super(props);

    this.subscription = this.subscription.bind(this);
  }

  render() {
    return (
      <Content withGrid>
        <Container className="u-full-height" fluid>
          <Row className="u-full-height" align="center">
            <Col sm={ 6 }>
              <img alt={ "cancel" } src={ CancelImageUrl } className="u-of-contain"/>
            </Col>
            <Col sm={ 6 }>
              <TextBlock>
                <h1>{ _("Sad to see you go...") }</h1>
                <Button onClick={ this.subscription } primary>{ _("Go to subscription overview") }</Button>
              </TextBlock>
            </Col>
          </Row>
        </Container>
      </Content>
    );
  }

  subscription(e) {
    e.preventDefault();

    this.props.goToSubscription();
  }
}

SubscriptionCancel.propTypes = {
  goToSubscription: PropTypes.func,
};

export default connect(
  null,
  dispatch => ({
    goToSubscription: () => dispatch(push("/settings/subscription/")),
  }),
)(SubscriptionCancel);
