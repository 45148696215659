import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import {connect} from "react-redux";

import {push} from "connected-react-router";

import "../../styles/components/layout/_page.scss";

const SIZES = {
  LARGE: "large",
};

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  render() {
    const {className, children, size} = this.props;
    const isLarge = size === SIZES.LARGE;

    const _children = React.Children.map(children, child => {
      if(child.type.name === "Header") {
        return React.cloneElement(child, {
          size,
        });
      }

      return React.cloneElement(child, {});
    });

    return (
      <div className={cx(className, "page", {
        "page--large": isLarge,
      })}>
        {_children}
      </div>
    );
  }

  handleClick(e) {
    e.preventDefault();

    this.props.push("/instances/");
  }
}

Page.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  size: PropTypes.string,
  push: PropTypes.func,
};

export default connect(
  null,
  dispatch => ({
    push: to => dispatch(push(to)),
  }),
)(Page);
