import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import logoPath from "../../images/logo.svg";
import "../../styles/components/layout/_logo.scss";

const HorizontalLogo = ({className, onClick}) => (
  <img src={logoPath} onClick={onClick} className={cx(className, "logo", "logo--horizontal", {
    "logo--clickable": onClick,
  })}/>
);

HorizontalLogo.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default HorizontalLogo;
