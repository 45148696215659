import React from "react";
import { gettext as _ } from "../../../utils";

import { useSelector } from "react-redux";

import { Row } from "../index";

import Table from "../../../components/table/Table";
import TableHead from "../../../components/table/TableHead";
import TableHeadCell from "../../../components/table/TableHeadCell";
import TableBody from "../../../components/table/TableBody";
import TableRow from "../../../components/table/TableRow";


/**
 * Displays a list of configurations related to an instance.
 *
 * @container
 * @name List
 */
export default function List() {
  const { detail } = useSelector((state) => state.instance);

  return (detail.configurations.length > 0)
    ? (
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell width="10%">{ _("Enabled") }</TableHeadCell>
            <TableHeadCell width="100%">{ _("Query name") }</TableHeadCell>
            <TableHeadCell width="auto"></TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { detail.configurations.map((item) => (
            <Row key={ item.id } configuration={ item }/>
          )) }
        </TableBody>
      </Table>
    )
    : (
      <div>
        <p>{ _("No configurations found") }</p>
      </div>
    );
}