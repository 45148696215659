import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import NotFound from "../../components/NotFound";
import { deleteConfiguration, fetchConfiguration, updateConfiguration } from "src/actions/configuration";
import Loader from "../../components/loaders/Loader";
import { addConfiguration, fetchInstance } from "src/actions/instance";
import {
  arrayContains,
  flatten,
  gettext as _,
  hasChanged,
  hasLength,
  renderErrors,
  renderTagErrors,
  validateTag,
} from "../../utils";
import Sidebar from "../../components/layout/Sidebar";
import subRoutes from "../../routes/subRoutes";
import Content from "../../components/layout/Content";
import PageHeader from "../../components/layout/PageHeader";
import BackButton from "../../components/buttons/BackButton";
import Detail from "../../components/layout/Detail";
import { Col, Container, Row } from "react-grid-system";
import Truncate from "../../components/common/Truncate";
import Field from "../../components/forms/fields/Field";
import Label from "../../components/forms/fields/Label";
import Hint from "../../components/forms/fields/Hint";
import Input from "../../components/forms/fields/Input";
import TagField from "../../components/forms/fields/TagField";
import TagInput from "../../components/forms/fields/TagInput";
import Dropdown from "../../components/dropdown/Dropdown";
import Trigger from "../../components/dropdown/Trigger";
import Message from "../../components/forms/fields/Message";
import Anchor from "../../components/buttons/Anchor";
import Checkbox from "../../components/forms/checkboxes/Checkbox";
import Button from "../../components/buttons/Button";
import { ConfigurationCustomFields } from "./index";
import Icon from "../../components/icons/Icon";
import { ReactComponent as DeleteIcon } from "../../icons/delete.svg";
import QueryBuilderModal from "Components/query-builder/Modal";
import "Styles/containers/_configuration.scss";
import ConfirmationModal from "Components/confirmation-modal";

const METHOD_MAP = {
  delete: {
    label: _("Delete"),
    value: "delete",
  },
  redact_full_comment: {
    label: _("Redact"),
    value: "redact_full_comment",
  },
};

/**
 * Displays a configuration form. Can be configured for both create and update.
 *
 * See prop types.
 *
 * @container
 * @name Configuration
 */
export default function Configuration({ instance, configurationId, update }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { instanceId: instanceIdUrlParam } = useParams();

  const [state, setState] = useState({
    name: {
      value: "",
    },
    query: {
      value: "",
    },
    tags: {
      value: "",
      options: [],
    },
    method: {
      value: Object.values(METHOD_MAP)[0],
    },
    enabled: {
      checked: false,
    },
    excludeFields: {
      value: null,
      checked: false,
    },
    fields: {
      value: "",
      selected: [],
      options: [],
    },
  });

  const configuration = useSelector((state) => state.configuration);
  const selectedFieldIds = state.fields.selected;

  const [showQueryBuilder, setShowQueryBuilder] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const toggleQueryBuilderModal = (e, queryText) => {
    e.preventDefault();

    setShowQueryBuilder(!showQueryBuilder);

    if(queryText) {
      setState((prevState) => ({
        ...prevState,
        query: {
          ...prevState["query"],
          value: queryText,
        },
      }));
    }
  };

  const toggleConfirmationModal = (e) => {
    e.preventDefault();
    setShowConfirmationModal(!showConfirmationModal);
  };

  function handleSubmit(e) {
    e.preventDefault();

    if(showConfirmationModal) {
      setShowConfirmationModal(false);
    }

    let args = {};

    if(!update || hasChanged(configuration.detail.name, state.name.value)) {
      args.name = state.name.value;
    }

    if(!update || hasChanged(configuration.detail.query, state.query.value)) {
      args.query = state.query.value;
    }

    if(!update || hasChanged(configuration.detail.tags, state.tags.options)) {
      args.tags = state.tags.options;
    }

    if(!update || hasChanged(configuration.detail.enabled, state.enabled.checked)) {
      args.enabled = state.enabled.checked;
    }

    if(!update || hasChanged(configuration.detail.method, state.method.value.value)) {
      args.method = state.method.value.value;
    }

    const fieldAction = state.excludeFields.value;

    if(!update || configuration.detail.ticket_fields_action !== state.excludeFields.value) {
      args.ticket_fields_action = state.excludeFields.value;
    }

    if(!update || hasChanged(configuration.detail.ticket_fields, selectedFieldIds)) {
      args.ticket_fields = selectedFieldIds;
    }

    if(update) {
      dispatch(updateConfiguration({
        configuration: configuration.detail,
        args,
      }));
    } else {
      if(
        hasLength(state.query.value) ||
        hasLength(state.tags.options) ||
        hasLength(state.name.value)
      ) {
        dispatch(addConfiguration({
          instance: instance.detail,
          configuration: {
            name: state.name.value,
            query: state.query.value,
            tags: state.tags.options,
            method: state.method.value.value,
            enabled: state.enabled.checked,
            ticket_fields_action: fieldAction,
            ticket_fields: selectedFieldIds,
          },
        }));
      }
    }
  }

  function back(e) {
    e.preventDefault();

    history.push(`/tool/instances/${ instanceIdUrlParam }`);
  }

  function handleTagAdd(e) {
    const isTagEntered = e.target.value.length > 0;

    if(isTagEntered && (e.keyCode === 13 || e.keyCode === 32 || e.type === "blur")) {
      e.preventDefault();

      setState((prevState) => {
        const value = prevState.tags.value;
        const options = prevState.tags.options;
        const isValid = validateTag(value);
        const isPresent = arrayContains(value, options);

        if(!isValid || isPresent) {
          return prevState;
        }

        return {
          ...prevState,
          tags: {
            ...prevState.tags,
            options: [...options, value],
            value: "",
          },
        };
      });
    }
  }

  function handleTagRemove(tag) {
    setState((prevState) => ({
      ...prevState,
      tags: {
        ...prevState.tags,
        options: prevState.tags.options.filter(_tag => _tag !== tag),
      },
    }));
  }

  function handleChange(key) {
    return e => {
      e.preventDefault();

      let value = e.target.value;

      setState((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          value,
        },
      }));
    };
  }

  function handleCheck(key) {
    return () => {
      setState(prevState => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          checked: !prevState[key].checked,
        },
      }));
    };
  }

  function handleSelect(selected) {
    setState((prevState) => ({
      ...prevState,
      method: {
        ...prevState.method.value,
        value: selected,
      },
    }));
  }

  function handleFields(action) {
    return function(id) {
      setState((prevState) => {
        let value;

        switch(action) {
          case "add":
            value = [id, ...prevState.fields.selected];
            break;
          case "remove":
            value = prevState.fields.selected.filter((field) => field !== id);
            break;
          default:
            throw Error(`Error: handleFields does not support action \`${ action }\``);
        }

        return {
          ...prevState,
          fields: {
            ...prevState.fields,
            selected: value,
          },
        };
      });
    };
  }

  function handleExclude(value) {
    setState((prevState) => ({
      ...prevState,
      excludeFields: {
        ...prevState.excludeFields,
        value,
      },
    }));
  }

  async function doConfigurationDestroy(e) {
    e.preventDefault();

    if(configuration.detail && confirm(_("Are you sure that you want to delete configuration \"{{configuration}}\"").replace("{{configuration}}", configuration.detail.name))) {
      await dispatch(deleteConfiguration(configuration.detail));
      history.push(`/tool/instances/${ instanceIdUrlParam }`);
    }
  }

  useEffect(() => {
    if(update) {
      dispatch(fetchConfiguration(configurationId, (configuration_) => {
        if(configuration_) {
          setState((prevState) => ({
            ...prevState,
            name: {
              ...prevState.name,
              value: configuration_.name,
            },
            query: {
              ...prevState.query,
              value: configuration_.query,
            },
            tags: {
              ...prevState.tags,
              options: configuration_.tags,
            },
            method: {
              value: METHOD_MAP[configuration_.method],
            },
            enabled: {
              ...prevState.enabled,
              checked: configuration_.enabled,
            },
            excludeFields: {
              ...prevState.excludeFields,
              value: configuration_.ticket_fields_action,
            },
            fields: {
              ...prevState.fields,
              selected: configuration_.ticket_fields,
            },
          }));
        }
      }));
    }

    if(!instance.detail || instance.detail.id !== instanceIdUrlParam) {
      dispatch(fetchInstance(instanceIdUrlParam));
    }
  }, []);

  if(instance.loading || (update && configuration.loading)) {
    return <Loader page/>;
  }

  if(instance.error && instance.error.status === 404 || update && configuration.error && configuration.error === 404) {
    return <NotFound/>;
  }

  let error = update ? configuration.error : instance.error;

  error = error && error.response;

  const nameErrors = error && error.name;
  const queryErrors = error && error.query && flatten(Object.values(error.query));
  const tagErrors = error && error.tags;
  const enabledErrors = error && error.enabled;
  const methodErrors = error && error.method;

  const hasChanged_ = update && (
    hasChanged(configuration.detail.query, state.query.value) ||
    hasChanged(configuration.detail.tags, state.tags.options) ||
    hasChanged(configuration.detail.name, state.name.value) ||
    hasChanged(configuration.detail.enabled, state.enabled.checked) ||
    hasChanged(configuration.detail.method, state.method.value.value) ||
    hasChanged(configuration.detail.ticket_fields, state.fields.selected) ||
    (configuration.detail.ticket_fields_action !== state.excludeFields.value)
  );

  const isSubmittable = !update && (
    hasLength(state.query.value) ||
    hasLength(state.tags.options) ||
    hasLength(state.name.value)
  );

  const isPristine = hasChanged_ || isSubmittable;

  // TODO: When we add support for organizations, we want to add `organization` to the array.
  const fieldType = ["ticket", "user"].reduce((result, type) => {
    if(state.query.value.includes(`type:${ type }`)) {
      return `${ type }_fields`;
    }

    return result;
  }, null);

  const fields = instance.detail && instance.detail.metadata.metadata[fieldType];

  return (
    <>
      <Sidebar links={ subRoutes.tool }/>
      <Content>
        <PageHeader>
          <BackButton large plain onClick={ back }>
            { _("Go back") }
          </BackButton>
          { update && (
            <Button onClick={ doConfigurationDestroy } danger size="extra-small">
              <Icon>
                <DeleteIcon/>
              </Icon>
            </Button>
          ) }
        </PageHeader>
        <form onSubmit={ handleSubmit }>
          <Detail hasGrid>
            <Container fluid>
              <Row className="u-mb-lg">
                <Col>
                  <Truncate>
                    <h2 className="u-mb-md">{ update ? _("Update configuration") : _("Add new configuration") }</h2>
                  </Truncate>
                  <Field isPristine={ !nameErrors } className="u-mb" secondary value={ state.name.value }
                    onChange={ handleChange("name") }>
                    <Label>
                      Name
                      <span className="c-fg-danger">*</span>
                    </Label>
                    <Hint>Give the configuration a name. This is used for easy identification of a query.</Hint>
                    <Input/>
                    { renderErrors(nameErrors) }
                  </Field>
                  <Field isPristine={ !queryErrors } className="u-mb" secondary value={ state.query.value }
                    onChange={ handleChange("query") }>
                    <Label>
                      Query
                      <span className="c-fg-danger">*</span>
                    </Label>
                    <Hint>{ _("Write a custom query by using ") }
                      <Anchor target="_blank" href="https://support.zendesk.com/hc/en-us/articles/203663226-Zendesk-Support-search-reference">
                        { _("Zendesk search query") }
                      </Anchor>
                      { /*_(" or build a simple query using our query builder. One 'type' keyword of either user OR ticket is required.")*/ }
                    </Hint>
                    <div className="query-wrapper">
                      <Input isPristine={ !queryErrors } className="u-mb" secondary value={ state.query.value } onChange={ handleChange("query") }/>
                      { /* <Button onClick={ toggleQueryBuilderModal } secondary size="extra-small" className="query-builder__button">{ _("Query builder") }</Button>*/ }
                    </div>
                    { renderErrors(queryErrors) }
                  </Field>
                  <QueryBuilderModal show={ showQueryBuilder } onClose={ toggleQueryBuilderModal }/>
                  <TagField className="u-mb" secondary isPristine={ !tagErrors } value={ state.tags.value }
                    options={ state.tags.options } onChange={ handleChange("tags") }
                    onRemove={ handleTagRemove }
                    onKeyDown={ handleTagAdd } onBlur={ handleTagAdd }>
                    <Label>Insert tags here</Label>
                    <Hint>These tags will be added to the processed tickets.</Hint>
                    <TagInput/>
                    { renderTagErrors(tagErrors, state.tags.value, state.tags.options) }
                  </TagField>
                  <Dropdown className="u-mb" selected={ state.method.value.value }
                    onSelect={ handleSelect } options={ Object.values(METHOD_MAP) }>
                    <Label>{ _("Method") }</Label>
                    <Hint>{ _("The specified query will be handled with the following method.") }</Hint>
                    <Trigger>
                      { state.method.value.label }
                    </Trigger>
                    { state.method.value.value === METHOD_MAP["redact_full_comment"].value && (
                      <Message isWarning>
                        { _("Redaction is permanent. You can't undo the redaction or see what was removed. " +
                          "Once a ticket is closed, you can no longer redact strings from its comments. ") }
                        <Anchor target="_blank" secondary href="https://support.gert.io/hc/en-us/sections/360003653540-Redaction">
                          { _("Read more.") }
                        </Anchor>
                      </Message>
                    ) }
                    { state.method.value.value === METHOD_MAP["delete"].value && (
                      <Message isWarning>
                        { _("Deletion is permanent. You can't undo the deletion. ") }
                        <Anchor target="_blank" secondary href="https://support.gert.io/hc/en-us/sections/360005715639-Deletion">
                          { _("Read more.") }
                        </Anchor>
                      </Message>) }
                    { renderErrors(methodErrors) }
                  </Dropdown>
                  <Checkbox isPristine={ !enabledErrors } checked={ state.enabled.checked }
                    onChange={ handleCheck("enabled") }>
                    <Label>{ _("Enable this configuration") }</Label>
                    <Hint>{ _("By enabling this configuration, it will run regularly.") }</Hint>
                    { renderErrors(enabledErrors) }
                  </Checkbox>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ConfigurationCustomFields
                    fields={ fields }
                    fieldType={ fieldType }
                    selectedFields={ state.fields.selected }
                    onHandleFields={ handleFields }
                    exclude={ state.excludeFields.value }
                    onExclude={ handleExclude }
                    isDeleteMethod={ state.method.value.value === METHOD_MAP["delete"].value }/>
                </Col>
              </Row>
              <Row>
                <Col className="u-ta-r">
                  <Button disabled={ !isPristine } size="small" primary onClick={ toggleConfirmationModal }>
                    { update ? _("Update configuration") : _("Add configuration") }
                  </Button>
                  <ConfirmationModal show={ showConfirmationModal } onClose={ toggleConfirmationModal }/>
                </Col>
              </Row>
            </Container>
          </Detail>
        </form>
      </Content>
    </>
  );
}

Configuration.propTypes = {
  // The redux state for `state.instance`
  instance: PropTypes.object,
  // The id of the viewed configuration. Used in combination with the `update` prop.
  configurationId: PropTypes.string,
  // If true, the container will update a configuration based on the `configurationId` prop.
  update: PropTypes.bool,
};
