import React from "react";
import PropTypes from "prop-types";

import "./entity-card-title.scss";


/**
 * Display the title in a self contained box in the bottom of the card.
 *
 * @component
 * @name EntityCardTitle
 */
export default function EntityCardTitle({ children }) {
  return (
    <div className="entity-card-title">
      <h3 className="entity-card-title__text">
        { children }
      </h3>
    </div>
  );
}

EntityCardTitle.propTypes = {
  children: PropTypes.node.isRequired, // The title to display in the banner.
};